import React from 'react'
import moment from 'moment'

const PeriodStartAt = (props) => {

  const {
    item
  } = props

  const noon = '10:00';
  const afterNoon  = '14:00';

  function translateType() {
    if(item === undefined ){
      return ''
    }
    else if(moment(item).format('HH:mm') < noon){
      return 'Matin'
    }
    else if(moment(item).format('HH:mm') < afterNoon){
      return 'Midi'
    }
    else{
      return 'Après-midi'
    }
    
  }

  return (
    <span>
      {translateType()}
    </span>
  )

}

export default PeriodStartAt
