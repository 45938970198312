import React from 'react'
import Filters from 'app/orderTool/components/sidebar/filters/Filters'

const coreData = {
  format : [
    {
      "option"      : "all",
      "traduction"  : "Tous types"
    },
    {
      "option"      : "v2",
      "traduction"  : "Plateaux-repas"
    },
    {
      "option"      : "caterer",
      "traduction"  : "Traiteur"
    },
    {
      "option"      : "breakfast",
      "traduction"  : "Petit-déjeuner"
    }
  ],
  statusQuotation : [
    {
      "option"      : "all_valid",
      "traduction"  : "En attente/validées/à vérifier"
    },
    {
      "option"      : "waiting",
      "traduction"  : "En attente"
    },
    {
      "option"      : "confirmed",
      "traduction"  : "Validées"
    },
    {
      "option"      : "refused",
      "traduction"  : "Refusées"
    },
    {
      "option"      : "billable",
      "traduction"  : "A vérifier avant facturation"
    }
  ],
  statusInvoice  : [
    {
      "option"      : "all_invoice",
      "traduction"  : "Tous les statuts"
    },
    {
      "option"      : "to_paid",
      "traduction"  : "A payer"
    },
    {
      "option"      : "paid",
      "traduction"  : "Payées"
    }
  ],
}

const FiltersContainer = (props) => {
  return (
    <Filters
      coreData={coreData}
    />
  );
}

export default FiltersContainer
