import moment from "moment";

export const orderStateToTotal = (order) => {
  const itemsCost = order.items ? order.items.reduce(
    (total, item) => total + (item.price || 0) * item.quantity,
    0
  ) : 0;
  const deliveryCost = order.deliveryCost;
  let reductionAmount = 0;
  if (order.reduction.proportional) {
    reductionAmount = ((itemsCost + deliveryCost) * order.reduction.proportional) / 100;
  } else if (order.reduction.direct) {
    reductionAmount = order.reduction.direct;
  }

  const totalHT  = itemsCost - reductionAmount + deliveryCost;
  const totalTTC = itemsCost - reductionAmount + deliveryCost;

  return {
    totalHT,
    totalTTC
  };
};
export const breakfastOrderStoreToApi = (orderIndex, orderInfo) => {
  const deliveryDate = orderInfo?.orders?.[orderIndex]?.deliveryInfo?.deliveryDate
  ? moment(orderInfo.orders[orderIndex].deliveryInfo.deliveryDate, "YYYY/MM/DD").format("DD/MM/YYYY")
  : null;
  const billingAddress = `${orderInfo?.billingInfo?.streetNumber || ''} ${orderInfo?.billingInfo?.street || ''}, ${orderInfo?.billingInfo?.postalCode || ''}, ${orderInfo?.billingInfo.city || ''}`;
  const deliveryAddress = `${orderInfo?.deliveryInfo?.streetNumber || ''} ${orderInfo?.deliveryInfo?.street || ''}, ${orderInfo?.deliveryInfo?.postalCode || ''}, ${orderInfo?.deliveryInfo?.city || ''}`;

  const deliveryStartAt = orderInfo?.orders?.[orderIndex]?.deliveryInfo?.startAt ? orderInfo.orders[orderIndex].deliveryInfo.startAt : null;
  const deliveryEndAt = orderInfo?.orders?.[orderIndex]?.deliveryInfo?.endAt ? orderInfo.orders[orderIndex].deliveryInfo.endAt : null;
  const deliveryArrivedAtStart = orderInfo?.orders?.[orderIndex]?.deliveryInfo?.arrivedAtStart ? orderInfo.orders[orderIndex].deliveryInfo.arrivedAtStart : null;
  const deliveryArrivedAtEnd = orderInfo?.orders?.[orderIndex]?.deliveryInfo?.arrivedAtEnd ? orderInfo.orders[orderIndex].deliveryInfo.arrivedAtEnd : null;
  return {
    customer: orderInfo?.customer?.id ? orderInfo.customer.id.toString() : null,
    company: orderInfo?.company?.id ? orderInfo.company.id.toString() : null,
    delivery_date: deliveryDate,
    tender: orderInfo?.tender?.id ? orderInfo.tender.id.toString() : null,
    confirmTender: orderInfo?.confirmTender ? orderInfo.confirmTender : null,
    customerSpecialCommentary: orderInfo?.customerSpecialCommentary || null,
    disable_approaching_mail: orderInfo.disableApproachingMail === true || orderInfo.disableApproachingMail === "1" || orderInfo.disableApproachingMail === 1 ? 1 : 0,
    orderFormDate: orderInfo?.orderFormDate ? moment(
      orderInfo.orderFormDate,
      "YYYY/MM/DD"
    ).format("YYYY-MM-DD") : null,
    customer_special_reference: orderInfo.customerSpecialReference === "" ? null : orderInfo?.customerSpecialReference,
    codeServiceChorus: orderInfo.codeServiceChorus === "" ? null : orderInfo?.codeServiceChorus,
    customer_quotation_commentary:
      orderInfo?.customerQuotationCommentary || null,
    commentaryForProd: orderInfo.orders[orderIndex].commentaryForProd,
    commentaryForRecupProd: orderInfo.orders[orderIndex].commentaryForRecupProd,
    deliveryAddress: deliveryAddress === "" ? null : deliveryAddress,
    billingAddress: billingAddress === "" ? null : billingAddress,
    firstname: orderInfo?.billingInfo?.firstname === "" ? null : orderInfo?.billingInfo?.firstname,
    lastname: orderInfo?.billingInfo?.lastname === "" ? null : orderInfo?.billingInfo?.lastname,
    billingCompany: orderInfo?.company?.name === "" ? null : orderInfo?.company?.name,
    phone: orderInfo?.billingInfo?.phone === "" ? null : orderInfo?.billingInfo?.phone,
    address: billingAddress === "" ? null : billingAddress,
    street_number: orderInfo?.billingInfo?.streetNumber === "" ? null : orderInfo?.billingInfo?.streetNumber,
    street: orderInfo?.billingInfo?.street === "" ? null : orderInfo?.billingInfo?.street,
    address_complement: orderInfo?.billingInfo.addressComplement === "" ? null : orderInfo?.billingInfo?.addressComplement,
    postal_code: orderInfo?.billingInfo?.postalCode === "" ? null : orderInfo?.billingInfo?.postalCode,
    city: orderInfo?.billingInfo?.city === "" ? null : orderInfo?.billingInfo?.city,
    format: orderInfo.orders[orderIndex].format,
    participants_number: orderInfo?.orders[orderIndex]?.deliveryInfo?.person ? orderInfo.orders[orderIndex].deliveryInfo.person.toString() : null,
    status: orderInfo?.orders[orderIndex]?.status || '101',
    delivery: {
      arrived_at_start: deliveryArrivedAtStart,
      arrived_at_end: deliveryArrivedAtEnd,
      start_at: deliveryStartAt,
      end_at: deliveryEndAt,
      delivery_information: orderInfo.deliveryInfo.deliveryInformation === "" ? null : orderInfo?.deliveryInfo?.deliveryInformation,
      firstname: orderInfo?.deliveryInfo?.firstname === "" ? null : orderInfo?.deliveryInfo?.firstname,
      lastname: orderInfo?.deliveryInfo?.lastname === "" ? null : orderInfo?.deliveryInfo?.lastname,
      company: orderInfo?.deliveryInfo?.company === "" ? null : orderInfo?.deliveryInfo?.company,
      phone: orderInfo?.deliveryInfo?.phone === "" ? null : orderInfo?.deliveryInfo?.phone,
      phone_secondary: orderInfo?.customer?.delivery_phone_secondary === "" ? null : orderInfo?.customer?.delivery_phone_secondary,
      street_number: orderInfo?.deliveryInfo?.streetNumber === "" ? null : orderInfo?.deliveryInfo?.streetNumber,
      street: orderInfo?.deliveryInfo?.street === "" ? null : orderInfo?.deliveryInfo?.street,
      address_complement: orderInfo?.deliveryInfo?.addressComplement === "" ? null : orderInfo?.deliveryInfo?.addressComplement,
      postal_code: orderInfo?.deliveryInfo?.postalCode === "" ? null : orderInfo?.deliveryInfo?.postalCode,
      city: orderInfo?.deliveryInfo?.city === "" ? null : orderInfo?.deliveryInfo?.city,
      note: orderInfo?.deliveryInfo?.textarea === "" ? null : orderInfo?.deliveryInfo?.textarea,
      recovery: "",
      service: orderInfo?.orders?.[orderIndex]?.deliveryInfo?.service === true || orderInfo?.orders?.[orderIndex]?.deliveryInfo?.service === "1" || orderInfo?.orders?.[orderIndex]?.deliveryInfo?.service === 1 ? 1 : 0,
      blanket: orderInfo?.orders?.[orderIndex]?.deliveryInfo?.blanket === true || orderInfo?.orders?.[orderIndex]?.deliveryInfo?.blanket === "1" || orderInfo?.orders?.[orderIndex]?.deliveryInfo?.blanket === 1 ? 1 : 0,
    },
    formulas: orderInfo.orders[orderIndex].items
    .filter((item) => item.type === 'formula')
    .map((selectedFormula) => {
      const formulaComponents = selectedFormula.formula_elements.map(
        (element) => {
          return {
            id: element.breakfast_product.id.toString(),
            quantity: element.quantity_per_person.toString(),
            type: "menu",
          };
        }
      );
  
      return {
        name: selectedFormula.name,
        quantity: selectedFormula.quantity,
        price: selectedFormula.price,
        components: formulaComponents,
      };
    }),
    products: orderInfo.orders[orderIndex].items
    .filter((item) => item.type === 'product')
    .map((selectedProduct) => {
      return {
        quantity: selectedProduct.quantity,
        name: selectedProduct.name,
        price: selectedProduct.price,
        id: selectedProduct.id,
      };
    }),
    deliveryCost: orderInfo.orders[orderIndex].deliveryCost,
    reduction: orderInfo?.orders[orderIndex]?.reduction ? {
      label: orderInfo?.orders[orderIndex]?.reduction?.label || "",
      direct: orderInfo?.orders[orderIndex]?.reduction?.direct || 0,
      proportional: orderInfo?.orders[orderIndex]?.reduction?.proportional || 0,
    } : null,
  };
};

export const breakfastOrderApiToStore = (order) => {

  let orderState = {
    deliveryInfo: {
      deliveryDate: moment(order.delivery_date, "DD/MM/YYYY").format(
        "YYYY/MM/DD"
      ),
      arrivedAtStart: order.delivery.arrived_at_start,
      arrivedAtEnd: order.delivery.arrived_at_end,
      startAt: order.delivery.start_at,
      endAt: order.delivery.end_at,
      deliveryInformation: order.delivery.delivery_information,
      firstname: order.delivery.firstname,
      lastname: order.delivery.lastname,
      company: order.delivery.company,
      phone: order.delivery.phone,
      phoneSecondary: order.delivery.phone_secondary,
      streetNumber: order.delivery.street_number,
      street: order.delivery.street,
      addressComplement: order.delivery.address_complement,
      postalCode: order.delivery.postal_code,
      city: order.delivery.city,
      codeChorus: order.codeServiceChorus,
      customerQuotationCommentary: order.customer_quotation_commentary,
    },
    billingInfo: {
      firstname: order.firstname,
      lastname: order.lastname,
      company: order.billingCompany,
      streetNumber: order.street_number,
      street: order.street,
      postalCode: order.postal_code,
      city: order.city,
      addressComplement: order.address_complement,
      phone: order.phone,
    },
    customer: order.customer ? {
      id: order.customer.id,
      firstname: order.customer.firstname,
      lastname: order.customer.lastname
    } : null,
    company: order.company ? {
      id: order.company.id,
      name: order.company.name,
    } : null,
    tender: order.tender ? {
      id: order.tender.id,
      name: order.tender.name,
    } : null,
    currentOrder: {},
    currentOrderIndex: null,
    customerSpecialCommentary: order.customer_quotation_commentary,
    customerSpecialReference: order.customer_special_reference,
    codeServiceChorus: order.code_service_chorus,
    orderFormDate: order.order_form_date ? moment(order.order_form_date).format(
      "YYYY-MM-DD"
    ) : null,
    disableApproachingMail: order.disable_approaching_mail === true || order.disable_approaching_mail === "1" || order.disable_approaching_mail=== 1,
    confirmTender: order.id ? true : false,
    orders: [
      {
        id: order.id,
        version: order.version,
        status: order.status,
        format: order.format,
        commentaryForProd: order.commentary_for_prod,
        commentaryForRecupProd: order.commentary_for_recup_prod,
        reduction: order.components
        .reduce((acc, component) => {
          if (component.type === 'Promotion') {
            return {
              label: component.name,
              direct: -1 * (acc.direct + component.price),
              proportional: 0,
            }
          }
          return acc;
        }, {
          label: "",
          direct: 0,
          proportional: 0,
        }),
        deliveryCost: order.components
        .reduce((acc, component) => {
          if (component.type === 'Delivery') {
            return acc + component.price;
          }
          return acc;
        }, 0),
        deliveryInfo: {
          startAt: order.delivery.start_at ? moment.parseZone(order.delivery.start_at).format("HH:mm") : "",
          endAt: order.delivery.end_at ? moment.parseZone(order.delivery.end_at).format("HH:mm") : "",
          deliveryDate: moment(order.delivery_date).format(
            "YYYY-MM-DD"
          ),
          person: order.participants_number,
          arrivedAtStart: order.delivery.arrived_at_start ? moment.parseZone(order.delivery.arrived_at_start).format("HH:mm") : "",
          arrivedAtEnd: order.delivery.arrived_at_end ? moment.parseZone(order.delivery.arrived_at_end).format("HH:mm") : "",
          service: order.delivery.service === true || order.delivery.service === "1" || order.delivery.service === 1,
          blanket: order.delivery.blanket === true || order.delivery.blanket === "1" || order.delivery.blanket === 1,
        },
        items: order.components
        .reduce((acc, component) => {

          if (component.type === 'menu') {
            // Trouver l'élément existant dans le tableau accumulé
            let existingItem = acc.find(item => item.name === component.formula_name && item.price === component.formula_price && item.quantity === component.formula_quantity);
          
            // Si l'élément existe déjà, ajoutez simplement le nouveau composant à formula_elements
            if (existingItem) {
              existingItem.formula_elements.push({
                breakfast_product: {
                  id: component.product_id,
                },
                quantity_per_person: component.quantity,
              });
            } else {
              // Sinon, créez un nouvel élément et ajoutez-le à l'accumulateur
              acc.push({
                name: component.formula_name,
                price: component.formula_price,
                quantity: component.formula_quantity,
                type: 'formula',
                formula_elements: [{
                  breakfast_product: {
                    id: component.product_id,
                  },
                  quantity_per_person: component.quantity,
                }],
              });
            }
          }
          else {
            // Ne prend que les composants de type supplement et special. Les autres (Delivery, Promotion sont traités autres part)
            if (component.type === 'supplement' || component.type === 'special') {
              acc.push({
                name: component.name,
                price: component.price,
                quantity: component.quantity,
                type: 'product',
                id: component.product_id,
              });
            }
          }
        
          return acc;
        }, []),
        info: order.info,
        totalHT: order.total,
        totalTTC: order.total_price_ttc,
      },
    ],
  };

  const totals = orderStateToTotal(orderState.orders[0]);
  orderState.orders[0].totalHT = totals.totalHT;
  orderState.orders[0].totalTTC = totals.totalTTC;

  return orderState;
}
