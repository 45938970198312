import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { thunk } from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // utilise le stockage local pour le web
import apiService from "./apiService";
import adminDataReducer from "./adminData/reducers";
import orderEditionReducer from "./orderEdition/reducers";

// Pour le debogage
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Combine reducers
const rootReducer = combineReducers({
  adminData: adminDataReducer,
  orderEdition: orderEditionReducer,
});

// Persiste le store
const persistConfig = {
  key: "root",
  storage,
  whitelist: [], // liste des réducteurs à persister
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create store
export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk, apiService))
);
export const persistor = persistStore(store);
