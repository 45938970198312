import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'emotion'
import {Droppable, Draggable} from 'react-beautiful-dnd';

import DeliveryRecoveryContainer from 'app/deliveryTool/containers/DeliveryRecoveryContainer'

import { useFilters } from 'app/deliveryTool/context/FiltersProvider'


const DnDDeliveries = (props) => {

  const {
    items,
    type,
    dragDisabled
  } = props

  const filters = useFilters();

  const styles = {
    title: {
      color: '#484b4c',
      fontSize: '1.5rem',
      margin: '1rem 0 1rem 0',
      fontWeight: 'normal'
    },
    subtitle: {
      fontSize: '1.2rem',
      margin: '0rem 0 0rem 0',
      fontWeight: 'normal'
    },
    back: {
      color: '#429853',
      fontSize: '1.0rem',
      cursor: 'pointer'
    },
    center: {
      textAlign: 'center'
    },
    block: {
      backgroundColor: 'white',
      margin: '1.0rem 0.5rem',
      padding: '0.5rem',
    },
    statut: {
      textTransform: 'uppercase',
      fontSize: '0.6rem',
      marginBottom: '0rem'
    },
    para:{
      marginBottom: '0rem',
      fontSize: '0.8rem'
    },
  }

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? '#429853' : '',
    border: isDraggingOver ? '1px solid #429853' : '1px solid #dedede',
    padding: '0px 5px',
    width: '100%',
    minHeight: '150px'
  });

  return (

    <div>
      <div className={css(styles.center)}>
        <h2 className={css(styles.title)}>{type === 'deliveries' ? 'Livraisons' : 'Récupérations'} non attribuées</h2>
      </div>
      <Droppable droppableId={type}>
          {(provided, snapshot) => (

              <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}>
                  {items.map((item, index) => (
                    filters.orderType === 'all' || item.type === filters.orderType
                      ?
                      <Draggable
                          isDragDisabled={dragDisabled}
                          key={type === 'deliveries' ? item.id : item.id + '001'}
                          draggableId={type === 'deliveries' ? item.id : item.id + '001'}
                          index={index}>
                          {(provided, snapshot) => (
                              <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={css({opacity: dragDisabled ? '0.5' : '1'})}>
                                  {type === 'deliveries' ? <DeliveryRecoveryContainer type="delivery" delivery={item} theme="light" /> : <DeliveryRecoveryContainer delivery={item} theme='light' type="recovery"/>}
                              </div>
                          )}
                      </Draggable>
                      :
                      null

                  ))}
                  {provided.placeholder}
              </div>

          )}
      </Droppable>
    </div>
  );
}

DnDDeliveries.propTypes = {
  items        : PropTypes.array,
  type         : PropTypes.string, //deliveries or recoveries,
  dragDisabled : PropTypes.bool
}

export default DnDDeliveries
