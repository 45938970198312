import React from 'react'

import DeliveryRecoveryContainer from 'app/deliveryTool/containers/DeliveryRecoveryContainer'

const RoadMapDeliveryMan = (props) => {

  const {
    roadmap
  } = props

  return (

    <div>
      {roadmap.deliveries.length > 0
        &&
        <div>
          {roadmap.deliveries.map((rmDelivery, index) =>
            <div key={index}>
              {rmDelivery.recovery ?
                <DeliveryRecoveryContainer type="recovery" delivery={rmDelivery.delivery} roadmapName={roadmap.name} sort={rmDelivery.sort} colorDeliveryMan={roadmap.delivery_man ? roadmap.delivery_man.color : 'green'} theme="print" />
              :
                <DeliveryRecoveryContainer type="delivery" delivery={rmDelivery.delivery} colorDeliveryMan={roadmap.delivery_man ? roadmap.delivery_man.color : 'green'} roadmapName={roadmap.name} sort={rmDelivery.sort} theme="print" />
              }
            </div>
          )}
        </div>
      }

    </div>

  )

}

RoadMapDeliveryMan.propTypes = {

}


export default RoadMapDeliveryMan
