import React, {useState, useContext} from 'react'
import { useSelector } from "react-redux";

import { CSSTransitionGroup } from 'react-transition-group'
import { css } from 'emotion'

import Order from 'app/orderTool/pages/manageOrders/Order'
import Invoice from 'app/orderTool/pages/manageOrders/Invoice'
import ModalSendQuotation from 'app/orderTool/pages/manageOrders/ModalSendQuotation'
import { useOrders } from 'app/orderTool/context/OrdersProvider'
import FakeDelivery from 'app/deliveryTool/components/delivery/FakeDelivery'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons"

import Pagination from "react-js-pagination";

const Orders = (props) => {

  const ordersResults = useOrders();

  // State des infos contenu dans la popup d'envoi de devis
  const [infoModal,setInfoModal] = useState({
    isOpen: false,
    data: {}
  })
  // Permet d'ouvrir la popup et d'envoyer les données
  const handleOpenModal = (order) => {
     setInfoModal({
       isOpen: true,
       data: order
     })
   }
   // Permet de fermer la popup et de vider les données
   const handleCloseModal = () => {
     setInfoModal({
       isOpen: false
     })
   }

  const {
    orders,
    dataFilter,
    countOrders
  } = ordersResults

  const orderEdition = useSelector((state) => state.orderEdition);
  
  const styles = {
    title:{
      color: 'grey',
      fontSize: '1.0rem',
      marginTop: '1.0rem',
      textAlign: 'right',
    },
    load: {
      background: 'white',
      margin: '0.5rem 0',
      border: '1px solid #ddd',
      padding: '1.0rem',
      fontWeight: 'normal'
    },
    currentOrder : {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginTop: '10px',
      padding: '5px',
    }
  }

  const handlePageChange = (pageNumber) => {

    //dataFilter.setOffset((pageNumber - 1) * 10)
    ordersResults.setDataFilter(
      {
        search: dataFilter.search,
        startDate: dataFilter.startDate,
        endDate: dataFilter.endDate,
        type: dataFilter.type,
        format: dataFilter.format,
        status: dataFilter.status,
        startDatePaid: dataFilter.startDatePaid,
        endDatePaid: dataFilter.endDatePaid,
        price: dataFilter.price,
        chorus: dataFilter.chorus,
        limit: 10,
        orderBy: dataFilter.orderBy,
        offset : (pageNumber - 1) * 10,
        tender_id: dataFilter.tender_id,
        selectedOrders: dataFilter.selectedOrders
      });

  }

  const handleSelectOrder = (order) => {
    ordersResults.setSelectedOrders(prevOrders => [...prevOrders, {id: order.id, orderType: order.info.order_type, orderNumber: order.info.orderNumber}]);
  };
  
  const handleDeselectOrder = (orderId) => {
    ordersResults.setSelectedOrders(prevOrders => prevOrders.filter(order => order.id !== orderId));
  };

  return (
    <div className={css(styles.scroll)}>
      { (orderEdition.customer  && Object.keys(orderEdition.customer).length > 0) && 
        <div className={css(styles.currentOrder)}>
          {orderEdition.orders[0].id === null ?
            <Link id="multiOrders" to={{
              pathname: `/commande`,
              search: `${window.location.search}`
            }}><FontAwesomeIcon icon={faFolderOpen} /> Commande en cours non sauvegardée {orderEdition.customer.firstname} {orderEdition.customer.lastname} - Rejoindre - </Link>
          :
            <Link id="multiOrders" to={{
              pathname: `/commande`,
              search: `${window.location.search}&order_id=${orderEdition.orders[0].id}&order_type=${orderEdition.orders[0].info.order_type}`
            }}><FontAwesomeIcon icon={faFolderOpen} /> Dernière commande ouverte {orderEdition.orders[0].info.orderNumber} - {orderEdition.customer.firstname} {orderEdition.customer.lastname} -> Ouvrir </Link>
          }
          
        </div>
      }
      <h1 className={css(styles.title)}>Résultats : {countOrders} {dataFilter.type === 'invoice' ? 'factures' : 'devis'} {dataFilter.startDate && dataFilter.endDate ? 'du '+dataFilter.startDate+' au '+dataFilter.endDate : 'toutes dates'}</h1>
      <CSSTransitionGroup
        transitionName="fade"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={200}>

        {ordersResults.loading ?
           <FakeDelivery />
          :
          <div>
            {countOrders > 0
              ?
                <div>
                  {orders && orders.map((order, index) =>
                    <>
                      {order.status >= 200 && order.status < 300 ?
                        <Invoice order={order} key={index}/>
                      :
                        <Order 
                          order={order} 
                          key={index} 
                          handleOpenModal={handleOpenModal}
                          handleSelectOrder={handleSelectOrder}
                          handleDeselectOrder={handleDeselectOrder}
                          isSelected={ordersResults.selectedOrders.some(selectedOrder => selectedOrder.id === order.id)}
                        />
                      }
                    </>
                  )}
                </div>
              :
                <p className={css(styles.load)}>
                  <b>Il n'y a pas de résultat pour cette recherche.</b>
                </p>
            }

          </div>
        }

      </CSSTransitionGroup>

      <Pagination
          activePage={dataFilter.offset === 0 ? 1 : (parseInt(dataFilter.offset) + 10) / 10}
          itemsCountPerPage={10}
          totalItemsCount={countOrders}
          pageRangeDisplayed={10}
          itemClass="page-item"
          linkClass="page-link"
          onChange={handlePageChange}
        />

      <ModalSendQuotation isOpen={infoModal.isOpen} data={infoModal.data}  handleCloseModal={handleCloseModal}/>
    </div>
  )

}

export default Orders
