import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setOrderDeliveryInfo,
  setOrderStatus,
} from "state/orderEdition/actions";
import { Form, Row, Col } from "react-bootstrap";

const HeaderOrderInfoComponent = ({ orderIndex }) => {
  const dispatch = useDispatch();
  const deliveryInfo = useSelector(
    (state) => state.orderEdition.orders[orderIndex].deliveryInfo
  );
  const orderStatus = useSelector(
    (state) => state.orderEdition.orders[orderIndex].status
  );

  const [status, setStatus] = useState(orderStatus || "");
  const [deliveryDate, setDeliveryDate] = useState(
    deliveryInfo?.deliveryDate || ""
  );
  const [person, setPerson] = useState(deliveryInfo?.person || "");
  const [startAt, setStartAt] = useState(deliveryInfo?.startAt || "");
  const [endAt, setEndAt] = useState(deliveryInfo?.endAt || "");
  const [arrivedAtStart, setArrivedAtStart] = useState(
    deliveryInfo?.arrivedAtStart || ""
  );
  const [arrivedAtEnd, setArrivedAtEnd] = useState(
    deliveryInfo?.arrivedAtEnd || ""
  );
  const [blanket, setBlanket] = useState(deliveryInfo?.blanket || false);
  const [withService, setWithService] = useState(deliveryInfo?.service || false);

  useEffect(() => {
    if (
      !deliveryInfo ||
      startAt !== deliveryInfo.startAt ||
      endAt !== deliveryInfo.endAt ||
      deliveryDate !== deliveryInfo.deliveryDate ||
      person !== deliveryInfo.person ||
      arrivedAtStart !== deliveryInfo.arrivedAtStart ||
      arrivedAtEnd !== deliveryInfo.arrivedAtEnd ||
      withService !== deliveryInfo.service ||
      blanket !== deliveryInfo.blanket
    ) {
      dispatch(
        setOrderDeliveryInfo(orderIndex, {
          ...deliveryInfo,
          startAt: startAt,
          endAt: endAt,
          deliveryDate: deliveryDate,
          person: person,
          arrivedAtStart: arrivedAtStart,
          arrivedAtEnd: arrivedAtEnd,
          service: withService,
          blanket: blanket,
        })
      );
    }
  }, [
    dispatch,
    orderIndex,
    deliveryInfo,
    startAt,
    endAt,
    deliveryDate,
    person,
    arrivedAtStart,
    arrivedAtEnd,
    withService,
    blanket,
  ]);

  useEffect(() => {
    if (status !== orderStatus) {
      dispatch(setOrderStatus(orderIndex, status));
    }
  }, [dispatch, orderIndex, orderStatus, status]);

  // Assumons que vous avez une liste de statuts
  let statusOptions = [
    {
      option: "101",
      traduction: "En attente",
    },
    {
      option: "102",
      traduction: "Validé",
    },
    {
      option: "103",
      traduction: "A vérifier avant facturation",
    },
    {
      option: "401",
      traduction: "Refusé par le client",
    },
  ];

  return (
    <>
      <Row>
        <Col>
          <Form.Group controlId="statusSelect">
            <Form.Label>Statut*</Form.Label>
            <Form.Control
              as="select"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="">Sélectionnez un statut</option>
              {statusOptions.map((sta, index) => (
                <option key={index} value={sta.option}>
                  {sta.traduction}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="deliveryDate">
            <Form.Label>Date de livraison*</Form.Label>
            <Form.Control
              type="date"
              value={deliveryDate}
              onChange={(e) => setDeliveryDate(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="person">
            <Form.Label>Nombre de personnes*</Form.Label>
            <Form.Control
              type="number"
              value={person}
              onChange={(e) => setPerson(e.target.value)}
              required
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="arrivedAtStart">
            <Form.Label>Début livraison</Form.Label>
            <Form.Control
              type="time"
              value={arrivedAtStart}
              onChange={(e) => setArrivedAtStart(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="arrivedAtEnd">
            <Form.Label>Fin livraison</Form.Label>
            <Form.Control
              type="time"
              value={arrivedAtEnd}
              onChange={(e) => setArrivedAtEnd(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="startAt">
            <Form.Label>Début presta</Form.Label>
            <Form.Control
              type="time"
              value={startAt}
              onChange={(e) => setStartAt(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="endAt">
            <Form.Label>Récupération</Form.Label>
            <Form.Control
              type="time"
              value={endAt}
              onChange={(e) => setEndAt(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="blanket">
            <Form.Check
              type="checkbox"
              label="Nappe"
              checked={blanket}
              onChange={(e) => setBlanket(e.target.checked)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="withService">
            <Form.Check
              type="checkbox"
              label="Avec service"
              checked={withService}
              onChange={(e) => setWithService(e.target.checked)}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default HeaderOrderInfoComponent;
