import React from 'react'
import { css } from 'emotion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const UrlQuotation = (props) => {

  const {
    url,
    type,
    old
  } = props

  const styles = {
    blockColor: {
      height: '25px',
      width: '32px',
      top: '0',
      right: '0',
      color: 'white',
      position: 'absolute',
      textAlign: 'center',
      backgroundColor: old ? 'black ': '#429853',
      '&:hover': {
        color: 'black'
      }
    },


  }

  return (
    <a href={url} target="_blank" className={css(styles.blockColor)}  rel="noopener noreferrer">
      {type === 'recovery' ?
        <FontAwesomeIcon icon={faCheckCircle} />
      :
        <><FontAwesomeIcon icon={faFileAlt} /></>
      }

    </a>
  )

}

export default UrlQuotation
