import React from 'react'
import { css } from 'emotion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngry, faFrown, faFlushed, faGrin, faGrinStars } from '@fortawesome/free-solid-svg-icons'

const Reviews = (props) => {

  const {
    note
  } = props

  const color = () => {
    if(note === 1 ){
      return '#ff4d4d'
    }
    else if(note === 2 ){
      return '#e68a00'
    }
    else if(note === 3 ){
      return '#ffcc00'
    }
    else if(note === 4 ){
      return '#86b300'
    }
    else if(note === 5 ){
      return '#009933'
    }
    else{
      return 'white'
    }
  }

  const styles = {
    blockColor: {
      height: '33px',
      width: '29px',
      position: 'absolute',
      top: '0',
      right: '0',
      fontSize: '22px',
      opacity: '0.6',
      color: color()
    },


  }

  return (
    <div>
      {note === 0 ?
        <div></div>
      :
        <div className={css(styles.blockColor)}>
          {note === 1 ?
              <FontAwesomeIcon icon={faAngry} />
          : note === 2 ?
              <FontAwesomeIcon icon={faFrown} />
          : note === 3 ?
              <FontAwesomeIcon icon={faFlushed} />
          : note === 4 ?
              <FontAwesomeIcon icon={faGrin} />
          : note === 5 ?
              <FontAwesomeIcon icon={faGrinStars} />
          :
              null
          }

        </div>
      }
    </div>
  )

}

export default Reviews
