import React from "react"
import PropTypes from 'prop-types'
import { compose, withProps, lifecycle } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps"

import Direction from 'app/universal/map/Direction'

const DirectionsMap = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDoRZ213t3WTSCF8JE27W9jLnhQunAODsI&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `300px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
  lifecycle({
    componentDidMount() {

    }
  })
)((props) =>
  <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: 45.7613911, lng: 4.9167728 }}>
    <Direction  deliveries={props.roadmap.deliveries} color={props.roadmap.delivery_man ? props.roadmap.delivery_man.color : 'green'} handleResult={() => {}} isLoading={props.isLoading}/>
  </GoogleMap>
);

DirectionsMap.propTypes = {
  roadmap: PropTypes.object
}

export default DirectionsMap
