// DeliveryFee.js
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CurrencyInput from "react-currency-input-field";
import { updateDeliveryCost } from "state/orderEdition/actions";

const DeliveryFeeComponent = ({ orderIndex }) => {
  const dispatch = useDispatch();
  const deliveryCost = useSelector((state) =>
    state.orderEdition.orders[orderIndex]
      ? state.orderEdition.orders[orderIndex].deliveryCost
      : 0
  );

  const handleValueChange = (value) => {
    dispatch(updateDeliveryCost(orderIndex, parseFloat(value * 100)));
  };

  return (
    <div>
      <label>Frais de livraison HT</label>
      <CurrencyInput
        id="deliveryCost"
        name="deliveryCost"
        placeholder="Montant"
        className="form-control"
        defaultValue={deliveryCost / 100}
        decimalSeparator="."
        decimalsLimit={2}
        decimalScale={2}
        allowNegativeValue={false}
        intlConfig={{ locale: "fr-FR", currency: "EUR" }}
        onValueChange={handleValueChange}
      />
    </div>
  );
};

export default DeliveryFeeComponent;
