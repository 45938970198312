import {
  RESET_ORDER_EDITION,
  INITIALIZE_ORDER_EDITION,
  SET_ORDER_EDITION_INFO,
  SET_DELIVERY_INFO,
  SET_BILLING_INFO,
  SET_CUSTOMER,
  SET_COMPANY,
  SET_TENDER,
  SET_CONFIRM_TENDER,
  SET_FORMAT,
  UPDATE_ORDER_ITEMS,
  UPDATE_REDUCTION,
  UPDATE_DELIVERY_COST,
  ADD_ORDER,
  SET_CURRENT_ORDER_INDEX,
  REMOVE_ORDER,
  SET_ORDER_STATUS,
  SET_ORDER_DELIVERY_INFO,
  FETCH_ORDER,
  FETCH_ORDER_COMPLETED,
  FETCH_ORDER_FAILED,
  SAVE_ORDER,
  SAVE_ORDER_COMPLETED,
  SAVE_ORDER_FAILED,
  SET_ORDER_INFO,
} from "./actions";
import { breakfastOrderApiToStore, orderStateToTotal } from "helper/transformDataHelper";

const initialState = {
  deliveryInfo: {
    firstname: "",
    lastname: "",
    streetNumber: "",
    street: "",
    postalCode: "",
    city: "",
    phone: "",
    startAt: "",
    endAt: "",
    arrivedAtStart: "",
    arrivedAtEnd: "",
    person: 0,
    addressComplement: "",
  },
  billingInfo: {
    firstname: "",
    lastname: "",
    streetNumber: "",
    street: "",
    postalCode: "",
    city: "",
    phone: "",
    addressComplement: "",
  },
  customer: {},
  company: {},
  tender: {},
  confirmTender: false,
  status: null,
  currentOrder: {},
  currentOrderIndex: 0,
  customerSpecialCommentary: "", // Commentaire visible
  customerSpecialReference: "", // Référence client
  codeServiceChorus: "", // Code Chorus
  orderFormDate: "", // Date de réception du bon de commande
  disableApproachingMail: false, // Relance en approche
  orders: [
    {
      id: null,
      format: null,
      blanket: false,
      service: false,
      commentaryForProd: "",
      status: "101",
      reduction: {
        label: "",
        direct: 0,
        proportional: 0,
      },
      deliveryCost: 0,
      items: [],
      totalHT: 0,
      totalTTC: 0,
      info: {},
      version: 'v2'
    },
  ],
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case RESET_ORDER_EDITION:
      return initialState;
    case INITIALIZE_ORDER_EDITION:
      return {
        ...state,
        ...initialState,
      };
    case SET_ORDER_EDITION_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case SET_DELIVERY_INFO:
      return {
        ...state,
        deliveryInfo: action.payload,
      };
    case SET_BILLING_INFO:
      return {
        ...state,
        billingInfo: action.payload,
      };
    case SET_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
      };
    case SET_COMPANY:
      return {
        ...state,
        company: action.payload,
      };
    case SET_TENDER:
      return {
        ...state,
        tender: action.payload,
      };
    case SET_CONFIRM_TENDER:
      return {
        ...state,
        confirmTender: action.payload,
      };
    case SET_FORMAT:
      return {
        ...state,
        orders: state.orders.map((order, index) => {
          if (index === action.payload.orderIndex) {
            return {
              ...order,
              format: action.payload.formatTxt,
            };
          }
          return order;
        }),
      };
    case SAVE_ORDER:
      return {
        ...state,
        isLoading: true,
      };
    case SAVE_ORDER_COMPLETED:
      return {
        ...state,
        isLoading: false,
        orders: state.orders.map((order, index) => {
          // if ( state.orders.length === 1 && index === 0) {
          //   return {
          //     ...order,
          //     id: action.payload?.result?.order?.id || null
          //   };
          // }
          // else if (index === action.payload.orderIndex) {
          //   return {
          //     ...order,
          //     id: action.payload?.result?.order?.id || null
          //   };
          // }
          return order;
        }),
      };
    case SAVE_ORDER_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_ORDER_ITEMS:
      return {
        ...state,
        orders: state.orders.map((order, index) => {

          // Ajoute items si non défini
          if (order.items === undefined) {
            order.items = [];
          }

          if (index === action.payload.orderIndex) {
            const newItems = action.payload.items;
            const itemType = action.payload.itemType;
    
            let updatedItems;
            if (itemType === 'formula') {
              // Filtrer les éléments existants pour exclure les formules
              updatedItems = order.items.filter(item => item.type !== 'formula');
              // Ajouter les nouvelles formules
              updatedItems = [...updatedItems, ...newItems.map(item => ({...item, type: itemType}))];
            } else if (itemType === 'product') {
              // Filtrer les éléments existants pour exclure les produits simples
              updatedItems = order.items.filter(item => item.type !== 'product');
              // Ajouter les nouveaux produits simples
              updatedItems = [...updatedItems, ...newItems.map(item => ({...item, type: itemType}))];
            }
    
            const {
              totalHT,
              totalTTC,
            } = orderStateToTotal({ ...order, items: updatedItems });
    
            return {
              ...order,
              items: updatedItems,
              totalHT,
              totalTTC,
            };
          }
          return order;
        }),
      };
    case UPDATE_REDUCTION:
      return {
        ...state,
        orders: state.orders.map((order, index) => {
          if (index === action.payload.orderIndex) {
            const newReduction = action.payload.reduction;
            const {
              totalHT,
              totalTTC,
            } = orderStateToTotal({ ...order, reduction: newReduction });
            return {
              ...order,
              reduction: newReduction,
              totalHT,
              totalTTC,
            };
          }
          return order;
        }),
      };
    case UPDATE_DELIVERY_COST:
      return {
        ...state,
        orders: state.orders.map((order, index) => {
          if (index === action.payload.orderIndex) {
            const newCost = action.payload.cost;
            const {
              totalHT,
              totalTTC,
            } = orderStateToTotal({ ...order, deliveryCost: newCost });
            return {
              ...order,
              deliveryCost: newCost,
              totalHT,
              totalTTC,
            };
          }
          return order;
        }),
      };
    case ADD_ORDER:
      return {
        ...state,
        orders: [
          ...state.orders,
          {
            reduction: {
              label: "",
              direct: 0,
              proportional: 0,
            },
            deliveryCost: 0,
            items: [],
            totalHT: 0,
            totalTTC: 0,
            deliveryInfo: {
              startAt: "",
              endAt: "",
              deliveryDate: "",
              person: "",
              arrivedAtStart: "",
              arrivedAtEnd: "",
            },
          },
        ],
        currentOrderIndex: state.orders.length,
      };
    case SET_CURRENT_ORDER_INDEX:
      return {
        ...state,
        currentOrderIndex: action.payload,
      };
    case REMOVE_ORDER:
      const prevIndex = state.currentOrderIndex - 1;

      return {
        ...state,
        currentOrderIndex: prevIndex >= 0 ? prevIndex : null,
        orders: state.orders.filter((order, index) => index !== action.payload),
      };
    case SET_ORDER_STATUS:
      return {
        ...state,
        orders: state.orders.map((order, index) => {
          if (index === action.payload.orderIndex) {
            return {
              ...order,
              status: action.payload.status,
            };
          }
          return order;
        }),
      };
    case SET_ORDER_DELIVERY_INFO:
      return {
        ...state,
        orders: state.orders.map((order, index) => {
          if (index === action.payload.orderIndex) {
            return {
              ...order,
              deliveryInfo: action.payload.deliveryInfo,
            };
          }
          return order;
        }),
      };
    case FETCH_ORDER:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_ORDER_COMPLETED:
      return {
        ...state,
        isLoading: false,
        ...breakfastOrderApiToStore(action.payload.result),
        currentOrderIndex: 0,
      };
    case FETCH_ORDER_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case SET_ORDER_INFO:
      return {
        ...state,
        orders: state.orders.map((order, index) => {
          if (index === action.payload.orderIndex) {
            return {
              ...order,
              ...action.payload.order,
            };
          }
          return order;
        }),
      };
    default:
      return state;
  }
};

export default reducers;
