import React from 'react'
import {Row, Col} from 'react-bootstrap'
import { css } from 'emotion'
// A SUPPRIMER PLUS UTILISE
const Composition = (props) => {

  const {
    dataCompositions,
    size,
    col
  } = props

  const styles = {
    product:{
      color:'#429853',
      fontWeight: 'bold',
      fontSize: 1 * size+'rem'
    },
    quantity:{
      textAlign:'right',
      fontSize: 1 * size+'rem'
    },
    compoProduct:{
      paddingRight: '2.0rem',
      color:'#aaa',
      fontSize: 0.8 * size+'rem',
      fontStyle: 'italic',
    },
    compoQuantity:{
      textAlign:'right',
      fontStyle: 'italic',
      color:'#aaa',
      fontSize: 0.8 * size+'rem',
    },
    part:{
      color: '#bbb',
      textAlign: 'center',
      fontSize: '1.4rem',
    },
    sheet:{
      marginTop: '1.0rem',
      backgroundColor: 'white',
      paddingTop:'1.0rem',
      paddingBottom: '1.0rem',
      pageBreakAfter: 'always'
    },
    typeProduct:{
      fontSize: '1.2rem',
      backgroundColor: '#429853',
      color: 'white',
      padding: '5px',
      display: 'inline-block',
      marginTop: '1.0rem',
      '@media print': {
          WebkitPrintColorAdjust: 'exact'
      }
    },
    hr: {
      marginTop: '0.1rem',
      marginBottom: '0.1rem'
    },
    pageBreak:{
      pageBreakAfter: 'always'
    },
    date:{
      background: 'none',
      border: 'thin solid #bbb',
      color: '#555',
      padding: '0.04rem 0.4rem'
    },
    filter:{
      borderBottom: '1px #ddd solid',
      padding: '10px 10px 10px 10px',
    },
    action: {
      'borderBottom': '1px #ddd solid',
      'padding': '10px 10px 10px 10px',
      'textAlign': 'right'
    },
    center:{
      textAlign: 'center'
    },
    print: {
      cursor: 'pointer',
      display:'inline-block',
      border: 'thin solid #bbb',
      color: '#555',
      padding: '0.2rem 1rem',
      transition: '300ms',
      ':hover': {
        textDecoration: 'none',
        color: 'white',
        backgroundColor: '#bbb',
        transition: '300ms'
      }
    }
  }

  return (
    <Row>
      {dataCompositions && dataCompositions.map((assemble_product, index) =>
        <Col xs={12} sm={col} md={col} key={'assemble'+index}>
          <hr className={css(styles.hr)}/>
          <Row>
            <Col xs={12} sm={8} md={8} className={css(styles.product)}>
              {assemble_product.name}
            </Col>
            <Col xs={12} sm={8} md={4} className={css(styles.quantity)}>
              {assemble_product.total.toFixed(4)} {assemble_product.unit}
            </Col>
            {assemble_product.composition && assemble_product.composition.length > 0 && assemble_product.composition.map((compositionProduct, index) =>
              <Col xs={12} sm={12} md={12} key={'composition'+index}>
                <Row>
                  <Col xs={12} sm={8} md={8} className={css(styles.compoProduct)}>
                    {compositionProduct.name}
                  </Col>
                  <Col xs={12} sm={8} md={4} className={css(styles.compoQuantity)}>
                    {compositionProduct.total.toFixed(4)} {compositionProduct.unit}
                  </Col>
                </Row>
                <Col xs={12} sm={12} md={12}>
                  {compositionProduct.composition && compositionProduct.composition.length > 0 && <Composition dataCompositions={compositionProduct.composition} size={0.7} col={12} />}
                </Col>
              </Col>
            )}
          </Row>

        </Col>

      )}
    </Row>
  )

}

export default Composition
