import React from "react"
import PropTypes from 'prop-types'
import { compose, withProps, lifecycle } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps"
import { animateScroll as scroller } from 'react-scroll'

import Direction from 'app/universal/map/Direction'

const DirectionsMap = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDoRZ213t3WTSCF8JE27W9jLnhQunAODsI&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `calc(100vh - 101px)` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
  lifecycle({
    componentDidMount() {

      const refs = {}
      this.setState({
        handleOver: (event) => {
        },
        onClick: (event) => {
        },
        onMapMounted: ref => {
          refs.map = ref;
        },
        deliveryOptions: {
          draggable: false,
          icon: {
            path: 'M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0',
            fillColor: 'black',
            fillOpacity: 0.8,
            scale: 0.2,
            strokeColor: 'black',
            strokeWeight: 1
          }
        },
        isInfoWindowOpen: [],
        onInfoWindowClick: (deliveryId) => {
          if (this.state.isInfoWindowOpen[deliveryId]) {
            this.state.isInfoWindowOpen[deliveryId] = !this.state.isInfoWindowOpen[deliveryId]
          }
          else {
            this.state.isInfoWindowOpen[deliveryId] = true
          }
        }
      })
    }
  })
)((props) =>
  <div>
    <GoogleMap
      ref={props.onMapMounted}
      onMouseOver={props.handleOver}
      onClick={props.onClick}
      defaultZoom={12}
      defaultCenter={{ lat: 45.7613911, lng: 4.9167728 }}
    >
      {props.recoveries.map((e,i) =>
        <Marker position={{ lat: e.lat, lng: e.lng }} key={e.id}
        clickable={true}
        onClick={() => {
          props.onInfoWindowClick(e.id)
          props.onMarkerClick(e.id)
        }}
        options={props.deliveryOptions}>
          {props.isInfoWindowOpen && props.isInfoWindowOpen[e.id] &&
            <InfoWindow onCloseClick={props.onInfoWindowClick(e.id)}>
              <div><b>{e.company}</b><br/>{e.stret_number} {e.street}, {e.postal_code} {e.city}</div>
            </InfoWindow>}
        </Marker>
      )}

      {props.deliveries.map((e,i) =>
        <Marker position={{ lat: e.lat, lng: e.lng }} key={e.id}
        clickable={true}
        onClick={() => {
          props.onInfoWindowClick(e.id)
          props.onMarkerClick(e.id)
          scroller.scrollTo('delivery-'+e.id+'', {
            duration: 800,
            containerId: 'listing',
            delay: 0,
            smooth: 'easeInOutQuart'
          })
        }}
        options={props.deliveryOptions}>
          {props.isInfoWindowOpen && props.isInfoWindowOpen[e.id] &&
            <InfoWindow onCloseClick={props.onInfoWindowClick(e.id)}>
              <div><b>{e.company}</b><br/>{e.stret_number} {e.street}, {e.postal_code} {e.city}</div>
            </InfoWindow>}
        </Marker>
      )}

      {props.roadmaps.length > 0 && props.roadmaps.map((roadmap, index) =>
        parseInt(props.filters.showRoadmap) === parseInt(roadmap.id) ?
          <Direction deliveries={roadmap.deliveries} color={roadmap.delivery_man ? roadmap.delivery_man.color : 'green'} handleResult={props.handleRouteData} key={roadmap.id} isLoading={props.isLoading}/>
          : null
      )}

    </GoogleMap>
  </div>
);

DirectionsMap.propTypes = {
  roadmaps       : PropTypes.array,
  handleRouteData: PropTypes.func,
  recoveries     : PropTypes.array,
  deliveries     : PropTypes.array
}

export default DirectionsMap
