import React from 'react'
import { css } from 'emotion'

const DeliveryTypeFilter = (props) => {

  const {
    deliveryType,
    handleChange
  } = props

  return (
    <select value={deliveryType} onChange={handleChange} className={css(styles.filterSelect)}>
      <option value="all">Livraison & Récupération</option>
      <option value='delivery'>Livraison</option>
      <option value='recovery'>Récupération</option>
    </select>
  )

}

const styles = {
  filterSelect: {
    background: 'none',
    border: 'thin solid #bbb',
    color: '#555',
    padding: '0.1rem 0.1rem',
  }
}

export default DeliveryTypeFilter
