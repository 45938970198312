import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { updateOrderItems } from "state/orderEdition/actions";
import { getProductItemsByOrderIndex } from "state/orderEdition/selectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import CurrencyInput from "react-currency-input-field";
import Select from "react-select";

const ProductsComponent = ({ title, orderIndex, products }) => {
  const dispatch = useDispatch();
  const items = useSelector((state) =>
    getProductItemsByOrderIndex(state, orderIndex)
  );
  const productsForSelect = products.map((product) => ({ value: product.id, label: product.name, ...product }));

  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(items);

  // Synchronise l'état local avec le store
  useEffect(() => {
    dispatch(updateOrderItems(orderIndex, selectedProducts, "product"));
  }, [selectedProducts, orderIndex, dispatch]);

  const handleSelectChange = (selectedItem) => {
    setSelectedProduct(selectedItem);
  };

  const addProduct = (productId, quantity) => {
    const product = products.find((f) => f.id === Number(productId));
    const updatedProduct = {
      ...product,
      quantity: quantity,
    };
    setSelectedProducts([...selectedProducts, updatedProduct]);
  };

  const handleAddProduct = () => {
    if (selectedProduct.value) {
      addProduct(selectedProduct.value, 1);
    }
  };

  const handleRemoveProduct = (index) => {
    setSelectedProducts(selectedProducts.filter((_, i) => i !== index));
  };

  return (
    <Container>
      <Row>
        <Col sm={12} md={6}>
          <h5>{title}</h5>
        </Col>
      </Row>
      {selectedProducts.map((product, index) => (
        <Row key={index}>
          <Col md={4}>
            <Form.Control type="text" value={product.name} readOnly />
          </Col>
          <Col md={4}>
            <Form.Control
              type="number"
              defaultValue={product.quantity}
              onChange={(e) => {
                let newProducts = [...selectedProducts];
                newProducts[index].quantity = e.target.value <= 0 ? 1 : e.target.value;
                setSelectedProducts(newProducts);
              }}
            />
          </Col>
          <Col md={3}>
            <CurrencyInput
              id="productPrice"
              name="productPrice"
              placeholder="Montant"
              className="form-control"
              defaultValue={(product.price || 0) / 100}
              decimalSeparator="."
              decimalsLimit={2}
              decimalScale={2}
              allowNegativeValue={false}
              intlConfig={{ locale: "fr-FR", currency: "EUR" }}
              onValueChange={(value, name) => {
                let newProducts = [...selectedProducts];
                newProducts[index].price = parseFloat(value * 100);
                setSelectedProducts(newProducts);
              }}
            />
          </Col>
          <Col md={1}>
            <Button variant="danger" onClick={() => handleRemoveProduct(index)}>
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </Col>
        </Row>
      ))}
      <hr />
      <Form>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="productSelect">
            <Form.Label>Produit</Form.Label>
            <Select
              placeholder="Chercher un produit"
              options={productsForSelect}
              closeMenuOnSelect={true}
              onChange={handleSelectChange}
              value={selectedProduct}
              name={"productId"}
            />
          </Form.Group>
        </Row>
        <Button variant="success" onClick={handleAddProduct}>
          Ajouter ce produit
        </Button>
      </Form>
    </Container>
  );
};

export default ProductsComponent;
