import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CurrencyInput from "react-currency-input-field";
import { updateReduction } from "state/orderEdition/actions";
import { Form } from "react-bootstrap";

const ReductionComponent = ({ orderIndex }) => {
  const dispatch = useDispatch();
  const reduction = useSelector((state) =>
    state.orderEdition.orders[orderIndex]
      ? state.orderEdition.orders[orderIndex].reduction
      : {}
  );

  const [reductionType, setReductionType] = useState(
    reduction.direct ? "direct" : reduction.proportional ? "proportional" : ""
  );

  const handleValueChange = (field, value) => {
    if (field === "direct" || field === "proportional") {
      if (value === "" || value === null || value === undefined || value === 0) {
        setReductionType("");
      } else {
        setReductionType(field);
      }
    }
    dispatch(updateReduction(orderIndex, { ...reduction, [field]: value }));
  };

  return (
    <Form>
      <Form.Group>
        <Form.Label>Libellé de la réduction</Form.Label>
        <Form.Control
          id="reductionLabel"
          name="reductionLabel"
          placeholder="Libellé"
          defaultValue={reduction.label}
          onChange={(e) => handleValueChange("label", e.target.value)}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Réduction directe</Form.Label>
        <CurrencyInput
          id="directReduction"
          name="directReduction"
          placeholder="Montant"
          className="form-control"
          defaultValue={reduction.direct / 100}
          decimalSeparator="."
          decimalsLimit={2}
          decimalScale={2}
          allowNegativeValue={false}
          intlConfig={{ locale: "fr-FR", currency: "EUR" }}
          onValueChange={(value) => handleValueChange("direct", isNaN(value) ? null : parseFloat(value * 100))}
          disabled={reductionType === "proportional"}
          style={reductionType === "proportional" ? { backgroundColor: '#cccccc' } : {}}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Réduction proportionnelle (%)</Form.Label>
        <Form.Control
          type="number"
          step="0.01"
          id="proportionalReduction"
          name="proportionalReduction"
          placeholder="Pourcentage"
          defaultValue={reduction.proportional}
          onChange={(e) => handleValueChange("proportional", e.target.value)}
          disabled={reductionType === "direct"}
          style={reductionType === "direct" ? { backgroundColor: '#cccccc' } : {}}
        />
      </Form.Group>
    </Form>
  );
};

export default ReductionComponent;
