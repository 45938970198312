import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Container, Row, Col } from "react-bootstrap";
import { css } from "emotion";

import Translate from "app/universal/utils/Translate";
import UrlQuotation from "app/universal/utils/UrlQuotation";
import UpdateDataApi from "app/universal/utils/UpdateDataApi";
import Reviews from "app/universal/utils/Reviews";

const Delivery = (props) => {
  const {
    delivery,
    colorDeliveryMan,
    roadmapName,
    sort,
    filters,
    handler,
    getUrl,
    theme
  } = props;

  const styles = {
    delivery: {
      background: "white",
      margin: "10px 0",
      transition: "300ms",
      border: "1px #dedede solid",
    },
    statut: {
      textTransform: "uppercase",
      fontSize: "0.6rem",
      "@media print": {
        fontSize: "1.4rem",
      },
      marginBottom: "0rem",
    },
    col: {
      padding: "0.5rem",
      borderRight: "1px #dedede solid",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    verticalAlign: {
      display: "flex",
      flexDirection: "row",
      position: "relative"
    },
    para: {
      marginBottom: "0rem",
      fontSize: "0.8rem",
      lineHeight: "1.3",
      "@media print": {
        fontSize: "1.6rem",
      },
      "@media (max-width: 767px)": {
        textAlign: 'left'
      },
    },
    paraCom: {
      marginLeft: "0.5rem",
      marginBottom: "0rem",
      fontSize: "0.8rem",
      "@media print": {
        fontSize: "1.4rem",
      },
    },
    center: {
      textAlign: "center",
    },
    noImg: {
      backgroundColor: "#dedede",
      textAlign: "center",
      color: "#ccc",
      wordBreak: "break-word",
    },
    img: {
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      minHeight: "100px",
      textAlign: "center",
    },
    divisor: {
      height: "1px",
      backgroundColor: "#dedede",
      margin: "1.0rem 0",
    },
    borderRight: {
      "@media (max-width: 767px)": {
        borderRight: "none",
      },
    },
    mobileDivisor: {
      "@media (max-width: 767px)": {
        display: "none",
      },
    },
    blockColor: {
      width: "50px",
      borderRadius: "4px",
      marginBottom: "0.5rem",
      color: "white",
      textShadow: "1px 1px 2px grey",
      textAlign: "center",
    },
    blockColorPrint: {
      height: "25px",
      position: "absolute",
      top: "0",
      right: "0",
      color: "white",
      textShadow: "1px 1px 2px grey",
      textAlign: "center",
      "@media print": {
        fontSize: "2.2rem",
      },
    },
    comment: {
      borderTop: "1px #dedede solid",
      fontSize: "0.8rem",
      "@media print": {
        fontSize: "1.6rem",
      },
    },
    company: {
      textTransform: "uppercase",
      color: "#429853",
      fontWeight: "bold",
      fontSize: "0.8rem",
      "@media print": {
        fontSize: "1.6rem",
      },
    },
    yes: {
      backgroundColor: 'red',
      color: 'white',
      padding: '5px'
    },
    no: {
      backgroundColor: 'grey',
      color: 'white',
      padding: '5px'
    },
  };

  return (
    <div>
      {theme === "light" ? (
        <Container
          className={css(styles.delivery, {
            border: filters.showDelivery === delivery.id && "3px green solid",
          })}
          name={"delivery-" + delivery.id}
          fluid
        >
          <Row className={css(styles.verticalAlign)}>
            <Col xs={12} md={12} className={css(styles.col)}>
              <div
                  className={css(styles.blockColor, {
                    backgroundColor: (colorDeliveryMan ? colorDeliveryMan : 'grey'),
                  })}
                >
                {delivery.info && delivery.info.numbering ? delivery.info.numbering : roadmapName && roadmapName + sort}
              </div>
              <UrlQuotation url={delivery.info.quotation_url} />
              <h3 className={css(styles.statut)}>
                Livraison <Translate item={delivery.type} /> -{" "}
                <b>
                  {delivery.firstname} {delivery.lastname} - {delivery.person}{" "}
                  pers
                </b>
              </h3>
              <p className={css(styles.para)}>
                <span className={css(styles.company)}>{delivery.company}</span>
                <br />
                {delivery.street_number} {delivery.street}{" "}
                {delivery.postal_code} {delivery.city}
                <br />
                <i>{delivery.address_complement}</i>
                <br />
                <br />
              </p>
              <Row noGutters>
                <Col xs={12} md={4}>
                  <p className={css(styles.para)}>Arrivé entre :</p>
                </Col>
                <Col xs={12} sm={12} md={4}>
                  <UpdateDataApi
                    value={delivery.arrived_at_start}
                    id={delivery.id}
                    name={"arrived_at_start"}
                    api={"delivery"}
                    input={"times"}
                    handler={handler}
                  />
                </Col>
                <Col xs={12} md={4}>
                  <UpdateDataApi
                    value={delivery.arrived_at_end}
                    id={delivery.id}
                    name={"arrived_at_end"}
                    api={"delivery"}
                    input={"times"}
                    handler={handler}
                  />
                </Col>
              </Row>
              <Row noGutters>
                <Col xs={12} md={4}>
                  <p className={css(styles.para)}>Début:</p>
                </Col>
                <Col xs={12} md={4}>
                  <UpdateDataApi
                    value={delivery.start_at}
                    id={delivery.id}
                    name={"start_at"}
                    api={"delivery"}
                    input={"times"}
                    handler={handler}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} className={css(styles.col, styles.comment)}>
              {delivery.more_information && (
                <p className={css(styles.paraCom)}>
                  <b>Commentaire Client :</b> {delivery.more_information}
                </p>
              )}
              {delivery.info.commentaryForRecupProd && (
                <p className={css(styles.paraCom)}>
                  <b>Commentaire récupération :</b> {delivery.info.commentaryForRecupProd}
                </p>
              )}
              <UpdateDataApi
                value={delivery.delivery_information}
                id={delivery.id}
                name={"delivery_information"}
                api={"delivery"}
                input={"text"}
                placeholder={"Ajouter un commentaire..."}
                handler={handler}
              />
            </Col>
          </Row>
        </Container>
      ) : theme === "print" ? (
        <Container className={css(styles.delivery)} fluid>
          
          <Row className={css(styles.verticalAlign)}>
            <div
                className={css(styles.blockColorPrint, {
                  backgroundColor: (colorDeliveryMan ? colorDeliveryMan : 'grey'),
                })}
              >
                {delivery.info && delivery.info.numbering ? delivery.info.numbering : roadmapName && roadmapName + sort}
              </div>
            <Col xs={12} md={6} className={css(styles.col)}>
              <h3 className={css(styles.statut)}>Livraison</h3>
              <p className={css(styles.para)}>
                <span className={css(styles.company)}>{delivery.company}</span>
                <br />
                {delivery.street_number} {delivery.street}{" "}
                {delivery.postal_code} {delivery.city}
                <br />
                <i>{delivery.address_complement}</i>
                <br />
                <i>
                  {delivery.firstname} {delivery.lastname} / <a href={`tel:${delivery.phone}`}>{delivery.phone}</a> / {delivery.phone_secondary && <a href={`tel:${delivery.phone_secondary}`}>{delivery.phone_secondary}</a>}
                  <br />
                </i>
              </p>
            </Col>
            <Col xs={12} md={2} className={css(styles.col)}>
              <Row noGutters>
                <Col xs={12} md={12} className={css(styles.borderRight)}>
                  <p className={css(styles.para, styles.center)}>
                    <Translate item={delivery.type} />
                  </p>
                  <p className={css(styles.para, styles.center)}>
                    {delivery.person} personne(s)
                  </p>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={2} className={css(styles.col)}>
              <Row noGutters>
                <Col xs={12} md={12}>
                  <p className={css(styles.para, styles.center)}>
                    Livr entre:{" "}
                    {delivery.arrived_at_start
                      ? moment(delivery.arrived_at_start).format("H:mm")
                      : "NC"}{" "}
                    et{" "}
                    {delivery.arrived_at_start
                      ? moment(delivery.arrived_at_end).format("H:mm")
                      : "NC"}
                  </p>
                </Col>
              </Row>
              <Row noGutters>
                <Col xs={12} md={12}>
                  <p className={css(styles.para, styles.center)}>
                    Début:
                    {delivery.start_at
                      ? moment(delivery.start_at).format("H:mm")
                      : "NC"}{" "}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={2} className={css(styles.col)}>
              {delivery.blanket ? (
                <p className={css(styles.para, styles.center)}>Nappe</p>
              ) : (
                <p className={css(styles.para, styles.center)}>Pas de nappe</p>
              )}
            </Col>
            {delivery.more_information && (
              <Col xs={12} className={css(styles.col, styles.comment)}>
                Commentaire Client : {delivery.more_information}
              </Col>
            )}
            {delivery.delivery_information && (
              <Col xs={12} className={css(styles.col, styles.comment)}>
                Commentaire interne livraison : {delivery.delivery_information}
              </Col>
            )}
            {delivery.info.commentaryForRecupProd && (
              <Col xs={12} className={css(styles.col, styles.comment)}>
                Commentaire récupération : {delivery.info.commentaryForRecupProd}
              </Col>
            )}
          </Row>
        </Container>
      ) : (
        <Container
          className={css(styles.delivery, {
            border: filters.showDelivery === delivery.id && "3px green solid",
          })}
          name={"delivery-" + delivery.id}
          fluid
        >
          <Row className={css(styles.verticalAlign)}>
            <Col xs={12} md={6} className={css(styles.col)}>
              <div
                  className={css(styles.blockColor, {
                    backgroundColor: "green",
                  })}
                >
                {delivery.info && delivery.info.numbering ? delivery.info.numbering : roadmapName && roadmapName + sort}
              </div>
              <UrlQuotation url={delivery.info.quotation_url} />
              <h3 className={css(styles.statut)}>Livraison</h3>
              <p className={css(styles.para)}>
                <span className={css(styles.company)}>{delivery.company}</span>
                <br />
                {delivery.street_number} {delivery.street}{" "}
                {delivery.postal_code} {delivery.city}
                <br />
                <i>{delivery.address_complement}</i>
                <br />
                <br />
                <b>
                  {delivery.firstname} {delivery.lastname}
                </b>
                <br />
                {delivery.phone}
              </p>
            </Col>
            <Col xs={12} md={2} className={css(styles.col)}>
              <Row noGutters>
                <Col xs={6} md={12} className={css(styles.borderRight)}>
                  <p className={css(styles.para, styles.center)}>
                    <Translate item={delivery.type} />
                  </p>
                  <div
                    className={css(styles.divisor, styles.mobileDivisor)}
                  ></div>
                </Col>
                <Col xs={6} md={12}>
                  <p className={css(styles.para, styles.center)}>
                    {delivery.person}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={2} className={css(styles.col)}>
              <Row noGutters>
                <Col xs={12} md={12}>
                  <p className={css(styles.para, styles.center)}>Livr entre:</p>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <UpdateDataApi
                    value={delivery.arrived_at_start}
                    id={delivery.id}
                    name={"arrived_at_start"}
                    api={"delivery"}
                    input={"times"}
                    handler={handler}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <UpdateDataApi
                    value={delivery.arrived_at_end}
                    id={delivery.id}
                    name={"arrived_at_end"}
                    api={"delivery"}
                    input={"times"}
                    handler={handler}
                  />
                </Col>
              </Row>
              <div className={css(styles.divisor)}></div>
              <Row noGutters>
                <Col xs={12} md={6}>
                  <p className={css(styles.para, styles.center)}>Début:</p>
                </Col>
                <Col xs={12} md={6}>
                  <UpdateDataApi
                    value={delivery.start_at}
                    id={delivery.id}
                    name={"start_at"}
                    api={"delivery"}
                    input={"times"}
                    handler={handler}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={2} className={css(styles.col)}>
              <Reviews note={delivery.note} />
              <UpdateDataApi
                label={"Nappe"}
                value={delivery.blanket}
                id={delivery.id}
                name={"blanket"}
                api={"delivery"}
                input={"checkbox"}
                handler={handler}
              />
              <div className={css(styles.divisor)}></div>
              <UpdateDataApi
                label={"A récupérer"}
                value={delivery.recovery}
                id={delivery.id}
                name={"recovery"}
                api={"delivery"}
                input={"checkbox"}
                handler={handler}
              />
              <div className={css(styles.divisor)}></div>
              <p className={css(styles.center)}>
                <small>Récup à cocher : </small>
               {delivery.info.hasBackCondition ?  <span className={css(styles.yes)}>oui</span> : <span className={css(styles.no)}>non</span>}
              </p>
            </Col>
            <Col xs={12} className={css(styles.col, styles.comment)}>
              {delivery.more_information && (
                <p className={css(styles.paraCom)}>
                  <b>Commentaire Client :</b> {delivery.more_information}
                </p>
              )}
              {delivery.info.commentaryForRecupProd && (
                <p className={css(styles.paraCom)}>
                  <b>Commentaire récupération :</b> {delivery.info.commentaryForRecupProd}
                </p>
              )}
              <UpdateDataApi
                value={delivery.delivery_information}
                id={delivery.id}
                name={"delivery_information"}
                api={"delivery"}
                input={"text"}
                placeholder={"Ajouter un commentaire..."}
                handler={handler}
              />
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

Delivery.propTypes = {
  delivery: PropTypes.object,
  colorDeliveryMan: PropTypes.string,
  sort: PropTypes.number
};

export default Delivery;
