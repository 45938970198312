import React from 'react'
import { css } from 'emotion'
import {Col} from 'react-bootstrap'

const Login = (props) => {

  const {
    handleSubmit
  } = props

  const styles = {

    login: {
      minHeight: 'calc(100vh)',
      overflow: 'hidden',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundImage: 'linear-gradient(to right top, #f8f8f6, #e8ecdd, #d3e1c6, #b9d7b4, #9bcda5)',
      width: '100%',
      height: '100%',
      padding: '0 5% 0 5%',
      position: 'relative',
      zIndex: '1000',
      display: 'flex',
      alignItems: 'center',
      WebkitAlignItems: 'center',
      MsAlignItems: 'center',
      '@media screen and (max-width: 768px)': {
        minHeight: 'calc(100vh)',
        display: 'block',
        paddingTop: '40px',
      }
    },
    block: {
      backgroundColor: 'white',
      padding: '30px',
    },
    intro: {
      fontWeight: '700',
      textTransform: 'uppercase',
      lineHeight: '1.2',
      fontSize: '14px',
    },
    sub: {
      fontSize: '11px',
      fontWeight: '400',
      color: '#767676',
    },
    punchline: {
      color: '#429853',
      opacity: '0.8',
      fontSize: '3.5vw',
      padding: '0 2rem',
      '@media screen and (max-width: 768px)': {
        display: 'none'
      }
    },
    logoImg: {
      marginBottom: '20px',
    },
    img: {
      width: '200px',
    },
    label: {
      marginBottom: '5px',
      fontWeight: '700',
      fontSize: '14px',
    },
    button: {
      width: '100%',
      backgroundColor: '#429853',
      color: 'white',
      textAlign: 'center',
      border: 'none',
      fontSize: '18px',
      padding: '10px 5px',
      borderRadius: '4px',
    },
    input: {
      borderRadius: '0px',
      border: '1px solid rgba(34,36,38,0.15)',
      boxShadow: '0 0 0 0 transparent inset',
      transition: 'color .1s ease,border-color .1s ease',
      outline: '0',
      width:'100%',
      height: '34px',
      padding: '6px 12px',
      fontSize: '14px',
      lineHeight: '1.42857143',
    }

  }

  return (

    <div className={css(styles.login)}>
      <Col xs={12} md={4} className={css(styles.block)}>
        <div className={css(styles.logoImg)}>
          <img className={css(styles.img)} src="https://commande.lemoulin.fr/build/images/logo-le-moulin.png" alt="Le Moulin - Commande en ligne" />
        </div>

        <p className={css(styles.intro)}>
          Bienvenue sur la plateforme du Moulin<br />
          <span className={css(styles.sub)}>Pour commencer, connectez-vous...</span>
        </p>

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label className={css(styles.label)}>Identifiant*</label>
            <input type="text" name="username" className={css(styles.input)}/>
          </div>
          <div className="form-group">
            <label className={css(styles.label)}>Mot de passe*</label>
            <input type="password" name="password" className="form-control" className={css(styles.input)}/>
          </div>
          <input type="submit" value="Se connecter" className={css(styles.button)}/>
        </form>

      </Col>

      <Col xs={12} md={8}>
        <p className={css(styles.punchline)}>Vous n'imaginez pas tout ce qui se cache derrière nos salades.</p>
      </Col>

    </div>


  )

}

export default Login
