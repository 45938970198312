import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDeliveryInfo, setOrderInfo } from "state/orderEdition/actions";
import { Container, Row, Col, Form } from "react-bootstrap";

const CommentaryOrderInfoComponent = ({ orderIndex }) => {
  const dispatch = useDispatch();
  const deliveryInfo = useSelector((state) => state.orderEdition.deliveryInfo);
  const order = useSelector((state) => state.orderEdition.orders[orderIndex]);

  const [deliveryInformation, setDeliveryInformation] = useState(
    deliveryInfo.deliveryInformation || ""
  );
  const [commentaryForRecupProd, setCommentaryForRecupProd] = useState(
    order.commentaryForRecupProd || ""
  );
  const [customerSpecialCommentary, setCustomerSpecialCommentary] =
    useState(deliveryInfo.customerSpecialCommentary || "");

  const [commentaryForProd, setCommentaryForProd] = useState(
    order.commentaryForProd || ""
  );

  useEffect(() => {
    if (
      deliveryInformation !== deliveryInfo.deliveryInformation ||
      customerSpecialCommentary !== deliveryInfo.customerSpecialCommentary
    ) {
      dispatch(
        setDeliveryInfo({
          ...deliveryInfo,
          deliveryInformation: deliveryInformation,
          customerSpecialCommentary: customerSpecialCommentary,
        })
      );
    }
    if (
      commentaryForProd !== order?.commentaryForProd || 
      commentaryForRecupProd !== order?.commentaryForRecupProd
    ) {
      dispatch(
        setOrderInfo(orderIndex, {
          ...order,
          commentaryForProd: commentaryForProd,
          commentaryForRecupProd: commentaryForRecupProd,
        })
      );
    }
  }, [
    dispatch,
    deliveryInfo,
    deliveryInformation,
    commentaryForRecupProd,
    commentaryForProd,
    customerSpecialCommentary,
    orderIndex,
    order,
  ]);

  return (
    <Container fluid>
      <Row>
        <Col xs={6} sm={6} md={6} lg={4}>
          <Form.Group controlId="deliveryInformation">
            <Form.Label>Livraison (interne)</Form.Label>
            <Form.Control
              as="textarea"
              value={deliveryInformation}
              onChange={(e) => setDeliveryInformation(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col xs={6} sm={6} md={6} lg={4}>
          <Form.Group controlId="commentaryForProd">
            <Form.Label>Production</Form.Label>
            <Form.Control
              as="textarea"
              value={commentaryForProd}
              onChange={(e) => setCommentaryForProd(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col xs={6} sm={6} md={6} lg={4}>
          <Form.Group controlId="commentaryForRecupProd">
            <Form.Label>Récupération (prod)</Form.Label>
            <Form.Control
              as="textarea"
              value={commentaryForRecupProd}
              onChange={(e) => setCommentaryForRecupProd(e.target.value)}
            />
          </Form.Group>
        </Col>
        {/* Pas utilisé pour le moment
        <Col xs={6} sm={6} md={6} lg={3}>
          <Form.Group controlId="customerSpecialCommentary">
            <Form.Label>Commantaire du client</Form.Label>
            <Form.Control
              as="textarea"
              value={customerSpecialCommentary}
              onChange={(e) => setCustomerSpecialCommentary(e.target.value)}
            />
          </Form.Group>
        </Col> */}
      </Row>
    </Container>
  );
};

export default CommentaryOrderInfoComponent;
