import { createSelector } from "reselect";

const getOrders = (state) => state.orderEdition.orders;

export const getTotalPrices = createSelector([getOrders], (orders) => {
  let totalHT  = 0;
  let totalTTC = 0;

  orders.forEach((order) => {
    totalHT += order.totalHT;
    totalTTC += order.totalTTC;
  });

  return {
    totalHT,
    totalTTC,
  };
});

/**
 * Retourne les items de type formule petit dej
 */
export const getBreakfastFormulaItemsByOrderIndex = createSelector(
  [getOrders, (_, orderIndex) => orderIndex],
  (orders, orderIndex) => {
    const items = orders[orderIndex].items;
    return items.filter(item => item.type === 'formula');
  }
);

/**
 * Retourne les items de type produit
 */
export const getProductItemsByOrderIndex = createSelector(
  [getOrders, (_, orderIndex) => orderIndex],
  (orders, orderIndex) => {
    const items = orders[orderIndex].items;
    return items.filter(item => item.type === 'product');
  }
);

export const getTotalPricesByOrderIndex = createSelector(
  [getOrders],
  (state, orderIndex) => orderIndex,
  (orders, orderIndex) => {
    const order = orders[orderIndex];

    if (!order) {
      return {
        totalHT : 0,
        totalTTC: 0,
      };
    }

    const { totalHT, totalTTC } =
      order;

    return {
      totalHT,
      totalTTC,
    };
  }
);
