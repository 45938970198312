import React from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { css } from 'emotion'

const DateFilter = (props) => {

  const {
    date,
    handleChange
  } = props

  return (
    <div className={css(styles.block)}>
      <DatePicker
        dateFormat="dd/MM/yyyy"
        onChange={handleChange}
        selected={date}
        className={css(styles.date)}
      />
    </div>
  )

}

const styles ={
  date:{
    background: 'none',
    border: 'thin solid #bbb',
    color: '#555',
    padding: '0.04rem 0.4rem'
  }
}
export default DateFilter
