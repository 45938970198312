import React from 'react'
import { css } from 'emotion'

import moment from 'moment'
import {Dropdown} from 'react-bootstrap'


const GenerateProdCardButton = (props) => {

  const {
    startDate,
    endDate,
    status,
    selectedOrders
  } = props.value

  const startDateFormat = moment(startDate).format('DD/MM/YYYY')
  const endDateFormat = moment(endDate).format('DD/MM/YYYY')

  let statusFormat = [];

  if(status == 'all_valid'){
    let code = ['waiting', 'confirmed', 'billable'];
    code.forEach(element => {
      statusFormat += '&filter[orders_status][value][]='+element+'';
    });
  }else{
    statusFormat += '&filter[orders_status][value][]='+status+'';
  }

  const selectedOrdersString = encodeURIComponent(JSON.stringify(selectedOrders));
  const prmMultipole = '?startDate='+moment(startDate).format('YYYY-MM-DD')+'&endDate='+moment(endDate).format('YYYY-MM-DD')+''+statusFormat+'&selectedOrders='+selectedOrdersString;

  return (
    <div>
    { startDate && endDate ?
      <Dropdown drop={'down'} className={css(style.center)}>
        <Dropdown.Toggle variant="success" className={css(style.buttonCard)}>
          Créer fiche du <br/> {startDateFormat} au {endDateFormat}<br/> pour
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <p className='ml-2 mb-0' style={{ color: '#4ddb8f' }}><small>RÉCAP<br/>{selectedOrders.length > 0 ? <i>Par prestation selectionnée</i> : "Toutes prestations" }</small></p>
          <Dropdown.Item href={'/fiche-production-multipole-v2'+prmMultipole+'&activity=all'} target="_blank">
              Multipôle
          </Dropdown.Item>
          <Dropdown.Item href={'/fiche-production-multipole-v2'+prmMultipole+'&activity=v2'} target="_blank">
              Plateaux-repas
          </Dropdown.Item>
          <Dropdown.Item href={'/fiche-production-multipole-v2'+prmMultipole+'&activity=caterer'} target="_blank">
              Traiteur
          </Dropdown.Item>
          <Dropdown.Item href={'/fiche-production-multipole-v2'+prmMultipole+'&activity=breakfast'} target="_blank">
              Petit-déjeuner
          </Dropdown.Item>
          <hr/>
          <p className='ml-2 mb-0' style={{ color: '#4dacdb' }}><small>PROD<br/>Toutes prestations</small></p>
          <Dropdown.Item href={`${process.env.REACT_APP_API_URL}/e28XmZh3/pro/v2/devis-productions${prmMultipole}&activity=all`}  target="_blank">
              Multipôle
          </Dropdown.Item>
          <Dropdown.Item href={`${process.env.REACT_APP_API_URL}/e28XmZh3/pro/v2/devis-productions${prmMultipole}&activity=v2`}  target="_blank">
              Plateaux-repas
          </Dropdown.Item>
          <Dropdown.Item href={`${process.env.REACT_APP_API_URL}/e28XmZh3/pro/v2/devis-productions${prmMultipole}&activity=caterer`} target="_blank">
            Traiteur
          </Dropdown.Item>
          <Dropdown.Item href={`${process.env.REACT_APP_API_URL}/e28XmZh3/pro/v2/devis-productions${prmMultipole}&activity=breakfast`}  target="_blank">
            Petit-déjeuner
          </Dropdown.Item>
          <hr/>
          <p className='ml-2 mb-0' style={{ color: '#ccc' }}><small>Toutes prestations</small></p>
          <Dropdown.Item href={'/anticipation'+prmMultipole+'&activity=all'} target="_blank">
              Anticipation
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      :
      <p className={css(style.msgGenerate)}>Pour générer des fiches de production merci de sélectionner vos dates ci-dessus.</p>
    }
    </div>
  );
};

const style = {
  msgGenerate:{
    textAlign: 'center',
    border: 'none',
    fontSize: '0.9rem',
    margin: 'auto',
    marginTop: '4.0rem',
    padding: '0.5rem',
    width: '80%',
    color: 'grey !important',
    borderRadius: '0',
    marginBottom: '2.0rem',

  },
  buttonCard:{
    backgroundColor: '#dedede !important',
    border: 'none',
    fontSize: '0.9rem',
    marginTop: '1.0rem',
    padding: '0.5rem',
    width: '80%',
    color: 'black !important',
    borderRadius: '0',
    marginBottom: '2.0rem',
    '&:hover': {
      backgroundColor:'#222d32 !important',
      color: 'white !important',
    }
  },
  center:{
    textAlign: 'center'
  },

}

export default GenerateProdCardButton
