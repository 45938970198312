import React, { useState } from "react";
import { useTable } from "react-table";
import './styles.css';

const AnticipationBoisson = (props) => {
  // props
  const { ordersInfo, tags } = props;

  const [showCafeProductName, setShowCafeProductName] = useState(false);
  // Permet de savoir si la colonne d'un tag doit afficher ou non les produits dans ses cellules
  const showProductNameByTag = {};

  // Les colonnes qui doivent avoir leur ligne droite en gras
  const boldVerticalSeparatorColumns = ["Presta", "Thé"];

  // Trie les tags par sort sachant qu'un sort inexistant ou null est dernier
  tags.sort((a, b) => {
    if (a.sort === null) return 1;
    if (b.sort === null) return -1;
    return a.sort - b.sort;
  });

  let orders = Object.values(ordersInfo)
    .flatMap((info) =>
      Object.values(info.orders).map((order) => ({
        ...order,
      }))
    )
    .flat();

  // Ajoutez une nouvelle clé "Autres" à chaque objet de commande, seulement pour les commandes petits déjeuners et non tagué
  orders.forEach((order) => {
    order.products.forEach((product) => {
      if (!tags.some((tag) => tag.name === product.tagName) && order.orderType === "breakfast") {
        if (!order["Autres"]) order["Autres"] = [];
        if (product.tagName) return;
        order["Autres"].push(
          `<span>${product.productName}</span> - ${product.quantity} ${
            product.unit ? ` ${product.unit}` : ""
          }`
        );
      } 
      else if (tags.some((tag) => tag.name === product.tagName)) {
        if (!order[product.tagName]) order[product.tagName] = [];

        // Pour les colonnes "Café" et "Thé", nous ne prenons pas en compte le type de commande "breakfast"
        if (product.tagName === "Café" || product.tagName === "Thé") {
          order[product.tagName].push(
            `<span>${product.productName}</span> - ${product.quantity} ${
              product.unit ? ` ${product.unit}` : ""
            }`
          );
        }
        // Pour les autres colonnes, nous ne prenons en compte que le type de commande "breakfast"
        else if (order.orderType === "breakfast") {
          order[product.tagName].push(
            `<span>${product.productName}</span> - ${product.quantity} ${
              product.unit ? ` ${product.unit}` : ""
            }`
          );
        }
      }
    });

    if (order["Autres"]) order["Autres"] = order["Autres"].join(" || ");
    tags.forEach((tag) => {
      if (order[tag.name]) order[tag.name] = order[tag.name].join(" || ");
    });
  });

  let totals = ["Total"].map(
    (total) => {
      let filteredOrders;
      filteredOrders = orders;
  
      let products = [];
      filteredOrders.forEach((order) => {
        order.products.forEach((product) => {
          if (order.orderType === "breakfast" || product.tagName === 'Café' || product.tagName === 'Thé') {
            products.push(product);
          }
        });
      });
  
      // Total pour toutes les colonnes pour la ligne "Total"
      // et seulement pour les colonnes "Café" et "Thé" pour les autres lignes
      return {
        roadmapStartHour: total,
        orderNumber: "",
        ...tags.reduce((acc, tag) => {
          // Pour les colonnes "Café" et "Thé", nous ne prenons pas en compte le type de commande "breakfast"
          // Pour les autres colonnes, nous ne prenons en compte que le type de commande "breakfast"
          const filteredProducts = products.filter((product) => {
            return product.tagName === tag.name;
          });
  
          const groupedProducts = filteredProducts.reduce((grouped, product) => {
            const key = `${product.productName} - ${
              product.unit ? ` ${product.unit}` : ""
            }`;
            if (!grouped[key]) {
              grouped[key] = {
                quantity: 0,
                unit: product.unit,
                productName: product.productName,
              };
            }
            grouped[key].quantity += product.quantity;
            return grouped;
          }, {});

          const productStrings = Object.values(groupedProducts).map(
            (product) => `${product.productName} - ${product.quantity} ${product.unit ? ` ${product.unit}` : ""}`
          );
          if (productStrings.length > 1) {
            showProductNameByTag[tag.name] = true;
          }
  
          acc[tag.name] = Object.values(groupedProducts)
            .map(
              (product) =>
                `${product.productName} - ${product.quantity} ${
                  product.unit ? ` ${product.unit}` : ""
                }`
            )
            .join(" || ");
          return acc;
        }, {}),
      };
    }
  );

  // Ajoutez les totaux aux commandes
  orders = orders.concat(totals);
  const columns = React.useMemo(
    () => [
      {
        Header: "H départ",
        accessor: "roadmapStartHour",
      },
      {
        Header: "Presta",
        accessor: "orderNumber",
        Cell: ({ cell: { value }, column: { id } }) => {
          var cellValue = `<span style="font-weight:bold; font-size:1.1em; color: blue;">${value}</span>`;
          return <div dangerouslySetInnerHTML={{ __html: cellValue }} />;
        },
      },
      ...tags.map((tag) => ({
        Header: tag.name,
        accessor: (row) => {
          if (row[tag.name]) {
            const productCount = row[tag.name].split(' || ').length;
            if (tag.name === "Café") {
              return row[tag.name];
            }

            if (productCount === 1 && (showProductNameByTag[tag.name] === undefined || showProductNameByTag[tag.name] !== true)) {
              const lastDashIndex = row[tag.name].lastIndexOf(' - ');
              const result = lastDashIndex !== -1 ? row[tag.name].substring(lastDashIndex + 3) : row[tag.name];
              return result;
            } else {
              return row[tag.name];
            }
          }
          return "";
        },
        Cell: ({ cell: { value }, column: { id } }) => {
          if (id === 'Café' && !showCafeProductName) {
            const quantity = value.split(' || ').reduce((total, product) => {
              const quantity = product.split(' - ').pop();
              const quantityNumber = parseInt(quantity, 10);
              return isNaN(quantityNumber) ? total : total + quantityNumber;
            }, 0);
            return quantity;
          }
          var cellValue = value;
          return <div dangerouslySetInnerHTML={{ __html: cellValue }} />;
        },
      })),
    ],
    [tags, showCafeProductName]
  );

  columns.push({
    Header: "Autres",
    accessor: "Autres",
    Cell: ({ cell: { value }, column: { id } }) => {
      var cellValue = value;
      return <div dangerouslySetInnerHTML={{ __html: cellValue }} />;
    },
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: orders });

  return (
    <div>
      <div className="no-print">
        <input
          type="checkbox"
          checked={showCafeProductName}
          onChange={(e) => setShowCafeProductName(e.target.checked)}
        />
        <label>Afficher le nom des produits dans la colonne Café</label>
      </div>
      <table
        {...getTableProps()}
        style={{ width: "100%" }}
      >
        <thead>
          <tr>
            <th colSpan={headerGroups[0].headers.length} style={{ padding: "10px", textAlign: "center" }}>
              Tableau des boissons
            </th>
          </tr>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th
                  {...column.getHeaderProps()}
                  className={boldVerticalSeparatorColumns.includes(column.Header) ? 'border-right-bold' : ''}
                  style={{ border: "solid 1px black", padding: "10px", width: i === 0 ? '10%' : undefined }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                style={
                  row.original.roadmapStartHour === "Total"
                    ? { fontWeight: "bold", backgroundColor: "#f2f2f2" }
                    : {}
                }
              >
              {row.cells.map((cell, i) => (
                <td
                  {...cell.getCellProps()}
                  className={boldVerticalSeparatorColumns.includes(cell.column.Header) ? 'border-right-bold' : ''}
                  style={{
                    border: "solid 1px black",
                    padding: "10px", width: i === 0 ? '10%' : undefined         
                  }}
                >
                  {cell.render("Cell")}
                </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AnticipationBoisson;
