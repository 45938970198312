import React, {useState, useEffect}  from 'react'
import {Container} from 'react-bootstrap'
import RoadMaps from 'app/deliveryTool/pages/publicPlanning/RoadMaps'
import Layout from 'app/universal/layout/Layout'
import LeMoulinApi from 'app/universal/utils/LeMoulinApi'

const PublicPlanning = (props) => {

  const [roadmaps, setRoadmaps]   = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [init, setInit]           = useState(false);
  const [message, setMessage]     = useState(null);

  const publicUrl   = true;
  const queryString = require('query-string')
  const query       = queryString.parse(window.location.search);

  useEffect(() => {
    setIsLoading(true);
    LeMoulinApi.request.deliveryMan.roadmaps.getByToken(query.public_token).then((result) => {
      setInit(true);
      if (result.success && result.data && result.data.roadmaps) {
        setRoadmaps(result.data.roadmaps);
        setIsLoading(false);
      }
      else {
        setIsLoading(false);
        setRoadmaps([]);
        if (result.message) {
          setMessage(result.message);
        }
      }
    });
  }, [query.public_token]);

  return (
      <Layout public={publicUrl}>
        <Container fluid={true} >
          {!init ? 
            <p>Récupération des livraisons en cours...</p>
          : roadmaps.length === 0 ? 
            <p>Aucune livraison {message && message}</p>
          :
            <RoadMaps roadmaps={roadmaps} loading={isLoading}/>
          }
        </Container>
      </Layout>
  )

}

const styles = {
  filterBlock:{
    'borderBottom': '1px #ddd solid',
    'padding': '10px 10px 10px 10px',
    "@media (max-width: 767px)": {
      'textAlign': 'center'
    }
  },
  action: {
    'borderBottom': '1px #ddd solid',
    'padding': '10px 10px 10px 10px',
    'textAlign': 'right',
    "@media (max-width: 767px)": {
      'textAlign': 'center'
    }
  },
  print: {
    display:'inline-block',
    border: 'thin solid #bbb',
    color: '#555',
    cursor: 'pointer',
    padding: '0.2rem 1rem',
    transition: '300ms',
    ':hover': {
      textDecoration: 'none',
      color: 'white',
      backgroundColor: '#bbb',
      transition: '300ms'
    }
  }

}

export default PublicPlanning
