import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TimePicker from 'rc-time-picker';
import moment from 'moment'
import 'rc-time-picker/assets/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const Times = (props) => {

  const {
    value,
    handleChange,
    isLoading
  } = props

  const [data, setData] = useState(() => {return (value !== undefined && value !== null ) ? moment(value) : null})
  const onChange = (val) => {
    setData(val)
    if (val === null) {
      handleChange(val)
    }
  }

  return (
    <>
      <TimePicker defaultValue={(value !== undefined && value !== null ) ? moment(value) : null} value={data} showSecond={false}
      onChange={onChange}
      onClose={(open) => {
        const r = handleChange(moment(data).format())
        r.then((resp) => {
          setData(moment(resp))
        })
      }}
      />
      {isLoading && <FontAwesomeIcon icon={faSpinner} pulse/>}
    </>
  )

}

Times.propTypes = {
  value       : PropTypes.string,
  handleChange: PropTypes.func
}

export default Times
