import React from 'react'
import { Link } from 'react-router-dom'
import {Container, Tooltip, ButtonToolbar, OverlayTrigger} from 'react-bootstrap'
import { css } from 'emotion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListAlt, faFolderOpen, faMapMarkedAlt, faArrowCircleLeft, faSync, faCalendar, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { useWindowDimensions } from 'app/universal/utils/WindowDimensionsProvider'

import logo from 'app/universal/assets/images/logo-moulin.png'


const url = `${process.env.REACT_APP_API_URL}/e28XmZh3/`;

const DesktopMenu = (props) => {

    const { width } = useWindowDimensions();

    return (
      <Container fluid={true} className={css(style.header)}>
        <nav>
          <li className={css(style.menuLogo)}>
            <img src={logo} alt='Logo Moulin' />
          </li>
          <ul className={css(style.desktopMenu)}>
            {width >= 890 &&
              <span>
                <li className={css(style.menuItem)}><Link id="managerOrder" to={{
                    pathname: `/`,
                    search: window.location.search
                  }}><FontAwesomeIcon icon={faFolderOpen} /> Devis/Facture</Link></li>
                <li className={css(style.menuItem)}><Link id="missing-information" to={{
                    pathname: `/informations-devis-manquantes`,
                    search: window.location.search
                  }}><FontAwesomeIcon icon={faExclamationTriangle} /> Info manquante</Link></li>
                <li className={css(style.menuItem)}><Link id="planning" to={{
                    pathname: `/planning`,
                    search: window.location.search
                  }}><FontAwesomeIcon icon={faListAlt} /> Planning</Link></li>
                <li className={css(style.menuItem)}><Link id="roadmap" to={{
                    pathname: `/itineraires`,
                    search: window.location.search
                  }}><FontAwesomeIcon icon={faMapMarkedAlt} /> Tournée</Link></li>
                <li className={css(style.menuItem)}><Link id="calendar" to={{
                    pathname: `/agenda`
                  }}><FontAwesomeIcon icon={faCalendar} /> Agenda</Link></li>
                <li className={css(style.menuItem, style.menuRight)}>
                  <ButtonToolbar>
                      <OverlayTrigger
                        key='bottom'
                        placement='bottom'
                        overlay={
                          <Tooltip id={`tooltip-bottom`}>
                            Permet de synchroniser les infos de l'interface si des modifications ont été faites sur le BO du Moulin en parrallèle.
                          </Tooltip>
                        }
                      >
                        <div className={css(style.syncro)} onClick={ () => window.location.reload()}><FontAwesomeIcon icon={faSync} /> Synchroniser</div>
                      </OverlayTrigger>
                  </ButtonToolbar>

                </li>
                <li className={css(style.menuItem, style.menuRight)}><a id="backBo" href={url}><FontAwesomeIcon icon={faArrowCircleLeft} /> Retour BO</a></li>
              </span>
            }

          </ul>
        </nav>
      </Container>
    )
}

const style = {
  header: {
    background: 'linear-gradient(130deg, #429853, #90a345);',
  },
  'desktopMenu': {
    listStyleType: 'none',
    margin: '0',
    padding: '0',
    overflow: 'hidden',
    height: '52px'
  },
  syncro: {
    cursor: 'pointer',
    display: 'block',
    color: 'white',
    textAlign: 'center',
    padding: '14px 16px',
    WebkitTextDecoration: 'none',
    textDecoration: 'none',
  },
  menuLogo:{
    float: 'left',
    display: 'block',
    color: 'white',
    textAlign: 'center',
    padding: '3px 16px 0px 0',
    textDecoration: 'none',
    transition: '300ms',
    cursor: 'pointer',
    ':hover': {
      opacity: '0.5'
    }
  },
  menuItem: {
    float: 'left',
    borderLeft: '1px solid rgba(255, 255, 255, 0.2)',
    a: {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      padding: '14px 16px',
      textDecoration: 'none',
      ':hover': {
        opacity: '0.5'
      }
    }
  },
  'active': {
    'color' : 'black'
  },
  'menuRight':{
    'float': 'right',
    'border': 'none'
  },

}

export default DesktopMenu
