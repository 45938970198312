import React from 'react'
import { css } from 'emotion'

const Checkbox = (props) => {

  const {
    label,
    id,
    name,
    onChange,
    onBlur,
    value
  } = props


  const style = {
    checkbox:{
      border: 'none',
      borderBottom: '2px solid #eaeae9',
      padding: '0.5rem',
      width: '100%',
      ':focus':{
        outline : '0'
      },
    },
    label:{
      fontSize: '1.0rem !important'
    }
  }

  return (
    <div className="fancy">
      <p className={css(style.checkbox)}>
        <label>
          <input
            type="checkbox"
            id={id}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            defaultChecked={value}
          />
          <span className={css(style.label)}>{label}</span>
        </label>
      </p>
    </div>
  )

}

export default Checkbox
