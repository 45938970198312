import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOrderEditionInfo } from "state/orderEdition/actions";
import { Form } from "react-bootstrap";

const DiversOrderInfoComponent = () => {
  const dispatch = useDispatch();
  const orderEdition = useSelector((state) => state.orderEdition);

  const [customerQuotationCommentary, setCustomerQuotationCommentary] = useState(
    orderEdition?.customerQuotationCommentary || ""
  );
  const [customerSpecialReference, setCustomerSpecialReference] = useState(
    orderEdition?.customerSpecialReference || ""
  );
  const [codeServiceChorus, setCodeServiceChorus] = useState(
    orderEdition?.codeServiceChorus || ""
  );
  const [orderFormDate, setOrderFormDate] = useState(
    orderEdition?.orderFormDate || ""
  );
  const [disableApproachingMail, setDisableApproachingMail] = useState(
    orderEdition?.disableApproachingMail || false
  );

  useEffect(() => {
    if (
      !orderEdition ||
      customerQuotationCommentary !== orderEdition.customerQuotationCommentary ||
      customerSpecialReference !== orderEdition.customerSpecialReference ||
      codeServiceChorus !== orderEdition.codeServiceChorus ||
      orderFormDate !== orderEdition.orderFormDate ||
      disableApproachingMail !== orderEdition.disableApproachingMail
    )
      dispatch(
        setOrderEditionInfo({
          ...orderEdition,
          customerQuotationCommentary: customerQuotationCommentary,
          customerSpecialReference: customerSpecialReference,
          codeServiceChorus: codeServiceChorus,
          orderFormDate: orderFormDate,
          disableApproachingMail: disableApproachingMail,
        })
      );
  }, [
    dispatch,
    orderEdition,
    customerQuotationCommentary,
    customerSpecialReference,
    codeServiceChorus,
    orderFormDate,
    disableApproachingMail,
  ]);

  return (
    <Form>
      <Form.Group controlId="customerQuotationCommentary">
        <Form.Label>Commentaire visible devis factures</Form.Label>
        <Form.Control
          type="text"
          value={customerQuotationCommentary}
          onChange={(e) => setCustomerQuotationCommentary(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="formCustomerSpecialReference">
        <Form.Label>Réf client</Form.Label>
        <Form.Control
          type="text"
          value={customerSpecialReference}
          onChange={(e) => setCustomerSpecialReference(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="formCodeServiceChorus">
        <Form.Label>Code Chorus</Form.Label>
        <Form.Control
          type="text"
          value={codeServiceChorus}
          onChange={(e) => setCodeServiceChorus(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="formOrderFormDate">
        <Form.Label>Date de réception du bon de commande</Form.Label>
        <Form.Control
          type="date"
          value={orderFormDate}
          onChange={(e) => setOrderFormDate(e.target.value)}
        />
      </Form.Group>

      <Form.Group
        controlId="formDisableApproachingMail"
        className="d-flex align-items-center"
      >
        <Form.Check
          type="checkbox"
          checked={disableApproachingMail}
          onChange={(e) => setDisableApproachingMail(e.target.checked)}
        />
         <Form.Label className="mb-0 mr-2">Désactiver la relance email (presta en approche, hors impayé)</Form.Label>
      </Form.Group>
    </Form>
  );
};

export default DiversOrderInfoComponent;
