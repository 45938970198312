import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autosuggest from "react-autosuggest";
import { setTender, setConfirmTender } from "state/orderEdition/actions";

const TenderSelectComponent = () => {
  const dispatch = useDispatch();
  const tenders = useSelector((state) =>
    state.adminData.tenders ? state.adminData.tenders : []
  );
  const selectedTender = useSelector((state) => state.orderEdition.tender);
  const confirmTender = useSelector((state) => state.orderEdition.confirmTender);

  const [value, setValue] = useState(selectedTender?.name || "");
  const [suggestions, setSuggestions] = useState([]);

  const handleTenderSelect = (tender) => {
    dispatch(setTender(tender));
  };

  const handleConfirmTender = (event) => {
    dispatch(setConfirmTender(event.target.checked));
  };

  const handleClearSelection = () => {
    dispatch(setTender(null));
    dispatch(setConfirmTender(false));
    setValue("");
  };

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : tenders.filter((tender) =>
          `${tender.name}`.toLowerCase().includes(inputValue)
        );
  };

  const getSuggestionValue = (suggestion) => `${suggestion.name}`;

  const renderSuggestion = (suggestion) => <div>{`${suggestion.name}`}</div>;

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: "Recherche de l'AO",
    value,
    onChange: (event, { newValue }) => {
      setValue(newValue);
    },
  };

  const theme = {
    input: {
      display: "block",
      width: "100%",
      height: "calc(1.5em + .75rem + 2px)",
      padding: ".375rem .75rem",
      fontSize: "1rem",
      fontWeight: "400",
      lineHeight: "1.5",
      color: "#495057",
      backgroundColor: "#fff",
      backgroundClip: "padding-box",
      border: "1px solid #ced4da",
      borderRadius: ".25rem",
      transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
    },
  };

  return (
    <>
      <label>Sélection de l'AO {value && (
       <a href="javascript:void(0)" onClick={handleClearSelection}>- Retirer</a>
      )}</label>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={(event, { suggestion }) =>
          handleTenderSelect(suggestion)
        }
        theme={theme}
      />
      <br />
      <label htmlFor="confirmTender">
      <input type="checkbox" id="confirmTender" name="confirmTender" required onChange={handleConfirmTender} checked={confirmTender} /> Je confirme mon AO
      </label>
    </>
  );
};

export default TenderSelectComponent;
