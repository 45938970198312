import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'emotion'

import { useFilters } from 'app/deliveryTool/context/FiltersProvider'
import { useRoadmaps } from 'app/deliveryTool/context/RoadmapsProvider'

import DateFilter from 'app/deliveryTool/components/filters/Date'
import DeliveryManFilter from 'app/deliveryTool/components/filters/DeliveryMan'
import OrderTypeFilter from 'app/deliveryTool/components/filters/OrderType'
import DeliveryTypeFilter from 'app/deliveryTool/components/filters/DeliveryType'

const Filters = (props) => {

  const {
    disableFilters
  } = props

  const filters = useFilters();
  const rmData = useRoadmaps();

  const handleDateChange = (date) => {
    filters.setDate(date);
  }

  const handleDeliveryManChange = (event) => {
    filters.setDeliveryMan(event.target.value)
  }

  const handleOrderTypeChange = (event) => {
    filters.setOrderType(event.target.value)
  }

  const handleDeliveryTypeChange = (event) => {
    filters.setDeliveryType(event.target.value)
  }

  return (
      <div>
        <div className={css(style.input)}>
          {disableFilters.date === undefined && <DateFilter date={filters.date} handleChange={handleDateChange}/> }
        </div>
        <div className={css(style.input)}>
          {disableFilters.deliveryMan === undefined && <DeliveryManFilter deliveryMan={filters.deliveryMan} deliveryMen={rmData.deliveryMen} handleChange={handleDeliveryManChange}/> }
        </div>
        <div className={css(style.input)}>
          {disableFilters.orderType === undefined && <OrderTypeFilter orderType={filters.orderType} handleChange={handleOrderTypeChange}/> }
        </div>
        <div className={css(style.input)}>
          {disableFilters.deliveryType === undefined && <DeliveryTypeFilter deliveryType={filters.deliveryType} handleChange={handleDeliveryTypeChange}/> }
        </div>
      </div>
  )

}

const style = {
  filterBlock:{
    'borderBottom': '1px #ddd solid',
    'padding': '10px 60px 10px 10px'
  },
  input:{
    display: 'inline-block',
    margin: '0rem 0.5rem',
  },

}

Filters.propTypes = {
  disableFilters: PropTypes.object
}
Filters.defaultProps = {
  disableFilters: {}
}

export default Filters
