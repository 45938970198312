import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autosuggest from "react-autosuggest";
import { setCompany } from "state/orderEdition/actions";
import CompanySchema from "schema/CompanySchema";

const CompanySelectComponent = () => {
  const dispatch = useDispatch();
  const companies = useSelector((state) =>
    state.adminData.companies ? state.adminData.companies : []
  );
  const selectedCompany = useSelector((state) => state.orderEdition.company);
  const [value, setValue] = useState(selectedCompany?.name || "");
  const [suggestions, setSuggestions] = useState([]);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    setValue(selectedCompany?.name || "");
  }, [selectedCompany]);

  const handleCompanySelect = (company) => {
    dispatch(setCompany(company));
  };

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : companies.filter((company) =>
          `${company.name}`.toLowerCase().includes(inputValue)
        );
  };

  const getSuggestionValue = (suggestion) => `${suggestion.name}`;

  const renderSuggestion = (suggestion) => <div>{`${suggestion.name}`}</div>;

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: "Recherche de l'entreprise...",
    value,
    onChange: (event, { newValue }) => {
      setValue(newValue);
    },
  };

  const theme = {
    input: {
      display: "block",
      width: "100%",
      height: "calc(1.5em + .75rem + 2px)",
      padding: ".375rem .75rem",
      fontSize: "1rem",
      fontWeight: "400",
      lineHeight: "1.5",
      color: "#495057",
      backgroundColor: "#fff",
      backgroundClip: "padding-box",
      border: "1px solid #ced4da",
      borderRadius: ".25rem",
      transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
    },
  };

  return (
    <>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={async (event, { suggestion }) => {
          try {
            // Valider la suggestion avec le schéma de l'entreprise
            await CompanySchema.validate(suggestion);
            // Si la validation réussit, passez la suggestion à la fonction onSelect
            setIsValid(true);
            handleCompanySelect(suggestion);
          } catch (error) {
            // Si la validation échoue, affichez une alerte et n'appellez pas onSelect
            setIsValid(false);
          }
        }}
        theme={theme}
      />
      {!isValid && <p>La sélection de l'entreprise est invalide. </p>}
    </>
  );
};

export default CompanySelectComponent;
