import React from 'react'
import { css, keyframes } from 'emotion'
import {Container, Col, Row} from 'react-bootstrap'

const FakeDelivery = (props) => {

  const styles = {
      col:{
        padding: '0.5rem',
        borderRight: '1px #dedede solid',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      },
      statut: {
        backgroundColor: '#dedede',
        height: '11px',
        width: '60px',
        maxWidth: '60px'
      },
      para:{
        marginTop: '0.4rem',
        marginBottom: '0rem',
        backgroundColor: '#dedede',
        height: '13px',
        width: '185px',
        maxWidth: '185px'
      },
      para2:{
        marginTop: '0.4rem',
        marginBottom: '0rem',
        backgroundColor: '#dedede',
        height: '13px',
        width: '290px',
        maxWidth: '290px'
      },
      para3:{
        marginTop: '0.4rem',
        marginBottom: '0rem',
        backgroundColor: '#dedede',
        height: '13px',
        width: '300px',
        maxWidth: '300px'
      },
      para4:{
        marginTop: '0.0rem',
        marginBottom: '0rem',
        backgroundColor: '#dedede',
        height: '13px',
        width: '100px',
        maxWidth: '100px'
      },
      verticalAlign:{
        display: 'flex',
        flexDirection: 'row',
      },
      center:{
        textAlign: 'center'
      },
      noImg: {
        backgroundColor: '#dedede',
        textAlign:'center',
        color: '#ccc',
        wordBreak: 'break-word'
      },
      img: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        minHeight: '100px',
        textAlign:'center',
      },
      fake:{
        display: 'none'
      }

    }
    const flash = keyframes`
    from, 50%, to {
      opacity: 1;
    }
    25%, 75% {
      opacity: 0.4;
    }
    `
    const randomDeliveriesFake = [1, 2, 3];

    function getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
    }

    return (
      <div>
        {randomDeliveriesFake.map((randomDeliveryFake, i) =>
          <Container className={css`
              background: white;
              margin: 10px;
              transition: 300ms;
              animation: ${flash} ${getRandomInt(2,5)}s ease infinite;
              transform-origin: center bottom;
            `} key={i} fluid>
            <Row className={css(styles.verticalAlign)}>
              <Col xs={12} md={2} className={css(styles.col, styles.noImg)}>Chargement ...</Col>
              <Col xs={12} md={10} className={css(styles.col)}>
                <h3 className={css(styles.statut)}><span className={css(styles.fake)}>fake</span></h3>
                <p className={css(styles.para)}></p>
                <p className={css(styles.para2)}></p>
                <p className={css(styles.para3)}></p>
                <br/>
                <p className={css(styles.para4)}></p>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    )
}

export default FakeDelivery
