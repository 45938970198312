import React from 'react'

const Translate = (props) => {

  const {
    item
  } = props


  function translateType() {
    if(item === 'breakfast'){
      return 'Petit-déjeuner'
    }
    else if(item === 'mealtray'){
      return 'Ancien PR 2021'
    }
    else if(item === 'v2'){
      return 'Plateaux-repas'
    }
    else if(item === 'caterer'){
      return 'Traiteur'
    }
    else if(item === '100'){
      return 'Livraison non attribuée'
    }
    else if(item === '110'){
      return 'Livraison attribuée'
    }
    else if(item === '101'){
      return 'Récupération attribuée'
    }
    else if(item === '310'){
      return 'Livraison non faite alors que la date est passée'
    }
    else if(item === '321'){
      return 'Livraison faite mais pas récupérée'
    }
    return 'Problème de trad'
  }

  return (
    <span>
      {translateType()}
    </span>
  )

}

export default Translate
