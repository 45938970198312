import React, { createContext, useContext, useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import LeMoulinApi from 'app/universal/utils/LeMoulinApi'
import { initDate, initValue, initNumber, initBoolean, rangeDate, statusCode, initSelectedOrders } from 'app/orderTool/context/Utils'

const OrdersContext = createContext(null)
const OrdersProvider = (props) => {

  let history = useHistory();

  const [orders, setOrders]                 = useState([]);
  const [countOrders, setCountOrders]       = useState(0);
  const [selectedOrders, setSelectedOrders] = useState(initSelectedOrders());
  const params                              = props.params;

  const defaultFilters = {
    search: initValue('search'),
    startDate: initDate('startDate'),
    endDate: initDate('endDate'),
    type: initValue('type', 'quotation'),
    status: initValue('status', 'all_valid'),
    startDatePaid: undefined,
    endDatePaid: undefined,
    chorus: initBoolean('chorus'),
    limit: 10,
    offset: initNumber('offset', 0),
    orderBy: initValue('orderBy', 'ASC'),
    tender_id: undefined,
    is_cancel: initBoolean('is_cancel'),
    to_upload_on_chorus: initBoolean('to_upload_on_chorus'),
    exclude_tender_order: initBoolean('exclude_tender_order'),
    selectedOrders: selectedOrders,
  };

  const [dataParams, setDataParams] = useState((params) ? params : undefined);
  const [dataFilter, setDataFilter] = useState(defaultFilters);

  const [loading, setLoading] = useState(false);

  const fetchData = async () => {

    let filters;
    filters = {
      search: dataFilter.search,
      type: dataFilter.type,
      format: dataFilter.format,
      limit: dataFilter.limit,
      offset: dataFilter.offset,
      order_by: {delivery_date : dataFilter.orderBy},
      selectedOrders: dataFilter.selectedOrders
    }
    filters.filters = {
      delivery_date: rangeDate(dataFilter.startDate,dataFilter.endDate),
      paid_date: rangeDate(dataFilter.startDatePaid,dataFilter.endDatePaid),
      total_price: dataFilter.price,
      status: statusCode(dataFilter.status),
      tender_id: dataFilter.tender_id
    }
    if (dataFilter.chorus === true) {
      filters.chorus = 1;
    }
    
    if (dataFilter.exclude_tender_order === true) {
      filters.exclude_tender_order = 1;
    }
    if (dataFilter.is_cancel === true) {
      filters.is_cancel = 1;
    }
    if (dataFilter.to_upload_on_chorus === true) {
      filters.to_upload_on_chorus = 1;
    }

    const ordersResult = (dataParams) ? await LeMoulinApi.request.order.gets(dataParams) : await LeMoulinApi.request.order.gets(filters);

    setOrders(ordersResult.orders)
    setCountOrders(ordersResult.total)
    setLoading(false);

  }
  
  // A chaque fois qu'un filtre est mis à jour on déclence une requete
  useEffect(() => {
    setLoading(true);
    fetchData();
    if (dataParams === undefined) {
      let filtersInUrl = {...dataFilter}
      if (filtersInUrl.selectedOrders) {
        try {
          filtersInUrl.selectedOrders = JSON.parse(filtersInUrl.selectedOrders);
        } catch (error) {
          filtersInUrl.selectedOrders = [];
        }
      }
      // Permet de nettoyer les filtres undefined pour ne pas les envoyer en parametre
      Object.keys(filtersInUrl).forEach((key, f) => {
        
        if (filtersInUrl[key] === undefined) {
          delete filtersInUrl[key];
        }
        if (filtersInUrl[key] === null) {
          delete filtersInUrl[key];
        }
        if (filtersInUrl[key] === '') {
          delete filtersInUrl[key];
        }
      });
      

      var prms = new URLSearchParams(filtersInUrl);

      history.push('?'+prms);
    }
    else {
      history.push('?');
    }

  }, [dataFilter, history]);

  const load = (filters) => {
    fetchData();
  }
  
  /**
   * Appel la ressource qui notifie le client des champs manquants puis met à jour les commandes avec la commande mise à jour pour sa notification
   */
  const sendMissingFieldsCustomerNotification = async (orderId, orderType) => {
    let refreshOrders  = await LeMoulinApi.request.order.missingFieldsCustomerNotification(orderId, orderType).then((response) => {
      let refreshOrders = [];
      if (response.order) {
        let newOrder = response.order;
        let refreshOrdersBis = orders.map((o) => {
          if (o.id === newOrder.id) {
            return newOrder;
          }
          return o
        });
        return refreshOrdersBis;
      }
    });
    setOrders(refreshOrders);
  }

  const data = {
    loading                              : loading,
    setLoading                           : (loading) => {setLoading(loading)},
    orders                               : orders,
    setOrders                            : (orders) => {setOrders(orders)},
    countOrders                          : countOrders,
    setCountOrders                       : (countOrders) => {setCountOrders(countOrders)},
    dataFilter                           : dataFilter,
    setDataFilter                        : (dataFilter) => {setDataFilter(dataFilter)},
    load                                 : (filters) => {load(filters)},
    sendMissingFieldsCustomerNotification: (orderId, orderType) => {sendMissingFieldsCustomerNotification(orderId, orderType)},
    selectedOrders                       : selectedOrders,
    setSelectedOrders                    : setSelectedOrders,
  }

  return (
    <OrdersContext.Provider value={data}>
      {props.children}
    </OrdersContext.Provider>
  )
}

export default OrdersProvider
export const useOrders = () => useContext(OrdersContext)
