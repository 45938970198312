import React  from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import { css } from 'emotion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'

import Providers from 'app/deliveryTool/context/Providers'
import RoadMaps from 'app/deliveryTool/pages/planning/RoadMaps'
import Filters from 'app/deliveryTool/components/filters/Filters'
import Layout from 'app/universal/layout/Layout'


const Planning = (props) => {

  const disableFilters = {orderType:1, deliveryType:1};

  const urlPrint = () => {
    props.history.push({
      pathname: 'impression-planning',
      search: window.location.search,
    });
  }

  return (
    <Providers>
      <Layout>
        <Container fluid={true} >
          <Row>
            <Col xs={12} sm={8}  className={css(styles.filterBlock)}>
              <Filters disableFilters={disableFilters} />
            </Col>
            <Col xs={12} sm={4} className={css(styles.action)}>
              <div className={css(styles.print)} onClick={ () => {urlPrint()}}><FontAwesomeIcon icon={faPrint} /> Outil d'impression</div>
            </Col>
          </Row>
          <RoadMaps router={props.history}/>
        </Container>
      </Layout>
    </Providers>
  )

}


const styles = {
  filterBlock:{
    '@print':{
      'display': 'none'
    },
    'borderBottom': '1px #ddd solid',
    'padding': '10px 10px 10px 10px'
  },
  action: {
    'borderBottom': '1px #ddd solid',
    'padding': '10px 10px 10px 10px',
    'textAlign': 'right'
  },
  print: {
    cursor: 'pointer',
    display:'inline-block',
    border: 'thin solid #bbb',
    color: '#555',
    padding: '0.2rem 1rem',
    transition: '300ms',
    ':hover': {
      textDecoration: 'none',
      color: 'white',
      backgroundColor: '#bbb',
      transition: '300ms'
    }
  }

}

export default Planning
