import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import Layout from "app/universal/layout/Layout";
import {
  initialize,
  getBreakfastProducts,
  getBreakfastFormulas,
} from "state/adminData/actions";
import {
  addOrder,
  saveOrder,
  initializeOrderEdition,
  resetOrderEdition,
  fetchOrder
} from "state/orderEdition/actions";
import CustomerSelectComponent from "./CustomerSelectComponent";
import CompanySelectComponent from "./CompanySelectComponent";
import TenderSelectComponent from "./TenderSelectComponent";
import FormatSelectComponent from "./FormatSelectComponent";
import DeliveryInfoComponent from "./DeliveryInfoComponent";
import BillingInfoComponent from "./BillingInfoComponent";
import BreakfastFormulasComponent from "./breakfast/BreakfastFormulasComponent";
import ProductsComponent from "./breakfast/ProductsComponent";
import DeliveryFeeComponent from "./DeliveryFeeComponent";
import ReductionComponent from "./ReductionComponent";
import TotalPricesComponent from "./TotalPricesComponent";
import HeaderOrderInfoComponent from "./HeaderOrderInfoComponent";
import CommentaryOrderInfoComponent from "./CommentaryOrderInfoComponent";
import DiversOrderInfoComponent from "./DiversOrderInfoComponent";
// import ListOrdersComponent from "./ListOrdersComponent";
import OrderSummarizeComponent from "./OrderSummarizeComponent";
import Loader from "app/deliveryTool/components/delivery/FakeDelivery";
import { breakfastOrderStoreToApi } from "helper/transformDataHelper";
import { useLocation, useHistory } from 'react-router-dom';

const MultiOrders = () => {
  const dispatch = useDispatch();
  const isAdminDataLoading     = useSelector((state) => state.adminData.isLoading);
  const isAdminDataInialized   = useSelector((state) => state.adminData.isInitialized);
  const isOrderEditionLoading  = useSelector((state) => state.orderEdition.isLoading);
  const breakfastProductsState = useSelector((state) => state.adminData.breakfastProducts);
  const breakfastProducts      = breakfastProductsState.data;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get('order_id');
  const orderType = queryParams.get('order_type');

  const [isLoading, setIsLoading] = useState(isAdminDataLoading || isOrderEditionLoading);

  // Gére le loading
  useEffect(() => {
    setIsLoading((isAdminDataLoading || isOrderEditionLoading || breakfastProductsState.isLoading) ? true : false);
  }, [isAdminDataLoading, isOrderEditionLoading, breakfastProductsState]);

  // L'initialisation
  useEffect(() => {
    if (isAdminDataLoading === false && isAdminDataInialized === false) {
      dispatch(initialize());
    }
  }, [
    dispatch,
    isAdminDataLoading,
    isAdminDataInialized
  ]);

  // La commande
  useEffect(() => {
    if (orderId && orderType) {
      dispatch(fetchOrder(orderId, orderType));
    }
  }, [dispatch, orderId, orderType]);

  // Les produits petits dejs
  useEffect(() => {
    if (!breakfastProductsState.isInitialized && !breakfastProductsState.isLoading) {
      dispatch(getBreakfastProducts());
    }
  }, [dispatch, breakfastProductsState]);

  const currentOrderIndex = useSelector(
    (state) => state.orderEdition.currentOrderIndex
  );

  const orderEdition = useSelector((state) => state.orderEdition);

  const order = useSelector((state) => state.orderEdition.orders[currentOrderIndex]);

  const handleAddOrder = () => {
    dispatch(addOrder());
  };

  const history = useHistory();

  const handleNewOrder = () => {
    if (window.confirm("Vous êtes sure de vouloir faire une nouvelle commande ? Pensez à bien sauvegarder votre devis en cours.") == true) {
      dispatch(resetOrderEdition());
      reloadOffer();
    
      // Supprimer les paramètres order_id et order_type de l'URL
      const location = history.location;
      const queryParams = new URLSearchParams(location.search);
      queryParams.delete('order_id');
      queryParams.delete('order_type');
    
      history.push({
        pathname: location.pathname,
        search: queryParams.toString()
      });
    }
  };

  const reset = () => {
    dispatch(resetOrderEdition());
    reloadOffer();
  };

  const reloadOffer = () => {
    dispatch(initialize());
    dispatch(getBreakfastProducts());
    dispatch(getBreakfastFormulas());
  };

  const handleSaveAndExit = () => {
    const data = breakfastOrderStoreToApi(currentOrderIndex, orderEdition);
    dispatch(saveOrder(data, currentOrderIndex, order?.id));
    dispatch(initializeOrderEdition());
  };

  const handleSaveAndAdd = () => {
    const data = breakfastOrderStoreToApi(currentOrderIndex, orderEdition);
    dispatch(saveOrder(data, currentOrderIndex, order?.id));
    dispatch(addOrder());
  };

  return (
    <Layout>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) 
      : order && (order.version !== 'v2' || order.status > 200)
      ? (
        <div style={{ padding: "15px", textAlign: "center" }}>
          <h1>Commande non modifiable</h1>
          <p>
            { order.status > 200
              ? "Cette commande a déjà été validée et ne peut plus être modifiée."
              : "Cette commande a été créée avec une version antérieure de l'outil de commande et ne peut pas être modifiée."
            }
          </p>
        </div>
      )
      : (
        
        <div style={{"position":"absolute","inset":"52px 0px 0px","overflow":"hidden"}}>
            <div style={{"padding":"15px", "zIndex":"2","position":"absolute","top":"0px","bottom":"0px","transition":"-webkit-transform 0.3s ease-out 0s","willChange":"transform","overflowY":"auto","width":"300px","backgroundColor":"white","left":"0px","transform":"translateX(0%)","boxShadow":"rgba(0, 0, 0, 0.15) -2px 2px 4px"}}>
              <div>
                <br />
                <label>Sélection du client*</label>
                <CustomerSelectComponent />
                <br/>
                <label>Sélection de l'entreprise</label>
                <CompanySelectComponent />
                <br/>
                <TenderSelectComponent />
                <br/>
                <hr/>
                <br/>
                <DeliveryInfoComponent title="Livraison :" />
                  
                <BillingInfoComponent title="Facturation :" />
              </div>
            </div>

            {currentOrderIndex === null ? (
              <div style={{"padding":"15px", "position":"absolute","inset":"0px 300px 0px 300px","overflowY":"auto","transition":"left 0.3s ease-out 0s, right 0.3s ease-out 0s"}}>
                <Button variant="success" onClick={handleAddOrder}>
                  Ajouter une commande
                </Button>
              </div>
            ) : (
              <div style={{"padding":"15px", "position":"absolute","inset":"0px 300px 0px 300px","overflowY":"auto","transition":"left 0.3s ease-out 0s, right 0.3s ease-out 0s"}}>
                <Row
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    border: "1px solid lightgray",
                    borderRadius: "10px",
                    margin: "10px 0",
                  }}
                >
                  <Col xs={12} sm={12} md={12} style={{ margin: "15px 0" }}>
                    <FormatSelectComponent orderIndex={currentOrderIndex} />
                    {currentOrderIndex !== null ? (
                      <HeaderOrderInfoComponent
                        orderIndex={currentOrderIndex}
                      />
                    ) : (
                      <Button variant="success" onClick={handleAddOrder}>
                        Ajouter une commande
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    border: "1px solid lightgray",
                    borderRadius: "10px",
                    margin: "10px 0",
                  }}
                >
                  <Col xs={12} sm={12} md={12} style={{ margin: "15px 0" }}>
                    <h5>Commentaires</h5>
                    <CommentaryOrderInfoComponent orderIndex={currentOrderIndex} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={8} md={8} style={{ margin: "15px 0" }}>
                    <h3>Mes produits</h3>
                  </Col>
                  <Col xs={12} sm={4} md={4} style={{ margin: "15px 0" }} className="text-right">
                    <Button onClick={reloadOffer} variant="warning">
                      Recharger l'offre
                    </Button>
                  </Col>
                </Row>
                <Row
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    border: "1px solid lightgray",
                    borderRadius: "10px",
                    margin: "10px 0",
                  }}
                >
                  <Col xs={12} sm={12} md={12} style={{ margin: "15px 0" }}>
                    {currentOrderIndex !== null ? (
                      <BreakfastFormulasComponent
                        title="Les formules"
                        orderIndex={currentOrderIndex}
                        products={breakfastProducts}
                      />
                    ) : (
                      <Button variant="success" onClick={handleAddOrder}>
                        Ajouter une commande
                      </Button>
                    )}
                  </Col>
                </Row>

                <Row
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    border: "1px solid lightgray",
                    borderRadius: "10px",
                    margin: "10px 0",
                  }}
                >
                  <Col xs={12} sm={12} md={12} style={{ margin: "15px 0" }}>
                    {currentOrderIndex !== null ? (
                      <ProductsComponent
                        title="Autres prestations au détail"
                        orderIndex={currentOrderIndex}
                        products={breakfastProducts}
                      />
                    ) : (
                      <Button variant="success" onClick={handleAddOrder}>
                        Ajouter une commande
                      </Button>
                    )}
                  </Col>
                </Row>

                <Row
                  className="justify-content-between"
                  style={{
                    margin: "10px 0",
                  }}
                >
                  <Col
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      border: "1px solid lightgray",
                      borderRadius: "10px",
                      padding: "15px",
                      maxWidth: "49%",
                    }}
                  >
                    <h5>Frais de livraison</h5>
                    <DeliveryFeeComponent orderIndex={currentOrderIndex} />
                  </Col>
                  <Col
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      border: "1px solid lightgray",
                      borderRadius: "10px",
                      padding: "15px",
                      maxWidth: "49%",
                    }}
                  >
                    <h5>Réduction</h5>
                    <ReductionComponent orderIndex={currentOrderIndex} />
                  </Col>
                </Row>

                {/* <Row
                  style={{
                    margin: "10px 0",
                  }}
                >
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      border: "1px solid lightgray",
                      borderRadius: "10px",
                      padding: "15px",
                    }}
                  >
                    <h5>Prix totaux</h5>
                    <TotalPricesComponent orderIndex={currentOrderIndex} />
                  </Col>
                </Row> */}

                {/* Pas utile pour le moment <Row>
                  <Col>
                    <Button
                      variant="success"
                      size="lg"
                      block
                      onClick={handleSaveAndExit}
                    >
                      Enregistrer et quitter
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      variant="info"
                      size="lg"
                      block
                      onClick={handleSaveAndAdd}
                    >
                      Enregistrer et ajouter une autre prestation
                    </Button>
                  </Col>
                </Row> */}
              </div>
            )}

            <div style={{"padding":"15px", "zIndex":"2","position":"absolute","top":"0px","bottom":"0px","transition":"-webkit-transform 0.3s ease-out 0s","willChange":"transform","overflowY":"auto","width": "300px","backgroundColor":"white","right":"0px","transform":"translateX(0%)","boxShadow":"rgba(0, 0, 0, 0.15) -2px 2px 4px"}}>
              {/* Désactivé pour le moment <Row>
                <Col xs={12} sm={12} md={12} style={{ margin: "10px 0" }}>
                  <Button variant="success" onClick={handleAddOrder}>
                    Ajouter une commande
                  </Button>
                </Col>
              </Row> */}
              <Row>
                <Col xs={12} sm={8} md={8} style={{ margin: "10px 0" }}>
                  <h4>Récapitulatif</h4>
                </Col>
                <Col xs={12} sm={4} md={4} className="text-right">
                  <OverlayTrigger
                    key='bottom'
                    placement='bottom'
                    overlay={
                      <Tooltip id={`tooltip-bottom`}>
                        Créer une nouvelle commande, pensez à enregistrer la commande en cours avant.
                      </Tooltip>
                    }
                  >
                    <Button onClick={handleNewOrder} variant="primary" size="sm" style={{ margin: "10px 0" }}>
                      +
                    </Button>
                  </OverlayTrigger>
                </Col>
                <Col xs={12} sm={12} md={12} style={{ margin: "10px 0" }}>
                  <OrderSummarizeComponent orderIndex={0} currentOrderIndex={currentOrderIndex}/>
                </Col>
                {/* pour le multi devis <Col xs={12} sm={12} md={12} style={{ margin: "10px 0" }}>
                  <h5>Récapitulatif commande(s)</h5>
                  <ListOrdersComponent />
                </Col> */}
              </Row>
              <hr/>
              <Row>
                <Col xs={12} sm={12} md={12} style={{ margin: "10px 0" }}>
                  <h5>Info complémentaires</h5>
                  <DiversOrderInfoComponent />
                </Col>
              </Row>
            </div>
          </div>
      )}
    </Layout>
  );
};

export default MultiOrders;
