import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const Checkbox = (props) => {

  const {
    label,
    value,
    handleChange,
    isLoading
  } = props

  return (

    <div className="fancy">
      <p>
        <label>
          <input type="checkbox" checked={value} onChange={handleChange}/>
          <span>{label}</span>
        </label>
        {isLoading && <FontAwesomeIcon icon={faSpinner} pulse/>}
      </p>
    </div>

  )

}

export default Checkbox
