import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import { Container, Row, Col, Dropdown, Button } from "react-bootstrap";
import { css } from "emotion";
import UpdateDataApi from "app/universal/utils/UpdateDataApi";
import LeMoulinApi from "app/universal/utils/LeMoulinApi";
import swal from 'sweetalert';

const Invoice = (props) => {
  const { order } = props;

  const [isLoading, setIsLoading]                         = useState(false);
  const [isCancelled, setIsCancelled]                     = useState(order.cancel_date ? true : false);
  const [cancelDate, setCancelDate]                       = useState(order.cancel_date);
  const [correctionPaymentDate, setCorrectionPaymentDate] = useState(order.correction_payment_date);
  const [info, setInfo]                                   = useState(order.info);

  const currentUrlEncode = encodeURIComponent(window.location.href);
  
  const {
    company,
    company_old,
    firstname,
    lastname,
    format,
    participants_number,
    id,
    on_chorus_manually,
    chorus_response,
    chorus_info
  } = order;

  const delivery_date = order.delivery
    ? order.delivery.delivery_date
    : order.delivery_date;

  const themeColor = () => {
    if (info.order_type === "mealtray") {
      return "#a18b6e";
    } else if (info.order_type === "caterer") {
      return "black";
    } else if (info.order_type === "breakfast") {
      return "#439b5c";
    } else if (info.order_type === "v2") {
      return "#2b7ed6";
    }
  };

  const cancelOrder = () => {
    setIsLoading(true);
    const api = info.order_type;
    swal({
      title: "Attention ",
      text: "Vous allez annuler cette facture et générer un avoir, cette action est irreversible.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((confirm) => {
      if(confirm){
        let cancelDate = moment().format('YYYY-MM-DD HH:mm:ss');
        const put= LeMoulinApi.request[api].put(id, {cancel_date : cancelDate})
        return put.then(resp => {
          if (resp.success !== undefined && resp.success === false) {
            let msg = "Une erreur est survenue lors de l'annulation de la facture";
            if (resp.message) {
              msg = resp.message;
            }
            swal({
              title: "Attention ",
              text: msg,
              icon: "warning",
              dangerMode: true,
            })           
          }
          else {
            setCancelDate(resp.cancel_date);
            setIsCancelled(resp.cancel_date ? true : false); 
            setInfo(resp.info);
          }
          setIsLoading(false);
        });
      }else{
        setIsLoading(false);
      }
    });
  }

  /**
   * Handler pour le updateDataApi qui gère si erreur et modifie la variable de correction_payment_date
   */
  const getCorrectionPaymentDateResponse = (resp) => {
    if (resp.success && resp.success === false) {
      let msg = "Une erreur est survenue lors de l'annulation de la facture";
      if (resp.message) {
        msg = resp.message;
      }
      swal({
        title: "Attention ",
        text: msg,
        icon: "warning",
        dangerMode: true,
      })            
    }
    else {
      setCorrectionPaymentDate(resp.correction_payment_date);
      setInfo(resp.info);
    }
  }

  const styles = {
    delivery: {
      background: "white",
      margin: "0.5rem 0",
      border: "1px solid #ddd",
      borderLeft: "10px solid " + themeColor() + "",
      transition: "300ms",
    },
    statut: {
      textTransform: "uppercase",
      fontSize: "0.6rem",
      marginBottom: "0rem",
    },
    col: {
      padding: "0.5rem",
      borderRight: "1px #dedede solid",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    verticalAlign: {
      display: "flex",
      flexDirection: "row",
    },
    para: {
      marginBottom: "0rem",
      fontSize: "0.9rem",
      lineHeight: "1.3",
    },
    center: {
      textAlign: "center",
      "@media (max-width: 767px)": {
        textAlign: "left",
      },
    },
    divisor: {
      height: "1px",
      backgroundColor: "#dedede",
      margin: "0.7rem 0",
    },
    borderRight: {
      "@media (max-width: 767px)": {
        borderRight: "1px #dedede solid",
      },
    },
    mobileDivisor: {
      "@media (max-width: 767px)": {
        display: "none",
      },
    },
    borderBottomMobile: {
      "@media (max-width: 767px)": {
        borderBottom: "1px #dedede solid",
      },
    },
    borderTopMobile: {
      "@media (max-width: 767px)": {
        borderTop: "1px #dedede solid",
      },
    },
    company: {
      textTransform: "uppercase",
      color: themeColor(),
      fontWeight: "bold",
      fontSize: "0.8rem",
      margin: "0",
    },
    label2Col: {
      width: "80%",
      display: "inline-block",
      margin: "auto",
    },
    checkbox2Col: {
      width: "20%",
      display: "inline-block",
      borderLeft: "1px solid #dedede",
      margin: "auto",
    },
    button: {
      textWrap: 'wrap',
      width: '100%',
      color: '#222d32',
      textAlign: 'center',
      fontSize: '0.8rem',
      transition: '300ms',
      display: 'block',
      padding: '4px',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      wordBreak: 'break-word',
      ':hover':{
        textDecoration: 'none',
        backgroundColor: '#222d32',
        color: 'white',
      }
    },
    theme: {
      backgroundColor: themeColor(),
      color: "white",
    },
    grey: {
      backgroundColor: "#d7d7d7",
      color: "black",
    },
    red: {
      color: "red",
    },
    lightGrey: {
      backgroundColor: "#ebebeb",
      color: "black",
    },
    ultraLightGrey: {
      backgroundColor: "#f4f4f4",
      color: "black",
    },
    action: {
      padding: "0",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    fixed: {
      position: "absolute",
      right: "0",
      top: "0",
    },
    buttonToggle: {
      borderRadius: '0px !important',
      padding: '.25rem .5rem',
      border: 'none',
      fontSize: '0.8rem',
      backgroundColor: '#f4f4f4',
      color: 'black',
      width: '100%',
      textWrap: 'wrap',
      '::after':{
        display: 'inline-block',
        marginLeft: '.255em',
        verticalAlign: '0',
        content: '"···"',
        border: 'none'
      }
    },
    startTitle: {
      textTransform: "uppercase",
      fontSize: "0.6rem",
    },
    documents: {
      display: 'flex'
    },
    borderRight: {
      ':first-of-type':{
        borderRight: 'solid #fff 1px;'
      },
      ':last-of-type':{
        borderRight: 'none'
      }
    },
    borderTopBottom: {
      borderTop: 'solid #fff 1px;',
      borderBottom: 'solid #fff 1px;'
    },
    document: {
      border: '1px solid black'
    },
    loader: {
      position: 'absolute',
      top: '0',
      right: '0',
      left: '0',
      bottom: '0',
      backgroundColor: 'white',
      opacity : 0.8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      textTransform: 'uppercase'
    }
  };

  return (
    <Container className={css(styles.delivery)} fluid>
      <Row className={css(styles.verticalAlign)}>
        <Col
          xs={12}
          md={4}
          className={css(styles.col, styles.borderBottomMobile)}
        >
          <p className={css(styles.para)}>
            <small>
              <b>
                {order.info.orderNumber} -&nbsp; 
                {info.document_urls.length > 0 &&
                  info.document_urls.map((document, index) => 
                    <>
                      <a href={document.url} target="_blank" key={index*10}>
                        {document.name}
                      </a>
                      &nbsp;-&nbsp;
                    </>
                  )          
                }&nbsp;
                {order.total_price_ttc && order.total_price_ttc / 100+"€" }
              </b>
            </small>
          </p>
          <p className={css(styles.company)}>
            {company && company.name ? company.name : company_old} <br />{" "}
            {firstname} {lastname}
          </p>
        </Col>
        <Col xs={6} md={3} className={css(styles.col)}>
          <p className={css(styles.para, styles.center)}>
            {moment(delivery_date).format("DD/MM/YYYY")}
          </p>
          <div className={css(styles.divisor, styles.mobileDivisor)}></div>
          <div className={css(styles.para, styles.center)}>
            {isCancelled ? 
                <>Commande annulée le {moment(cancelDate).format('D/M/Y à HH:mm')}</>
              :
              <>
                {order.status == "201" ?
                  <span className={css(styles.label2Col, styles.center)}>
                    {order.info && order.info.payment_date_limit && `A payer avant le ${moment(order.info.payment_date_limit).format('D/M/Y')}`}
                  </span>
                : order.status == "203" ? 
                  <><span className={css(styles.startTitle)}>Payé le </span><br/><UpdateDataApi
                  value={order.paid_date}
                  id={id}
                  name={"paid_date"}
                  api={info.order_type}
                  input={"date"}
                  handler={() => {}}
                /></> 
                : null }
              </>
            }
          </div>
          {order.status == "201" && !isCancelled &&
            <>
              <div className={css(styles.divisor, styles.mobileDivisor)}></div>
              <p className={css(styles.para, styles.center)}>
                <>
                <span className={css(styles.startTitle)}>Payé le:</span><br/> 
                  <UpdateDataApi
                    value={order.paid_date}
                    id={id}
                    name={"paid_date"}
                    api={info.order_type}
                    input={"date"}
                    handler={() => {}}
                    noFutur={true}
                  />
                </>
              </p>
            </>
          }
          {order.status == "203" && isCancelled &&
            <>
              <div className={css(styles.divisor, styles.mobileDivisor)}></div>
              <p className={css(styles.para, styles.center)}>
                <>
                <span className={css(styles.startTitle)}>Avoir réglé le:</span><br/> 
                  <UpdateDataApi
                    value={correctionPaymentDate}
                    id={id}
                    name={"correction_payment_date"}
                    api={info.order_type}
                    input={"date"}
                    handler={getCorrectionPaymentDateResponse}
                    noFutur={true}
                  />
                </>
              </p>
            </>
          }
        </Col>
        <Col xs={6} md={3} className={css(styles.col)}>
          <div className={css(styles.para, styles.center)}>
            {(!isCancelled && info.on_chorus_auto == false) && 
              <>
                <span className={css(styles.label2Col, styles.center)}>
                <UpdateDataApi
                    value={on_chorus_manually}
                    id={id}
                    name={"on_chorus_manually"}
                    api={info.order_type}
                    input={"checkbox"}
                    handler={() => {}}
                  /> <small>Mis sur chorus manuellement ?</small>
                </span>
                <div className={css(styles.divisor, styles.mobileDivisor)}></div>
              </>
            }
            
            <span className={css(styles.label2Col, styles.center)}>
              Sur Chorus : {on_chorus_manually || info.on_chorus_auto == true ? "Oui" : ((order.company && order.company.bill_on_chorus) ? "Programmé" : "Non")}<br/><small>{on_chorus_manually && "(manuellement)"} {info.on_chorus_auto == true && "Envoyé par l'APP"}<br/>
              {(chorus_response && (on_chorus_manually || info.on_chorus_auto == true)) ? "Dépot le : "+moment(chorus_response.dateDepot).format("DD/MM/YYYY") :
              (info.chorus_date_depot && (on_chorus_manually || info.on_chorus_auto == true)) ? "Dépot le : "+moment(info.chorus_date_depot).format("DD/MM/YYYY") : null }
              </small>
            </span>
          </div>
        </Col>
        <Col xs={6} md={2} className={css(styles.action)}>
          {(!isCancelled && info.on_chorus_auto == false) &&
            <Link
              to={{
                pathname: `${info.chorus_url}`,
              }}
              className={css(styles.button, styles.theme)} 
              target="_blank"
            >
              Envoyer sur chorus
            </Link>   
          }    
          <Link target="_parent" to={{
                  pathname: `${info.clone_url}?redirect_url_encode=${currentUrlEncode}`,
                }} className={css(styles.button, styles.grey, styles.borderTopBottom)}>Dupliquer</Link>     
          <Dropdown>
            <Dropdown.Toggle variant="secondary" size="sm" id="dropdown-custom-components" className={css(styles.buttonToggle)}>
              Documents & Annulation
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {info.document_urls.length > 0 ?
                info.document_urls.map((document, index) => 
                  <Dropdown.Item
                    key={index*10}
                    href={document.url}
                    target="_blank"
                  >
                    {document.name}
                  </Dropdown.Item>
                )  
                :
                <Dropdown.Item eventKey="10" href={info.generate_document_url} target="_blank">Générer le document manquant</Dropdown.Item>            
              }
              <hr/>
                <Dropdown.Item eventKey="2" href={info.propose_url} target="_blank">Le Moulin vous propose</Dropdown.Item>
              <hr/>
              {!isCancelled && 
              <Dropdown.Item eventKey="1"
                  className={css(styles.red)}
                  onSelect={cancelOrder}
                >
                  Annuler et générer un avoir<br/>
                </Dropdown.Item>
              }
            </Dropdown.Menu>
          </Dropdown>              
        </Col>
      </Row>
      {isLoading && <div className={css(styles.loader)}>
        <div>Chargement...</div>
      </div>}
    </Container>
  );
};

Invoice.propTypes = {
  order: PropTypes.object,
};

export default Invoice;
