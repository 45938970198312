import React from 'react'
import PropTypes from 'prop-types'

import { useFilters } from 'app/deliveryTool/context/FiltersProvider'
import { useRoadmaps } from 'app/deliveryTool/context/RoadmapsProvider'

import Delivery from 'app/deliveryTool/components/delivery/Delivery'
import Recovery from 'app/deliveryTool/components/recovery/Recovery'

const DeliveryRecoveryContainer = (props) => {

  const {
    type,
    theme,
    delivery,
    colorDeliveryMan,
    roadmapId,
    roadmapName,
    sort,
    recoveryReallocation
  } = props

  const rmData = useRoadmaps();
  const filters = useFilters();

  const setRoadmaps = (data) => {
    rmData.setRoadmaps(data)
  }

  // Supprime une rm recovery d'un rm en fonction d'une livraison
  const removeRmRecoveryRoadmap = (delivery) => {
    let roadmaps = [...rmData.roadmaps]
    roadmaps.forEach((roadmap, index) => {
      roadmap.deliveries.forEach((rmDelivery, i) => {
        if (rmDelivery.delivery.id === delivery.id && rmDelivery.recovery === true) {
          roadmaps[index].deliveries.splice(i, 1)
        }
      })
    })
    setRoadmaps(roadmaps)
  }

  // Met à jour les recups en fonction de l'attr recovery de la livraison
  const updateRecovery = (delivery) => {
    const recoveries = [...rmData.recoveries]
    const index = rmData.recoveries.findIndex(function(element) {
      return parseInt(element.id) === parseInt(delivery.id);
    }, [delivery])
    // Supprime une recup non attr
    if (index !== -1 && delivery.recovery === false) {
      recoveries.splice(index, 1)
      rmData.setRecoveries(recoveries)
    }
    // Ajoute une recup non attr
    else if (index === -1 && delivery.recovery === true && delivery.status !== '111' && delivery.status !== '101') {
      recoveries.push(delivery)
      rmData.setRecoveries(recoveries)
    }

    // Verifie qu'il n'y a pas un rm recovery de cette livraison dans un rm, si c'est le cas, il supprime ce rm recovery
    if (delivery.recovery === false) {
      removeRmRecoveryRoadmap(delivery)
    }
  }

  // Met à jour les livraisons et roadmap qui possède cette livraison
  const handler = (data) => {

    if( type === 'delivery'){

      // Met a jour la livraison
      let deliveries = [...rmData.deliveries]
      let index = rmData.deliveries.findIndex(function(element) {
        return parseInt(element.id) === parseInt(data.id);
      }, [data])
      deliveries[index] = data
      rmData.setDeliveries(deliveries)

      // Les recups si l'update concerne le champ recovery
      updateRecovery(data)

    }else if( type === 'recovery'){

      let recoveries = [...rmData.recoveries]
      let index = rmData.recoveries.findIndex(function(element) {
        return parseInt(element.id) === parseInt(data.id);
      }, [data])
      recoveries[index] = data
      rmData.setRecoveries(recoveries)

    }

    // La Rm avec la livraison mise à jour ou la recovery supprimé
    if (roadmapId) {

      rmData.setRoadmapsInit(() => {

        const rmUpdated = rmData.roadmapsInit.map((roadmap, i) => {

          // Trouve la bonne Rm
          if (roadmap.id === roadmapId) {
            // Filtre les livraisons pour retirer les recup si une livraison n'est plus à recup
            const rmDeliveries = roadmap.deliveries
            .filter((rmDelivery) => {
              if (data.id === rmDelivery.delivery.id) {
                if (data.recovery === false && rmDelivery.recovery === true) {
                  return false
                }
              }
              return true
            })
            // Parcours les livraisons pour mettre à jour la bonne
            .map((rmDelivery, ii) => {
              if (data.id === rmDelivery.delivery.id) {
                rmDelivery.delivery = data
                return rmDelivery
              }
              else {
                return rmDelivery
              }
            })
            roadmap.deliveries = rmDeliveries
            return roadmap

          } else {
            return roadmap;
          }
        });
        return rmUpdated
      })
    }
  }

  return (
    <div>
    {type === 'recovery' ?
      <Recovery
        delivery={delivery}
        colorDeliveryMan={colorDeliveryMan}
        filters={filters}
        handler={handler}
        getUrl={process.env.REACT_APP_API_URL}
        theme={theme}
        roadmapId={roadmapId}
        handleReallocation={recoveryReallocation}
      />
    :
      <Delivery
        delivery={delivery}
        colorDeliveryMan={colorDeliveryMan}
        roadmapName={roadmapName}
        sort={sort}
        filters={filters}
        handler={handler}
        getUrl={process.env.REACT_APP_API_URL}
        theme={theme}
      />
    }
    </div>

  )

}

DeliveryRecoveryContainer.propTypes = {
  type                : PropTypes.string,
  theme               : PropTypes.string,
  delivery            : PropTypes.object,
  colorDeliveryMan    : PropTypes.string,
  roadmapId           : PropTypes.number,
  roadmapName         : PropTypes.string,
  sort                : PropTypes.number,
  recoveryReallocation: PropTypes.func,
}

DeliveryRecoveryContainer.defaultProps = {
  type: 'delivery',
  theme: 'full',
  roadmapId: null,
}

export default DeliveryRecoveryContainer
