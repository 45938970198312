import React from 'react'
import { css } from 'emotion'

const Search = (props) => {

  const {
    id,
    name,
    type,
    onChange,
    onBlur,
    value,
    placeholder
  } = props

  return (
     <input
      id={id}
      name={name}
      type={type}
      onChange={onChange}
      onBlur={onBlur}
      defaultValue={value}
      placeholder={placeholder}
      className={css(style.input)}/>
  )

}

const style = {
  input:{
    border: 'none',
    borderBottom: '2px solid #eaeae9',
    padding: '0.5rem',
    width: '100%',
    ':focus':{
      outline : '0'
    },
  }
}

export default Search
