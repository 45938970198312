import React from "react";
import Sidebar from "react-sidebar";
import { css } from 'emotion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSlidersH } from '@fortawesome/free-solid-svg-icons'

const mql = window.matchMedia(`(min-width: 800px)`);

class SidebarInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarDocked: mql.matches,
      sidebarOpen: false
    };

    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }

  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  mediaQueryChanged() {
    this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
  }

  render() {
    return (
      <Sidebar
        sidebar={this.props.component}
        open={this.state.sidebarOpen}
        docked={this.state.sidebarDocked}
        onSetOpen={this.onSetSidebarOpen}
        pullRight={true}
        styles={{ root: {
          position: "absolute",
          top: '52px',
          left: 0,
          right: 0,
          bottom: 0,
          overflow: "hidden",
        },
        sidebar: {
          width: '250px',
          zIndex: 2,
          position: "absolute",
          top: 0,
          bottom: 0,
          transition: "transform .3s ease-out",
          WebkitTransition: "-webkit-transform .3s ease-out",
          willChange: "transform",
          overflowY: "auto",
          backgroundColor: 'white',
        },
        content: {
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          WebkitOverflowScrolling: "touch",
          transition: "left .3s ease-out, right .3s ease-out"
        },
        overlay: {
          zIndex: 1,
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0,
          visibility: "hidden",
          transition: "opacity .3s ease-out, visibility .3s ease-out",
          backgroundColor: "rgba(0,0,0,.3)"
        },
        dragHandle: {
          zIndex: 1,
          position: "fixed",
          top: 0,
          bottom: 0
        }}}
      >
        <button onClick={() => this.onSetSidebarOpen(true)} className={css(styles.button)}>
          <FontAwesomeIcon icon={faSlidersH} />
        </button>
        <div id="scrollableDiv" style={{ height: 'calc(100vh - 52px)', overflow: "auto" }}>
          {this.props.children}
        </div>
      </Sidebar>
    );
  }
}

const styles = {
  button: {
    backgroundColor: 'white',
    width: '55px',
    height: '55px',
    position: 'fixed',
    bottom: '5%',
    right: '5%',
    borderRadius: '50%',
    fontSize: '1.2rem',
    zIndex: '1',
    border: '1px solid #ddd',
    boxShadow: 'rgba(0,0,0,0.05) -3px 1px 2px;',
  },
}

export default SidebarInfo;
