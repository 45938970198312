import React from 'react'
import { css } from 'emotion'

const Select = (props) => {

  const {
    theme,
    id,
    name,
    onChange,
    onBlur,
    value,
    data
  } = props


  const style = {
    select:{
      backgroundColor: theme === 'grey' ? '#f8f8f6' : 'inherit',
      border: 'none',
      borderBottom: '2px solid #eaeae9',
      padding: '0.5rem',
      width: '100%',
      ':focus':{
        outline : '0'
      },
    }
  }

  return (
     <select className={css(style.select)} onChange={onChange} id={id} name={name}
     onBlur={onBlur} value={value}>
       {data && data.map((info, index) =>
         <option value={info.option} key={index}>{info.traduction}</option>
       )}
     </select>
  )

}

export default Select
