import React, { createContext, useContext, useState, useEffect } from 'react'
import moment from 'moment'

const FiltersContext = createContext(null)
const FiltersProvider = (props) => {

  const queryString = require('query-string')

  // Initialisation du filtre date
  const initDate = () => {
    const query = queryString.parse(window.location.search);

    if(query.date === undefined ){
      return new Date();
    }
    return new Date(query.date);
  }

  // Initialisation du filtre delivery man
  const initDeliveryMan = () => {
    const query = queryString.parse(window.location.search);
    if(query.deliveryman === undefined ){
      return 'all';
    }
    return query.deliveryman;
  }

  // Initialisation du filtre delivery man
  const initOrderType = () => {
    const query = queryString.parse(window.location.search);
    if(query.ordertype === undefined ){
      return 'all';
    }
    return query.ordertype;
  }

  // Initialisation du filtre delivery man
  const initDeliveryType = () => {
    const query = queryString.parse(window.location.search);
    if(query.deliverytype === undefined ){
      return 'all';
    }
    return query.deliverytype;
  }

  // Initialisation du filtre public
  const initPublicType = () => {
    const query = queryString.parse(window.location.search);
    if(query.publictype === undefined ){
      return '0';
    }
    return query.publictype;
  }

  const [dateFilter, setDateFilter]                 = useState(initDate());
  const [deliveryManFilter, setDeliveryManFilter]   = useState(initDeliveryMan());
  const [orderTypeFilter, setOrderTypeFilter]       = useState(initOrderType());
  const [deliveryTypeFilter, setDeliveryTypeFilter] = useState(initDeliveryType());
  const [publicTypeFilter, setPublicTypeFilter]     = useState(initPublicType());
  const [showRoadmapFilter, setShowRoadmapFilter]   = useState(null);
  const [showDeliveryFilter, setShowDeliveryFilter] = useState(null);

  // Permet de mettre à jour l'url dès qu'un state change
  useEffect(() => {
      
      const dateUrl = moment(dateFilter).format('YYYY-MM-DD')

      let deliveryManUrl = '&deliveryman='+deliveryManFilter
      let orderTypeUrl = '&ordertype='+orderTypeFilter
      let deliveryTypeUrl = '&deliverytype='+deliveryTypeFilter
      let publicTypeUrl = '&publictype='+publicTypeFilter

      if (deliveryManFilter === 'all')
        deliveryManUrl = ''
      if (orderTypeFilter === 'all')
        orderTypeUrl = ''
      if (deliveryTypeFilter === 'all')
        deliveryTypeUrl = ''
      if (publicTypeFilter === '0')
        publicTypeUrl = ''

      if (window.location.search !== '?date='+dateUrl+deliveryManUrl+orderTypeUrl+deliveryTypeUrl+publicTypeUrl) {
        window.history.replaceState(null, null, '?date='+dateUrl+deliveryManUrl+orderTypeUrl+deliveryTypeUrl+publicTypeUrl);
      }

  }, [dateFilter, deliveryManFilter, orderTypeFilter, publicTypeFilter, deliveryTypeFilter])

  const filters = {
    date            : dateFilter,
    setDate         : (date) => {setDateFilter(date)},
    deliveryMan     : deliveryManFilter,
    setDeliveryMan  : (deliveryMan) => {setDeliveryManFilter(deliveryMan)},
    orderType       : orderTypeFilter,
    setOrderType    : (orderType) => {setOrderTypeFilter(orderType)},
    deliveryType    : deliveryTypeFilter,
    setDeliveryType : (orderType) => {setDeliveryTypeFilter(orderType)},
    publicType      : publicTypeFilter,
    setPublicType   : (publicType) => {setPublicTypeFilter(publicType)},
    showRoadmap     : showRoadmapFilter,
    setShowRoadmap  : (showRoadmap) => {setShowRoadmapFilter(showRoadmap)},
    showDelivery     : showDeliveryFilter,
    setShowDelivery  : (showDelivery) => {setShowDeliveryFilter(showDelivery)}
  }

  return (
    <FiltersContext.Provider value={filters}>
      {props.children}
    </FiltersContext.Provider>
  )
}

export default FiltersProvider
export const useFilters = () => useContext(FiltersContext)
