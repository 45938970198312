import axios from "axios";

export const INITIALIZE_MULTI_DEVIS = "INITIALIZE_MULTI_DEVIS";
export const INITIALIZE_MULTI_DEVIS_COMPLETED =
  "INITIALIZE_MULTI_DEVIS_COMPLETED";
export const INITIALIZE_MULTI_DEVIS_FAILED = "INITIALIZE_MULTI_DEVIS_FAILED";

export const GET_BREAKFAST_PRODUCTS = "GET_BREAKFAST_PRODUCTS";
export const GET_BREAKFAST_PRODUCTS_COMPLETED = "GET_BREAKFAST_PRODUCTS_COMPLETED";
export const GET_BREAKFAST_PRODUCTS_FAILED = "GET_BREAKFAST_PRODUCTS_FAILED";

export const GET_BREAKFAST_FORMULAS = "GET_BREAKFAST_FORMULAS";
export const GET_BREAKFAST_FORMULAS_COMPLETED = "GET_BREAKFAST_FORMULAS_COMPLETED";
export const GET_BREAKFAST_FORMULAS_FAILED = "GET_BREAKFAST_FORMULAS_FAILED";

export const initialize = () => ({
  type: INITIALIZE_MULTI_DEVIS,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/orders/initialize.json`,
    method: "GET",
    params: {},
  },
});

export const getBreakfastProducts = () => ({
  type: GET_BREAKFAST_PRODUCTS,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/breakfasts/products.json`,
    method: "GET",
    params: {},
  },
});

export const getBreakfastFormulas = () => ({
  type: GET_BREAKFAST_FORMULAS,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/breakfasts/formulas.json`,
    method: "GET",
    params: {},
  },
});
