import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import moment from 'moment'
import { css } from 'emotion'

Modal.setAppElement('body')

const ModalSendQuotation = (props) => {

    const {
      isOpen,
      handleCloseModal,
      data
    } = props

    let msg = ""

    if(data){
      msg = `Bonjour ${data.firstname} ${data.lastname} !\n\nVotre commande n°C/${data.id} pour le ${moment(data.delivery_date).format('DD-MM-YYYY')} a bien été prise en compte, nous vous en remercions. Vous trouverez ci-joint le devis associé.\n\nPour rappel, votre commande est modifiable jusqu'à 24h avant votre événement.<br /><br />Vous pourrez effectuer le règlement par CB en ligne à partir du jour de la prestation ou sur facture par chèque ou par virement à 30 jours.\n\nA bientôt,\nL'équipe du Moulin`
    }

    return (
      <div>
      {data &&
        <Modal
           isOpen={isOpen}
           contentLabel="Minimal Modal Example"
           className={css(styles.modal)}
           overlayClassName={css(styles.overlay)}
        >
          <textarea defaultValue={msg} className={css(styles.textarea)}/>
          <div className={css(styles.center)}>
            <button onClick={handleCloseModal} className={css(styles.buttonSend)}>Envoyer</button>
            <button onClick={handleCloseModal} className={css(styles.buttonClose)}>Ne pas envoyer et fermer</button>
          </div>
        </Modal>
        }
      </div>
    );

}

const styles = {
  overlay:{
    position: 'fixed',
    backgroundColor: 'rgba(0,0,0,0.6)',
    zIndex: '9999',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
  },
  textarea:{
    width: '100%',
    height: '330px',
    padding: '.375rem .75rem',
    border: '1px solid #ced4da',
    borderRadius: '.25rem',
  },
  center: {
    textAlign: 'center',
    margin: 'auto'
  },
  buttonSend:{
    width: '90%',
    textAlign: 'center',
    fontSize: '0.8rem',
    transition: '300ms',
    display: 'block',
    padding: '10px 5px',
    backgroundColor: '#222d32',
    color: 'white',
    border: 'none',
    margin: 'auto',
    ':hover':{
      textDecoration: 'none',
      backgroundColor: 'black',
      color: 'white',
    }
  },
  buttonClose:{
    margin: '5px auto',
    width: '50%',
    textAlign: 'center',
    fontSize: '0.8rem',
    transition: '300ms',
    display: 'block',
    padding: '10px 5px',
    backgroundColor: '#d7d7d7',
    color: 'black',
    border: 'none',
    ':hover':{
      textDecoration: 'none',
      backgroundColor: 'black',
      color: 'white',
    }
  },
  modal: {
    position: 'absolute',
    maxWidth: '500px',
    height: '430px',
    margin: 'auto',
    top: '40px',
    left: '40px',
    right: '40px',
    bottom: '40px',
    overflow: 'auto',
    backgroundColor: 'white',
  },
}

export default ModalSendQuotation
