import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import LeMoulinApi from 'app/universal/utils/LeMoulinApi'
import Checkbox from 'app/universal/inputs/Checkbox'
import Text from 'app/universal/inputs/Text'
import Times from 'app/universal/inputs/Times'
import Select from 'app/universal/inputs/Select'
import DateInput from 'app/universal/inputs/DateInput'

const UpdateDataApi = (props) => {

  const {
    label,
    value,
    id,
    name,
    api,
    input,
    placeholder,
    handler,
    data,
    color,
    typeFormat,
    noFutur
  } = props

  const [dataValue, setDataValue] = useState(value)
  const [isLoading, setIsLoading] = useState(false);

  // Fonction pour update les types Checkbox/Radio
  const updateCheck = (event) => {
    setIsLoading(true)
    setDataValue(event.target.checked)
    const putDelivery= LeMoulinApi.request[api].put(id, {[name] : event.target.checked})
    putDelivery.then(delivery => {
      handler(delivery)
      setIsLoading(false)
    })
  }

  // Fonction pour update les types text
  const updateText = (event) => {
    setIsLoading(true)
    setDataValue(event.target.value)
    if(event.target.value !== value){
      const putDelivery= LeMoulinApi.request[api].put(id, {[name] : event.target.value})
      putDelivery.then(delivery => {
        handler(delivery)
        setIsLoading(false)
      })
    }
  }

  // Fonction pour update les types select
  const updateSelect = (event) => {
    setIsLoading(true)
    setDataValue(event.target.value)
    if(event.target.value !== value){
      const putDelivery= LeMoulinApi.request[api].put(id, {[name] : event.target.value})
      putDelivery.then(delivery => {
        handler(delivery)
        setIsLoading(false)
      })
    }
  }

  // Fonction pour update les types text
  const updateTimes = async (event) => {
    let val
    if (event === null) {
      val = null
    }
    else {
      val = moment(event).format('HH:mm')
    }


    let putDelivery = null;
    // Permet d'acceder d'uploader une donnée ayant un acces via des enfants, on retourne une value stringify qu'on traite apres pour la convertir en objet.
    if(typeFormat === 'stringify'){

      var res = name.replace(/value/g, val);
      let data = JSON.parse(res);
      putDelivery = await LeMoulinApi.request[api].put(id, data);

    }else{

      putDelivery = await LeMoulinApi.request[api].put(id, {[name] : val});

    }

    if (typeof putDelivery['error'] === 'undefined') {
      handler(putDelivery)
      setDataValue(event)
      return event
    }
    else {
      return dataValue
    }
  }

  const updateDate = (dateF) => {
    setIsLoading(true)
    setDataValue(dateF)
    if(dateF !== value){
      const put = LeMoulinApi.request[api].put(id, {[name] : dateF})
      put.then(resp => {
        handler(resp)
        setIsLoading(false)
      })
    }
  }

  return (

    <div>
      {input === 'checkbox' ?
        <Checkbox label={label} value={dataValue} handleChange={updateCheck} isLoading={isLoading}/>
      : input === 'text' ?
        <Text label={label} value={dataValue} handleChange={updateText} placeholder={placeholder} isLoading={isLoading}/>
      : input === 'times' || input === 'time' ?
        <Times label={label} value={value} handleChange={updateTimes} isLoading={isLoading}/>
      : input === 'date' ?
        <DateInput noFutur={noFutur} label={label} value={value} handleChange={updateDate} isLoading={isLoading}/>
      : input === 'select' ?
        <Select label={label} value={dataValue} handleChange={updateSelect} data={data} color={color} isLoading={isLoading}/>
      :
        null
      }

    </div>

  )

}


UpdateDataApi.propTypes = {
  label      : PropTypes.string,
  value      : PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object]),
  id         : PropTypes.number,
  name       : PropTypes.string,
  api        : PropTypes.string,
  input      : PropTypes.string,
  placeholder: PropTypes.string,
  typeFormat: PropTypes.string,
}

export default UpdateDataApi
