import React from 'react'
import {Form} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const Select = (props) => {

  const {
    value,
    data,
    handleChange,
    color,
    isLoading
  } = props


  return (
    <>
      <Form.Control as="select" onChange={handleChange} value={value} style={{ color: color }}>
        {data && data.map((info, index) =>
          <option value={info.option} key={index}>{info.traduction}</option>
        )}
      </Form.Control>
      {isLoading && <FontAwesomeIcon icon={faSpinner} pulse/>}
    </>

  )

}

export default Select
