import {
  INITIALIZE_MULTI_DEVIS,
  INITIALIZE_MULTI_DEVIS_FAILED,
  INITIALIZE_MULTI_DEVIS_COMPLETED,
  GET_BREAKFAST_PRODUCTS,
  GET_BREAKFAST_PRODUCTS_FAILED,
  GET_BREAKFAST_PRODUCTS_COMPLETED,
  GET_BREAKFAST_FORMULAS,
  GET_BREAKFAST_FORMULAS_FAILED,
  GET_BREAKFAST_FORMULAS_COMPLETED,
} from "./actions";

const initialState = {
  isLoading: false,
  isInitialized: false,
  hasFailed: false,
  loadedData: {
    companies: false,
    customers: false,
    tenders: false,
    formats: false,
  },
  companies: [],
  customers: [],
  tenders: [],
  addresses: [],
  formats: [],
  breakfastProducts: {
    data: [],
    isLoading: false,
    isInitialized: false,
    hasFailed: false,
  },
  breakfastFormulas: {
    data: [],
    isLoading: false,
    isInitialized: false,
    hasFailed: false,
  },
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_MULTI_DEVIS:
      return {
        ...state,
        isLoading: true,
      };
    case INITIALIZE_MULTI_DEVIS_COMPLETED:
      return {
        ...state,
        isLoading: false,
        isInitialized: true,
        companies: action.payload.result.companies,
        customers: action.payload.result.customers,
        tenders: action.payload.result.tenders,
        formats: action.payload.result.formats,
        addresses: action.payload.result.addresses,
        loadedData: {
          ...state.loadedData,
          companies: true,
          customers: true,
          tenders: true,
          formats: true,
          addresses: true,
        },
      };
    case INITIALIZE_MULTI_DEVIS_FAILED:
      return {
        ...state,
        isLoading: false,
        isInitialized: true,
        hasFailed: true,
      };
    case GET_BREAKFAST_PRODUCTS:
      return {
        ...state,
        breakfastProducts : {
          ...state.breakfastProducts,
          isLoading: true,
        }
      };
    case GET_BREAKFAST_PRODUCTS_COMPLETED:
      return {
        ...state,
        breakfastProducts: {
          data: action.payload.result,
          isLoading: false,
          isInitialized: true,
        }
      };
    case GET_BREAKFAST_PRODUCTS_FAILED:
      return {
        ...state,
        breakfastProducts: {
          ...state.breakfastProducts,
          isLoading: false,
          isInitialized: true,
          hasFailed: true,
        }
      };
    case GET_BREAKFAST_FORMULAS:
      return {
        ...state,
        breakfastFormulas: {
          ...state.breakfastFormulas,
          isLoading: true,
        }
      };
    case GET_BREAKFAST_FORMULAS_COMPLETED:
      return {
        ...state,
        breakfastFormulas: {
          data: action.payload.result,
          isLoading: false,
          isInitialized: true,
        }
      };
    case GET_BREAKFAST_FORMULAS_FAILED:
      return {
        ...state,
        breakfastFormulas: {
          ...state.breakfastFormulas,
          isLoading: false,
          isInitialized: true,
          hasFailed: true,
        }
      };
    default:
      return state;
  }
};

export default reducers;
