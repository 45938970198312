import React from 'react'
import { css } from 'emotion'

const OrderTypeFilter = (props) => {

  const {
    orderType,
    handleChange
  } = props

  return (
    <select value={orderType} onChange={handleChange} className={css(styles.filterSelect)}>
      <option value="all">Tout type de commande</option>
      <option value='mealtray'>Plateaux-Repas</option>
      <option value='breakfast'>Petit déjeuner</option>
      <option value='caterer'>Traiteur</option>
    </select>
  )

}

const styles = {
  filterSelect: {
    background: 'none',
    border: 'thin solid #bbb',
    color: '#555',
    padding: '0.1rem 0.1rem',
  }
}

export default OrderTypeFilter
