import React, { useState, useEffect } from "react";
import { css } from "emotion";
import Order from "app/orderTool/pages/missingInformations/Order";
import { useOrders } from "app/orderTool/context/OrdersProvider";
import FakeDelivery from "app/deliveryTool/components/delivery/FakeDelivery";

const Orders = (props) => {

  const ordersResults = useOrders();

  const {
    orders
  } = ordersResults;

  const styles = {
    title: {
      color: "grey",
      fontSize: "1.0rem",
      marginTop: "1.0rem",
      textAlign: "right",
    },
    load: {
      background: "white",
      margin: "0.5rem 0",
      border: "1px solid #ddd",
      padding: "1.0rem",
      fontWeight: "normal",
    },
  };

  return (
    <div className={css(styles.scroll)}>
      {ordersResults.loading ? (
        <FakeDelivery />
      ) : (
        <div>
          {orders && orders.length > 0 ? (
            <div>
              {orders &&
                orders.map((order, index) => (
                  <Order order={order} key={index} handleMissingFieldsCustomerNotification={ordersResults.sendMissingFieldsCustomerNotification}/>
                ))}
            </div>
          ) : (
            <p className={css(styles.load)}>
              <b>Il n'y a pas de résultat pour cette recherche.</b>
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default Orders;
