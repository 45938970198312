import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFormat } from "state/orderEdition/actions";
import { Form, FormControl } from 'react-bootstrap';

const FormatSelectComponent = ({orderIndex}) => {
  const dispatch = useDispatch();
  const order = useSelector((state) => state.orderEdition.orders[orderIndex]);
  const formats = useSelector((state) =>
    state.adminData.formats ? state.adminData.formats : []
  );

  const selectedFormat = useSelector((state) => order?.format || "Choisir un format");

  const handleFormatSelect = (event) => {
    const format = formats.find(format => format === event.target.value);
    dispatch(setFormat(orderIndex, format));
  };

  return (
    <Form.Group controlId="formatSelect">
      <Form.Label>Format*</Form.Label>
      <FormControl as="select" value={selectedFormat} onChange={handleFormatSelect}>
        <option disabled value="Choisir un format">Choisir un format</option>
        {formats.map((format, key) => (
          <option key={key} value={format}>
            {format}
          </option>
        ))}
      </FormControl>
    </Form.Group>
  );
};

export default FormatSelectComponent;