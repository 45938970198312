import React from 'react'

import FiltersProvider from 'app/deliveryTool/context/FiltersProvider'
import RoadmapsProvider from 'app/deliveryTool/context/RoadmapsProvider'

const Providers = (props) => {

  const {
    children
  } = props

  return (
    <FiltersProvider>
      <RoadmapsProvider>
        {children}
      </RoadmapsProvider>
    </FiltersProvider>
  )
}



export default Providers
