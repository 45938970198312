import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { setDeliveryInfo as setDeliveryInfoState } from "state/orderEdition/actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import Select from "react-select";

const DeliveryInfoComponent = ({ title }) => {
  const dispatch = useDispatch();

  const deliveryInfoState = useSelector(
    (state) => state.orderEdition.deliveryInfo
  );
  const addresses = useSelector((state) => state.adminData.addresses);
  const [show, setShow] = useState(false);
  const [deliveryInfo, setDeliveryInfo] = useState({
    lastname: deliveryInfoState?.lastname || "",
    firstname: deliveryInfoState?.firstname || "",
    company: deliveryInfoState?.company || "",
    phone: deliveryInfoState?.phone || "",
    streetNumber: deliveryInfoState?.streetNumber || "",
    street: deliveryInfoState?.street || "",
    postalCode: deliveryInfoState?.postalCode || "",
    city: deliveryInfoState?.city || "",
    addressComplement: deliveryInfoState?.addressComplement || "",
  });

  const handleSelectAddressChange = (event) => {
    
    setSelectedOptions(event);
    
    const selectedAddress = addresses.find(
      (adresse) => adresse.id === Number(event.value)
    );
    if (selectedAddress) {
      setDeliveryInfo({
        ...deliveryInfo,
        streetNumber: selectedAddress?.street_number || "",
        street: selectedAddress?.street || "",
        postalCode: selectedAddress?.postal_code || "",
        city: selectedAddress?.city || "",
        addressComplement: selectedAddress?.address_complement || "",
      });
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    setDeliveryInfo({
      ...deliveryInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      setDeliveryInfoState({
        ...deliveryInfoState,
        ...deliveryInfo,
      })
    );
    handleClose();
  };

  // On format addresses pour react-select
  const addressesFormat = addresses.map((item) => ({ value: item.id, label: item.name_address, ...item }));
  const [optionSelected, setSelectedOptions] = useState([]);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Informations de livraison</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Carnet d'adresses</Form.Label>
              <Select
                options={addressesFormat}
                closeMenuOnSelect={true}
                onChange={handleSelectAddressChange}
                value={optionSelected}
                name={"address"}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Nom</Form.Label>
              <Form.Control
                type="text"
                name="lastname"
                value={deliveryInfo.lastname}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Prénom</Form.Label>
              <Form.Control
                type="text"
                name="firstname"
                value={deliveryInfo.firstname}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Entreprise</Form.Label>
              <Form.Control
                type="text"
                name="company"
                value={deliveryInfo.company}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Téléphone</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={deliveryInfo.phone}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Numéro de rue</Form.Label>
              <Form.Control
                type="text"
                name="streetNumber"
                value={deliveryInfo.streetNumber}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Rue</Form.Label>
              <Form.Control
                type="text"
                name="street"
                value={deliveryInfo.street}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Code Postal</Form.Label>
              <Form.Control
                type="text"
                name="postalCode"
                value={deliveryInfo.postalCode}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Ville</Form.Label>
              <Form.Control
                type="text"
                name="city"
                value={deliveryInfo.city}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Complément d'adresse</Form.Label>
              <Form.Control
                type="text"
                name="addressComplement"
                value={deliveryInfo.addressComplement}
                onChange={handleChange}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Enregistrer
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <>
        <Row>
          <Col xs={7} sm={7} md={7}>
            <h5>{title}</h5>
          </Col>
          <Col xs={5} sm={5} md={5} className="text-right">
            <Button variant="success" size="sm" onClick={handleShow}>
              <FontAwesomeIcon icon={faPen} />
            </Button>
          </Col>
        </Row>
        
        <p>
          {deliveryInfo.lastname || "Nom"}{" "}
          {deliveryInfo.firstname || "Prénom"}
          <br />
          {deliveryInfo.phone || "Tel."}
          <br />
          {deliveryInfo.streetNumber || "Num."} {deliveryInfo.street || "Rue"}
          <br />
          {deliveryInfo.postalCode || "Code postal"}{" "}
          {deliveryInfo.city || "Ville"}
          <br />
          {deliveryInfo.addressComplement || "Complém."}
        </p>
        
      </>
    </>
  );
};

export default DeliveryInfoComponent;
