import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment'
import {Container, Row, Col} from 'react-bootstrap'
import { css } from 'emotion'

import UpdateDataApi from 'app/universal/utils/UpdateDataApi'

const Order = (props) => {

  const {
    order, 
    handleMissingFieldsCustomerNotification
  } = props;

  const currentUrlEncode = encodeURIComponent(window.location.href);

  const themeColor = () => {
    if(order.info.order_type === 'mealtray'){
      return '#a18b6e'
    }
    else if(order.info.order_type === 'caterer'){
      return 'black'
    }
    else if(order.info.order_type === 'breakfast'){
      return '#439b5c'
    }
    else if(order.info.order_type === 'v2'){
      return '#2b7ed6'
    }
  } 
  
  const styles = {
    delivery: {
      background: 'white',
      margin: '0.5rem 0',
      border: '1px solid #ddd',
      borderLeft: '10px solid '+themeColor()+'',
      transition: '300ms'
    },
    statut: {
      textTransform: 'uppercase',
      fontSize: '0.6rem',
      marginBottom: '0rem'
    },
    col:{
      padding: '0.5rem',
      borderRight: '1px #dedede solid',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    verticalAlign:{
      display: 'flex',
      flexDirection: 'row',
    },
    para:{
      marginBottom: '0rem',
      fontSize: '0.8rem',
      lineHeight: '1.3'
    },
    center:{
      textAlign: 'center',
      '@media (max-width: 767px)':{
        textAlign: 'left'
      }
    },
    divisor: {
      height: '1px',
      backgroundColor: '#dedede',
      margin: '0.7rem 0'
    },
    borderRight: {
      '@media (max-width: 767px)':{
        borderRight: '1px #dedede solid',
      }
    },
    mobileDivisor: {
      '@media (max-width: 767px)':{
        display: 'none'
      }
    },
    borderBottomMobile:{
      '@media (max-width: 767px)':{
        borderBottom: '1px #dedede solid',
      }
    },
    borderTopMobile:{
      '@media (max-width: 767px)':{
        borderTop: '1px #dedede solid',
      }
    },
    company:{
      textTransform: 'uppercase',
      color: themeColor(),
      fontWeight: 'bold',
      fontSize: '0.8rem',
      margin: '0'
    },
    label2Col:{
      width: '80%',
      display:'inline-block',
      margin:'auto'
    },
    checkbox2Col:{
      width: '20%',
      display:'inline-block',
      borderLeft: '1px solid #dedede',
      margin:'auto'
    },
    button:{
      width: '100%',
      textAlign: 'center',
      cursor: "pointer",
      fontSize: '0.8rem',
      transition: '300ms',
      display: 'block',
      padding: '10px 4px',
      ':hover':{
        textDecoration: 'none',
        opacity: 0.7,
      }
    },
    theme:{
      backgroundColor: themeColor(),
      color: "white !important"
    },
    grey:{
      backgroundColor: '#d7d7d7',
      color: 'black',
    },
    red:{
      backgroundColor: '#cc0000',
      color: 'white',
    },
    lightGrey:{
      backgroundColor: '#ebebeb',
      color: 'black',
    },
    black:{
      backgroundColor: '#000',
      color: 'white',
    },
    ultraLightGrey:{
      backgroundColor: '#f4f4f4',
      color: 'black',
    },
    action:{
      padding: '0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    fixed:{
      position: 'absolute',
      right: '0',
      top: '0'
    },
    buttonToggle:{
      borderRadius: '0px !important',
      padding: '.25rem .5rem',
      border: 'none',
      borderLeft: '1px solid #cdcdcd',
      backgroundColor: '#f4f4f4',
      color: 'black',
      '::after':{
        display: 'inline-block',
        marginLeft: '.255em',
        verticalAlign: '0',
        content: '"···"',
        border: 'none'
      }
    },
    startTitle:{
      textTransform: 'uppercase',
      fontSize: '0.6rem',
      marginTop: '1rem'
    }
  }

  return (

    <Container className={css(styles.delivery)} fluid>
      <Row className={css(styles.verticalAlign)}>
        <Col xs={12} md={2} className={css(styles.col, styles.borderBottomMobile)}>
          <small></small>
          <p className={css(styles.company)}>{order.company && order.company.name}<br/><i>{order.firstname} {order.lastname}</i></p>
          <p className={css(styles.para)}>
            {order.info.orderNumber}<br/>{order.info.vosFacturesNumber}
          </p>
        </Col>
        <Col xs={6} md={2} className={css(styles.col)}>
          <p className={css(styles.para, styles.center)}>{moment(order.delivery.delivery_date).format('DD/MM/YYYY')}</p>
          <div className={css(styles.divisor, styles.mobileDivisor)}></div>
          <p className={css(styles.para, styles.center)}>{order.format}</p>
        </Col>
        <Col xs={6} md={6} className={css(styles.col)}>
          <Row>
            {order.missing_fields && order.missing_fields.map((input, index) =>
              <Col xs={6} md={4}>
                <div className={css(styles.para)} key={index} >
                  <span className={css(styles.startTitle)}>{input.frName}</span><br/>
                  <UpdateDataApi
                    id={order.id} 
                    typeFormat="stringify" 
                    name={input.reactLivraisonName} 
                    api={order.info.order_type} 
                    input={input.type} 
                    handler={() => {}}
                  />
                </div> 
              </Col>      
            )}
          </Row>
          
          
        </Col>
        
        <Col xs={12} md={2} className={css(styles.action)}>
          <a className={css(styles.button, styles.theme)} onClick={() => window.confirm("Etes-vous sure d'envoyer une relance à ce client ?") && handleMissingFieldsCustomerNotification(order.id, order.info.order_type)}>
              {order.notification && order.notification.reminder_missing_field_mail_date && <small><i>(Relancer le {moment(order.notification.reminder_missing_field_mail_date).format('DD/MM/YYYY')})</i><br/></small>}
              Relancer
          </a>
          <Link to={{
              pathname: `${order.info.quotation_url}`,
            }} className={css(styles.button, styles.lightGrey)} target="_blank">
                Voir le devis prod
              </Link>
          <Link target="_parent" to={{
              pathname: `${order.info.edit_url}?redirect_url_encode=${currentUrlEncode}`,
            }} className={css(styles.button, styles.grey)} target="_blank">Modifier devis</Link>
        </Col>
      </Row>
    </Container>

  )

}



Order.propTypes = {
  order: PropTypes.object
}

export default Order
