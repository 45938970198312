import React from 'react'
import ReactDOM from 'react-dom'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import MissingInformations from 'app/orderTool/pages/missingInformations/MissingInformations';
import ManageOrders from 'app/orderTool/pages/manageOrders/ManageOrders';
import Agenda from 'app/deliveryTool/pages/agenda/Agenda.js';
import Planning from 'app/deliveryTool/pages/planning/Planning';
import Itineraires from 'app/deliveryTool/pages/itineraire/Itineraires';
import PrintPlanning from 'app/deliveryTool/pages/printPlanning/PrintPlanning';
import PublicPlanning from 'app/deliveryTool/pages/publicPlanning/PublicPlanning';
import CardProdMultipole from 'app/orderTool/pages/cardProdMultipole/CardProdMultipole';
import CardProdMultipoleV2 from 'app/orderTool/pages/cardProdMultipoleV2/CardProdMultipoleV2';
import Anticipation from 'app/orderTool/pages/anticipation/Anticipation';
import MultiOrders from 'app/orderTool/pages/multiOrders/MultiOrders';
import NotFound404 from 'app/universal/404/NotFound404';
 // eslint-disable-next-line
import Global from 'app/universal/styles/Global';
import * as serviceWorker from './serviceWorker';
import WindowDimensionsProvider from 'app/universal/utils/WindowDimensionsProvider'
import ApiAuthProvider from 'app/universal/context/ApiAuthProvider'
import ErrorBoundary from 'app/universal/errors/ErrorBoundary'
import { Provider } from 'react-redux';
import { store, persistor } from 'state/store';
import { PersistGate } from 'redux-persist/integration/react';

const routing = (
  <WindowDimensionsProvider>
    <ErrorBoundary>
      <ApiAuthProvider>
        <Router>
          <Switch>
          <Route exact path="/informations-devis-manquantes" component={MissingInformations} />
          <Route path="/planning" component={Planning} />
          <Route exact path="/itineraires/" component={Itineraires} />
          <Route path="/itineraires/:id" component={Itineraires} />
          <Route path="/impression-planning" component={PrintPlanning} />
          <Route path="/impression-planning-public" component={PublicPlanning}/>
          <Route path="/agenda" component={Agenda} />
          <Route path="/fiche-production-multipole" component={CardProdMultipole} />
          <Route path="/fiche-production-multipole-v2" component={CardProdMultipoleV2} />
          <Route path="/anticipation" component={Anticipation} />
          <Provider store={store}>
              <Route exact path="/" component={ManageOrders} />
              <PersistGate loading={null} persistor={persistor}>
                <Route path="/commande" component={MultiOrders} />
              </PersistGate>
            </Provider>
            
            <Route component={NotFound404} />
          </Switch>
        </Router>
      </ApiAuthProvider>
    </ErrorBoundary>
  </WindowDimensionsProvider>
)

ReactDOM.render(routing, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
