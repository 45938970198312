import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import 'rc-time-picker/assets/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const DateInput = (props) => {

  const {
    value,
    handleChange,
    isLoading,
    noFutur
  } = props

  const [data, setData] = useState(() => { return (value === undefined || value === null) ? null : new Date(value); })

  const onChange = (val) => {
    setData((val === undefined || val === null) ? null : val);
    handleChange((val === undefined || val === null) ? null : moment(val).format('YYYY-MM-DD'));
  }

  return (
    <>
      <DatePicker
        dateFormat="dd/MM/yyyy"
        onChange={onChange}
        selected={data}
        readOnly={isLoading}
        maxDate={noFutur == true && new Date()}
      /> {isLoading && <FontAwesomeIcon icon={faSpinner} pulse/>}
    </>
  )

}

DateInput.propTypes = {
  value       : PropTypes.string,
  handleChange: PropTypes.func
}

export default DateInput
