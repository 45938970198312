import React, {useState}  from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import { css } from 'emotion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint, faPaperPlane } from '@fortawesome/free-solid-svg-icons'

import Providers from 'app/deliveryTool/context/Providers'
import RoadMaps from 'app/deliveryTool/pages/printPlanning/RoadMaps'
import Filters from 'app/deliveryTool/components/filters/Filters'
import Layout from 'app/universal/layout/Layout'
import LeMoulinApi from 'app/universal/utils/LeMoulinApi'

const PrintPlanning = (props) => {

  const disableFilters = {orderType:1, deliveryType:1};
  const [isLoading, setIsLoading] = useState(false);

  const sendRoadmapsOnWorkchat = async () => {
    setIsLoading(true);
    let queryString   = require('query-string')
    let query         = queryString.parse(window.location.search);
    let deliveryDate  = query.date ? query.date : null;
    let deliveryManId = query.deliveryman ? query.deliveryman : null;
    await LeMoulinApi.request.deliveryMan.sendRoadmapsOnWorkchat(deliveryDate, deliveryManId);
    setIsLoading(false);
  }

  return (
    <Providers>
      <Layout>
        <Container fluid={true} >
          <Row className='no-print'> 
            <Col xs={12} sm={8} className={css(styles.filterBlock)}>
              <Filters disableFilters={disableFilters} />
            </Col>
            <Col xs={12} sm={4} className={css(styles.action)}>
              {isLoading ? 
                <div className={css(styles.printDisable)}><FontAwesomeIcon icon={faPaperPlane} /> Envoi en cours ...</div>
              :
                <div className={css(styles.print)} onClick={() => sendRoadmapsOnWorkchat()}><FontAwesomeIcon icon={faPaperPlane} /> Envoyer aux livreurs</div>
              }
              <div className={css(styles.print)} onClick={() => window.print()}><FontAwesomeIcon icon={faPrint} /> Imprimer</div>
            </Col>
          </Row>
        <RoadMaps router={props.history}/>
        </Container>
      </Layout>
    </Providers>
  )

}

const styles = {
  filterBlock:{
    'borderBottom': '1px #ddd solid',
    'padding': '10px 10px 10px 10px',
    "@media (max-width: 767px)": {
      'textAlign': 'center'
    }
  },
  action: {
    'borderBottom': '1px #ddd solid',
    'padding': '10px 10px 10px 10px',
    'textAlign': 'right',
    "@media (max-width: 767px)": {
      'textAlign': 'center'
    }
  },
  print: {
    display:'inline-block',
    border: 'thin solid #bbb',
    color: '#555',
    cursor: 'pointer',
    padding: '0.2rem 1rem',
    transition: '300ms',
    ':hover': {
      textDecoration: 'none',
      color: 'white',
      backgroundColor: '#bbb',
      transition: '300ms'
    }
  },
  printDisable: {
    display:'inline-block',
    border: 'thin solid #bbb',
    color: '#555',
    padding: '0.2rem 1rem',
    transition: '300ms',
    opacity: 0.5
  }

}

export default PrintPlanning
