export const RESET_ORDER_EDITION = "RESET_ORDER_EDITION";
export const INITIALIZE_ORDER_EDITION = "INITIALIZE_ORDER_EDITION";
export const SET_ORDER_EDITION_INFO = "SET_ORDER_EDITION_INFO";
export const SET_DELIVERY_INFO = "SET_DELIVERY_INFO";
export const SET_BILLING_INFO = "SET_BILLING_INFO";
export const SET_CUSTOMER = "SET_CUSTOMER";
export const SET_COMPANY = "SET_COMPANY";
export const SET_TENDER = "SET_TENDER";
export const SET_CONFIRM_TENDER = "SET_CONFIRM_TENDER";
export const SET_FORMAT = "SET_FORMAT";
export const UPDATE_ORDER_ITEMS = "UPDATE_ORDER_ITEMS";
export const UPDATE_REDUCTION = "UPDATE_REDUCTION";
export const UPDATE_DELIVERY_COST = "UPDATE_DELIVERY_COST";
export const ADD_ORDER = "ADD_ORDER";
export const SET_CURRENT_ORDER_INDEX = "SET_CURRENT_ORDER_INDEX";
export const REMOVE_ORDER = "REMOVE_ORDER";
export const SET_ORDER_STATUS = "SET_ORDER_STATUS";
export const SET_ORDER_DELIVERY_INFO = "SET_ORDER_DELIVERY_INFO";
export const FETCH_ORDER = "FETCH_ORDER";
export const FETCH_ORDER_COMPLETED = "FETCH_ORDER_COMPLETED";
export const FETCH_ORDER_FAILED = "FETCH_ORDER_FAILED";
export const SAVE_ORDER = "SAVE_ORDER";
export const SAVE_ORDER_COMPLETED = "SAVE_ORDER_COMPLETED";
export const SAVE_ORDER_FAILED = "SAVE_ORDER_FAILED";
export const SET_ORDER_INFO = "SET_ORDER_INFO";

export const resetOrderEdition = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_ORDER_EDITION,
    });
  };
};

export const initializeOrderEdition = () => {
  return (dispatch) => {
    dispatch({
      type: INITIALIZE_ORDER_EDITION,
    });
  };
};

export const setOrderEditionInfo = (orderEditionInfo) => {
  return (dispatch) => {
    dispatch({
      type: SET_ORDER_EDITION_INFO,
      payload: orderEditionInfo,
    });
  };
};

export const setDeliveryInfo = (deliveryInfo) => {
  return (dispatch) => {
    dispatch({
      type: SET_DELIVERY_INFO,
      payload: deliveryInfo,
    });
  };
};

export const setBillingInfo = (billingInfo) => {
  return (dispatch) => {
    dispatch({
      type: SET_BILLING_INFO,
      payload: billingInfo,
    });
  };
};

export const setCustomer = (customer) => {
  return (dispatch) => {
    dispatch({
      type: SET_CUSTOMER,
      payload: customer,
    });
  };
};

export const setCompany = (company) => {
  return (dispatch) => {
    dispatch({
      type: SET_COMPANY,
      payload: company,
    });
  };
};

export const setTender = (tender) => {
  return (dispatch) => {
    dispatch({
      type: SET_TENDER,
      payload: tender,
    });
  };
};

export const setConfirmTender = (confirmBool) => {
  return (dispatch) => {
    dispatch({
      type: SET_CONFIRM_TENDER,
      payload: confirmBool,
    });
  };
};

export const setFormat = (orderIndex, formatTxt) => {
  return (dispatch) => {
    dispatch({
      type: SET_FORMAT,
      payload: {
        orderIndex: orderIndex,
        formatTxt: formatTxt,
      },
    });
  };
};

export const saveOrder = (data, orderIndex, orderId) => ({
  type: SAVE_ORDER,
  meta: {
    async: true,
    blocking: true,
    path: orderId ? `/api/pro/breakfasts/${orderId}.json` : `/api/pro/breakfasts.json`,
    method: orderId ? "PUT" : "POST",
    body: data,
    payload: {
      orderIndex: orderIndex,
    }
  },
});

export const updateOrderItems = (orderIndex, items, itemType) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ORDER_ITEMS,
      payload: {
        orderIndex: orderIndex,
        items: items,
        itemType: itemType,
      },
    });
  };
};

export const updateReduction = (orderIndex, reduction) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_REDUCTION,
      payload: {
        orderIndex: orderIndex,
        reduction: reduction,
      },
    });
  };
};

export const updateDeliveryCost = (orderIndex, cost) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DELIVERY_COST,
      payload: {
        orderIndex: orderIndex,
        cost: cost,
      },
    });
  };
};

export const addOrder = () => {
  return (dispatch) => {
    dispatch({
      type: ADD_ORDER,
    });
  };
};

export const setCurrentOrderIndex = (orderIndex) => {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_ORDER_INDEX,
      payload: orderIndex,
    });
  };
};

export const removeOrder = (orderIndex) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_ORDER,
      payload: orderIndex,
    });
  };
};

export const setOrderStatus = (orderIndex, status) => {
  return (dispatch) => {
    dispatch({
      type: SET_ORDER_STATUS,
      payload: {
        orderIndex: orderIndex,
        status: status,
      },
    });
  };
};

export const setOrderDeliveryInfo = (orderIndex, deliveryInfo) => {
  return (dispatch) => {
    dispatch({
      type: SET_ORDER_DELIVERY_INFO,
      payload: {
        orderIndex: orderIndex,
        deliveryInfo: deliveryInfo,
      },
    });
  };
};

export const fetchOrder = (orderId, orderType) => ({
  type: FETCH_ORDER,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/${orderType}s/${orderId}.json`,
    method: "GET"
  },
});

export const setOrderInfo = (orderIndex, order) => {
  return (dispatch) => {
    dispatch({
      type: SET_ORDER_INFO,
      payload: {
        orderIndex: orderIndex,
        order: order,
      },
    });
  };
};