import moment from 'moment'

const queryString = require('query-string')

// Initialisation des filtre date
export const initDate = (date, nullByDefault) => {
  const query = queryString.parse(window.location.search);

  if(query[date] === undefined ){
    // 19/04/22
    // Retourne la date du jour par défaut et non plus null
    if (nullByDefault === true) {
      return;
    }
    var currentDate = new Date();
    return moment(currentDate).format("Y-M-D");
  }

  return query[date];
}

// Initialisation des filtres pour des valeurs standard
export const initValue = (type, value) => {
  const query = queryString.parse(window.location.search);

  if(query[type] === undefined ){
    return value
  }

  return query[type];
}

// Initialisation des filtres pour des valeurs standard
export const initNumber = (type, value) => {
  const query = queryString.parse(window.location.search);

  if(query[type] === undefined ){
    return parseInt(value)
  }

  return parseInt(query[type]);
}

// Initialisation des filtres pour des Boolean
export const initBoolean = (type, value) => {
  const query = queryString.parse(window.location.search);

  if(query[type] === undefined){
    return false;
  }
  if(query[type] === 'false'){
    return false;
  }

  return true;
}

export const initSelectedOrders = () => {
  const query = queryString.parse(window.location.search);

  // Convertir la chaîne de caractères en tableau d'objets
  const selectedOrdersString = query['selectedOrders'];
  if (selectedOrdersString === undefined || selectedOrdersString === '') {
    return [];
  }

  try {
    const selectedOrdersArray = JSON.parse(decodeURIComponent(selectedOrdersString));
    return selectedOrdersArray;
  } catch (error) {
    console.error('Error parsing selectedOrders:', error);
    return [];
  }
}

// Formate les dates récuperer pour l'api sous la forme DATESTART,DATEEND
export const rangeDate = (start,end) => {

  if(start && end && start !== '' && end !== ''){
    return start+','+end
  }
  return;
}

//  Retourne les bons codes selon les status
export const statusCode = (value) => {
  if(value === 'all_valid'){
    return ['101', '102', '103']
  }
  if(value === 'waiting'){
    return ['101']
  }
  if(value === 'confirmed'){
    return ['102']
  }
  if(value === 'billable'){
    return ['103']
  }
  if(value === 'refused'){
    return ['400', '401', '402', '411', '412']
  }
  if(value === 'all_invoice'){
    return ['201', '203']
  }
  if(value === 'to_paid'){
    return ['201']
  }
  if(value === 'paid'){
    return ['203']
  }

  return ['101', '102']
}

