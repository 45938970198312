import React from 'react'
import { css } from 'emotion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const Text = (props) => {

  const {
    label,
    value,
    placeholder,
    handleChange,
    isLoading
  } = props

  return (

    <div>
      {label ?
        <label>
          {label}
          <input type="text" onBlur={handleChange} className="form-control" defaultValue={value} placeholder={placeholder} />
        </label>
      :
        <input type="text" onBlur={handleChange} className="form-control" defaultValue={value} placeholder={placeholder} />
      }
      {isLoading && <FontAwesomeIcon icon={faSpinner} pulse/>}
    </div>

  )

}

const styles = {
  textarea:{
    padding: '0 0.5rem',
    fontSize: '0.8rem',
    border: 'none',
    width: '100%',
    background: 'none',
    color: 'inherit',
    ':focus':{
      outline : '0'
    },
    '&:hover': {
      backgroundColor: 'rgba(66, 152, 83, 0.2)',
      transition: '300ms'
    }
  }
}
export default Text
