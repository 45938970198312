import React from 'react'
import { css } from 'emotion'
import { useRoadmaps } from 'app/deliveryTool/context/RoadmapsProvider'

/**
 * 02/23 - Ajout de defaultValue et hideAll pour l'utiliser pour le changement de livreur dans une tournée
 */
const CreateRoadmap = (props) => {

  const {
    handleChange,
    defaultDeliveryMenId,
    hideAll
  } = props

  const rmData = useRoadmaps();

  return (
    <select className={css(styles.filterSelect)} onChange={handleChange} defaultValue={defaultDeliveryMenId}>
      {hideAll !== true && <option value="all">Créer un itineraire pour ...</option>}
      {rmData.deliveryMen.map((dm, index) =>
        <option value={dm.id} key={dm.id}>{dm.firstname}</option>
      )}
    </select>
  )

}

const styles = {
  filterSelect: {
    backgroundColor: '#429853',
    borderRadius: '4px',
    border: 'none',
    color: 'white',
    padding: '0.5rem 0.5rem',
    margin: '1.0rem 0'
  }
}

export default CreateRoadmap
