import React from 'react'
import { css } from 'emotion'

const DeliveryManFilter = (props) => {

  const {
    deliveryMan,
    handleChange,
    deliveryMen
  } = props

  return (
    <select value={deliveryMan} onChange={handleChange} className={css(styles.filterSelect)}>
      <option value="all">Tous les livreurs</option>
      {deliveryMen && deliveryMen.map((dm, index) =>
        <option value={dm.id} key={dm.id}>{dm.firstname}</option>
      )}
    </select>
  )

}

const styles = {
  filterSelect: {
    background: 'none',
    border: 'thin solid #bbb',
    color: '#555',
    padding: '0.1rem 0.1rem',
  }
}

export default DeliveryManFilter
