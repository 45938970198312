import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autosuggest from "react-autosuggest";
import { setCustomer, setCompany, setBillingInfo } from "state/orderEdition/actions";

const CustomerSelectComponent = () => {
  const dispatch = useDispatch();
  const customers = useSelector((state) =>
    state.adminData.customers ? state.adminData.customers : []
  );
  const companies = useSelector((state) =>
    state.adminData.companies ? state.adminData.companies : []
  );
  const billingInfo = useSelector(
    (state) => state.orderEdition.billingInfo
  );
  const selectedCustomer = useSelector((state) => state.orderEdition.customer);

  const [value, setValue] = useState(selectedCustomer?.lastname || "");
  const [suggestions, setSuggestions] = useState([]);

  // Dispatch également l'entreprise et les infos de facturation
  const handleCustomerSelect = (customer) => {
    dispatch(setCustomer(customer));
    let company = companies.find((company) => company.id === customer.company_id);
    if (company) {
      dispatch(setCompany(company));
    }
    if (customer) {
      dispatch(setBillingInfo({
        ...billingInfo,
        lastname: customer?.lastname,
        firstname: customer?.firstname,
        phone: customer?.phone,
        streetNumber: customer?.street_number,
        street: customer?.street,
        postalCode: customer?.postal_code,
        city: customer?.city,
        addressComplement: customer?.address_complement,
      }))
    }
  };

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : customers.filter((customer) =>
          `${customer.lastname}`.toLowerCase().includes(inputValue) ||
          `${customer.firstname}`.toLowerCase().includes(inputValue) ||
          `${customer.email}`.toLowerCase().includes(inputValue)
        );
  };

  const getSuggestionValue = (suggestion) =>
    `${suggestion.lastname} ${suggestion.firstname}`;

  const renderSuggestion = (suggestion) => (
    <div>{`${suggestion.lastname} ${suggestion.firstname} ${suggestion.email} (ID: ${suggestion.id})`}</div>
  );

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: "Recherche de client...",
    value,
    onChange: (event, { newValue }) => {
      setValue(newValue);
    },
  };

  const theme = {
    input: {
      display: "block",
      width: "100%",
      height: "calc(1.5em + .75rem + 2px)",
      padding: ".375rem .75rem",
      fontSize: "1rem",
      fontWeight: "400",
      lineHeight: "1.5",
      color: "#495057",
      backgroundColor: "#fff",
      backgroundClip: "padding-box",
      border: "1px solid #ced4da",
      borderRadius: ".25rem",
      transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
    },
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      onSuggestionSelected={(event, { suggestion }) =>
        handleCustomerSelect(suggestion)
      }
      theme={theme}
    />
  );
};

export default CustomerSelectComponent;
