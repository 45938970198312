import React, { useState, useEffect }  from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import { css } from 'emotion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import Helmet from 'react-helmet';
import moment from 'moment';

import LeMoulinApi from 'app/universal/utils/LeMoulinApi'

import FakeDelivery from 'app/deliveryTool/components/delivery/FakeDelivery'
import Composition from 'app/orderTool/pages/cardProdMultipole/Composition'

// A SUPPRIMER PLUS UTILISE
const CardProdMultipole = (props) => {

  // Parametre pour la requete API (date & status)
  const queryString = require('query-string')

  const prm = queryString.parse(window.location.search);

  const [filter] = useState({
    startDate: prm.startDate,
    endDate: prm.endDate,
    status: prm.status
  })

  const [dataCompositions, setDataCompositions]   = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    setLoading(true);

    const fetchDataCompositions = async () => {

      const dataCompositionsResult = await LeMoulinApi.request.order.compositionSheets.gets({dates: {start: filter.startDate, end: filter.endDate, status: filter.format }})
      setDataCompositions(dataCompositionsResult)

      setLoading(false);
    }

    fetchDataCompositions()

  }, [filter]);

  return (
    <div>
      <Helmet bodyAttributes={{style: 'background-color : #4d4c4c'}}/>

      <div className={css(styles.print)} onClick={ () => {window.print()}}><FontAwesomeIcon icon={faPrint} /> </div>

      {loading ?
         <Container>
          <FakeDelivery />
         </Container>
        :
        <Container>
          <Row className={css(styles.sheet)}>

            <Col xs={12} sm={12} md={12}>
              <h1 className={css(styles.title)}>Production multipôles du {moment(filter.startDate).format('DD-MM-YYYY')} au {moment(filter.endDate).format('DD-MM-YYYY')}</h1>
              <h2 className={css(styles.part)}>Produits finis</h2>
            </Col>

            <Col xs={12} sm={12} md={12}>
              {dataCompositions.products && Object.keys(dataCompositions.products).map((category) =>
                <Row key={category}>

                  <Col xs={12} sm={12} md={12}>
                    <h3 className={css(styles.typeProduct)}>{category}</h3>
                    <Row>
                      {dataCompositions.products && dataCompositions.products[category].map((product, index) =>
                        <Col xs={12} sm={6} md={6} key={'product'+index}>
                          <Row>
                            <Col xs={12} sm={8} md={8} className={css(styles.product)}>
                              {product.name}
                            </Col>
                            <Col xs={12} sm={8} md={4} className={css(styles.border, styles.quantity)}>
                              {product.total.toFixed(0)} {product.unit}
                            </Col>
                          </Row>
                          <hr className={css(styles.hr)}/>
                        </Col>
                      )}
                      </Row>
                    </Col>
                  </Row>

              )}
              {dataCompositions.products && dataCompositions.products.length === 0 ? <Col xs={12} sm={12} md={12}><p className={css(styles.center)}>Aucun produit pour cette période</p></Col> : null }
            </Col>

          </Row>

          <Row className={css(styles.sheet)}>

            <Col xs={12} sm={12} md={12}>
              <h1 className={css(styles.title)}>Production multipôles du {moment(filter.startDate).format('DD-MM-YYYY')} au {moment(filter.endDate).format('DD-MM-YYYY')}</h1>
              <h2 className={css(styles.part)}>Fiche recette</h2>
            </Col>

            <Col xs={12} sm={12} md={12}>
              {dataCompositions.products && Object.keys(dataCompositions.products).map((category) =>
                <Row key={category}>

                  <Col xs={12} sm={12} md={12}>
                    <h3 className={css(styles.typeProduct)}>{category}</h3>
                    <Row>
                      {dataCompositions.products && dataCompositions.products[category].map((product, index) =>
                        product.show &&
                        <Col xs={12} sm={6} md={6} key={'product'+index}>
                          <Row>
                            <Col xs={12} sm={8} md={8} className={css(styles.product)}>
                              {product.name}
                            </Col>
                            <Col xs={12} sm={8} md={4} className={css(styles.border, styles.quantity)}>
                              {product.total.toFixed(0)} {product.unit}
                            </Col>
                            <Col xs={12}>
                              <div className={css(styles.recipProduct)}>
                                <Composition dataCompositions={product.composition} col={12} size={0.8}/>
                              </div>
                            </Col>
                          </Row>
                          <hr className={css(styles.hr)}/>
                        </Col>
                      )}
                      </Row>
                    </Col>
                  </Row>

              )}
              {dataCompositions.products && dataCompositions.products.length === 0 ? <Col xs={12} sm={12} md={12}><p className={css(styles.center)}>Aucun produit pour cette période</p></Col> : null }
            </Col>

          </Row>

          <Row className={css(styles.sheet)}>

            <Col xs={12} sm={12} md={12}>
              <h1 className={css(styles.title)}>Production multipôles du {moment(filter.startDate).format('DD-MM-YYYY')} au {moment(filter.endDate).format('DD-MM-YYYY')}</h1>
              <h2 className={css(styles.part)}>Sous recettes</h2>
            </Col>
            <Col xs={12} sm={12} md={12}>

              {dataCompositions.recipes && Object.keys(dataCompositions.recipes).map((category) =>
                <Row key={category}>
                  <Col xs={12} sm={12} md={12}>
                    <h3 className={css(styles.typeProduct)}>{category}</h3>
                    <Composition dataCompositions={dataCompositions.recipes[category]} col={6} size={1}/>
                  </Col>
                </Row>
              )}

              {dataCompositions.ingredients && Object.keys(dataCompositions.ingredients).map((category) =>
                <Row key={category}>
                  <Col xs={12} sm={12} md={12}>
                    <h3 className={css(styles.typeProduct)}>{category}</h3>
                    <Composition dataCompositions={dataCompositions.ingredients[category]} col={6} size={1}/>
                  </Col>
                </Row>
              )}


              {dataCompositions.recipes && dataCompositions.recipes.length === 0 ? <Col xs={12} sm={12} md={12}><p className={css(styles.center)}>Aucun produit pour cette période</p></Col> : null }

            </Col>

          </Row>

          <Row className={css(styles.sheet)}>
            <Col xs={12} sm={12} md={12}>
              <h1 className={css(styles.title)}>Production multipôles du {moment(filter.startDate).format('DD-MM-YYYY')} au {moment(filter.endDate).format('DD-MM-YYYY')}</h1>
              <h2 className={css(styles.part)}>Produits par fournisseur</h2>
            </Col>
            <Col xs={12} sm={12} md={12}>
              {dataCompositions.providers && Object.keys(dataCompositions.providers).map((provider) =>
                <Row key={provider}>
                  <Col xs={12} sm={12} md={12}>
                    <h3 className={css(styles.typeProduct)}>{provider}</h3>
                    <Row>
                    {dataCompositions.providers[provider].map((rawProducts, index) =>
                      <Col xs={12} sm={6} md={6} key={'rawProduct'+index}>
                        <Row>
                          <Col xs={12} sm={8} md={8} className={css(styles.product)}>
                            {rawProducts.name}
                          </Col>
                          <Col xs={12} sm={8} md={4} className={css(styles.quantity)}>
                            {rawProducts.total.toFixed(4)} {rawProducts.unit}
                          </Col>
                        </Row>
                        <hr className={css(styles.hr)}/>
                      </Col>
                    )}
                    </Row>

                  </Col>

                </Row>
              )}
              {dataCompositions && dataCompositions.length === 0 ? <Col xs={12} sm={12} md={12}><p className={css(styles.center)}>Aucun produit pour cette période</p></Col> : null }
            </Col>
          </Row>

        </Container>
      }
    </div>
  );

}


const styles = {
  title:{
    color: '#b64e4e',
    textAlign: 'center',
    fontSize: '1.6rem'
  },
  product:{
    color:'black',
    fontWeight: 'bold'
  },
  quantity:{
    textAlign:'right'
  },
  part:{
    color: '#bbb',
    textAlign: 'center',
    fontSize: '1.4rem',
  },
  sheet:{
    marginTop: '1.0rem',
    backgroundColor: 'white',
    paddingTop:'1.0rem',
    paddingBottom: '1.0rem',
    pageBreakAfter: 'always'
  },
  typeProduct:{
    fontSize: '1.2rem',
    backgroundColor: '#429853',
    color: 'white',
    padding: '5px',
    display: 'inline-block',
    marginTop: '1.0rem',
    '@media print': {
        WebkitPrintColorAdjust: 'exact'
    }
  },
  hr: {
    marginTop: '0.1rem',
    marginBottom: '0.1rem'
  },
  pageBreak:{
    pageBreakAfter: 'always'
  },
  date:{
    background: 'none',
    border: 'thin solid #bbb',
    color: '#555',
    padding: '0.04rem 0.4rem'
  },
  filter:{
    borderBottom: '1px #ddd solid',
    padding: '10px 10px 10px 10px',
  },
  action: {
    'borderBottom': '1px #ddd solid',
    'padding': '10px 10px 10px 10px',
    'textAlign': 'right'
  },
  center:{
    textAlign: 'center'
  },
  recipProduct: {
    padding: '0 0 0 1.0rem',
    color: 'grey !important',
    fontStyle: 'italic'
  },
  print: {
    cursor: 'pointer',
    backgroundColor: 'white',
    position:'fixed',
    top: '5%',
    width: '60px',
    height: '60px',
    paddingTop:'9px',
    right: '5%',
    borderRadius: '50%',
    border: 'none',
    color: '#555',
    fontSize: '1.5rem',
    textAlign: 'center',
    padding: '0.6rem 0.2rem',
    transition: '300ms',
    boxShadow: '2px 2px 10px #ccc',
    ':hover': {
      textDecoration: 'none',
      color: 'white',
      backgroundColor: '#bbb',
      transition: '300ms'
    }
  }
}

export default CardProdMultipole
