import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Card, Button, Dropdown, Modal, Col } from "react-bootstrap";
import { centimesToCurrency } from "helper/globalHelper";
import { breakfastOrderStoreToApi } from "helper/transformDataHelper";
import { saveOrder, setCurrentOrderIndex } from "state/orderEdition/actions";
import BreakfastOrderApiSchema from "schema/Breakfast/BreakfastOrderApiSchema";
import swal from 'sweetalert';

const OrderSummarizeComponent = ({ orderIndex, currentOrderIndex }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const orderEdition = useSelector((state) => state.orderEdition);

  const order = useSelector(
    (state) => state.orderEdition.orders[orderIndex] || {}
  );

  const [cancelDate, setCancelDate] = useState(null);
  const [info, setInfo] = useState({});
  
  useEffect(() => {
    setCancelDate(order.cancel_date || null);
    setInfo(order.info || {});
  }, [order]);

  const { totalHT, totalTTC } = order;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [validationError, setValidationError] = useState(null);

  const handleSave = async () => {
    const data = breakfastOrderStoreToApi(orderIndex, orderEdition);
    try {
      await BreakfastOrderApiSchema.validate(data);

      dispatch(saveOrder(data, orderIndex, order?.id))
      .then((res, a) => {
        if (res?.order?.id) {
          history.push({
            pathname: history.location.pathname,
            search: `?order_id=${res.order.id}&order_type=breakfast`,
          });
          
          swal({
            title: "Enregistrement fait",
            text: "Votre commande a bien été sauvegardé et le devis généré",
            icon: "success",
            buttons: ["Rester ici", "Retour aux commandes"],
          })
          .then((confirm) => {
            if(confirm){
              history.push({
                pathname: `/`,
                search: window.location.search
              });
            }
          });
        }
        if(res.success === false){
          swal({
            title: "Oups",
            text: res.message,
            icon: "error",
          })
        }
      });
    } catch (error) {
      setValidationError(error);
      setModalIsOpen(true);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setValidationError(null);
  };

  const handleEditOrder = (orderIndex) => {
    dispatch(setCurrentOrderIndex(orderIndex));
  };

  return (
    <>
      <Modal show={modalIsOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Oups, on a un problème</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{validationError?.message}</p>
          <Button onClick={closeModal} variant="warning">Fermer</Button>
        </Modal.Body>
      </Modal>
      <Card key={orderIndex}>
        <Card.Header>Prestation {info?.orderNumber || orderIndex + 1}</Card.Header>
        <Card.Body>
          <b>Total HT :</b> {centimesToCurrency(totalHT)}
          <br />
          {/* Il faut avoir la tva de chaque produit and co, pas dispo pour multi fomruleTotal TTC : {centimesToCurrency(totalTTC)} */}
          <br />
          <Row>
            <Col md={6}>
            {order?.id && (
              <Dropdown>
                <Dropdown.Toggle
                  variant="outline-secondary"
                  size="sm"
                  id="dropdown-custom-components"
                >
                  Documents
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {info.document_urls && info.document_urls.length > 0 ? (
                    info.document_urls.map((document, index) => (
                      <Dropdown.Item
                        key={index * 10}
                        href={document.url}
                        target="_blank"
                      >
                        {document.name}
                      </Dropdown.Item>
                    ))
                  ) : (
                    <Dropdown.Item
                      eventKey="10"
                      href={info.generate_document_url}
                      target="_blank"
                    >
                      Générer le document manquant
                    </Dropdown.Item>
                  )}
                  {!cancelDate && (
                    <>
                      <hr />
                      <Dropdown.Item
                        eventKey="1"
                        href={info.quotation_url}
                        target="_blank"
                      >
                        Fiche de production
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="2"
                        href={info.propose_url}
                        target="_blank"
                      >
                        Le Moulin vous propose
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            )}
            </Col>
            <Col md={6} className="text-right">
              <Button
              onClick={handleSave}
              variant="success"
              size="sm"
              >
                Sauvegarder
              </Button>
              {currentOrderIndex !== orderIndex && (
                <>
                  <Button
                    onClick={(e) => handleEditOrder(orderIndex)}
                    variant="primary"
                    size="sm"
                    style={{ marginRight: "5px" }}
                  >
                    Editer
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default OrderSummarizeComponent;
